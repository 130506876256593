import { useState } from 'react';

import { useCreateMainConversationRequest } from '../../../models/conversations/mainConversation/useMainConversation';
import { conversationsInvalidatedSg } from '../../../models/conversations/signals';

export default function useConversationResetAction() {
  const { request: createMainConversation } = useCreateMainConversationRequest();
  const [isResetInProgress, setIsResetInProgress] = useState(false);
  const handleConfirmReset = async () => {
    setIsResetInProgress(true);
    await createMainConversation();
    conversationsInvalidatedSg.value = true;
    setIsResetInProgress(false);
  };

  return {
    isResetInProgress,
    handleConfirmReset,
  };
}
