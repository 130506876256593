export const modalFilesUploadLocalization = {
  'ModalUploadFiles:Title': {
    ru: 'Загрузить файлы',
  },
  'ModalUploadLinks:Title': {
    ru: 'Добавить ссылки',
  },
  'Sources:Error:MaxFilesSize': {
    eng: '',
    ru: 'Нельзя загрузить за раз файлов более чем на {maxFilesSize}Мб',
  },
  'Sources:Error:FormatError': {
    eng: '',
    ru: 'Недопустимый формат',
  },
  'Sources:Error:SizeError': {
    eng: '',
    ru: 'Файл превысил максимально доступный размер',
  },
  'Sources:Upload:DuplicateError': {
    eng: 'This file already exists',
    ru: 'Этот файл уже есть',
  },
  'ModalUploadFiles:UploadArea:Description': {
    ru:
      '<p class="font-size-12 margin-bottom-4">Поддерживаются большинство типов текстовых, аудио и видео файлов. <a href="{linkUrl}" target="_blank" rel="noopener noreferrer" class="text-decoration-none">{linkText}</a></p>' +
      '<p class="font-size-12">Максимальный размер по сумме размера файлов в одной загрузке: 200 Мб</p>',
  },
  'ModalUploadFiles:UploadArea:Description:LinkText': {
    ru: 'Полный список в документации',
  },
  '': {
    ru: '',
  },
};
