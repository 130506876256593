import React, { FC, memo, useEffect } from 'react';

import { Spinner } from '@just-ai/just-ui';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router';

import { useAppContext } from '../../contexts/appContext';
import localize from '../../localization';
import { useConversations } from '../../models/conversations/useConversations';
import { externalAppsLoaded } from '../../models/externalApps/signals';
import { useFavorite } from '../../models/favourite/favourite.api';
import { useTemplates, templatesLoaded } from '../../models/templates';
import { goToHome, routes } from '../../routes';

export const LoadingContainer: FC = memo(({ children }) => {
  const {
    state: { locale },
  } = useAppContext();

  const { loadedTemplates, errorTemplates } = useTemplates(locale, true);
  const {
    actualConversations,
    mainConversationError,
    conversationsListError,
    loaded: conversationsLoaded,
  } = useConversations({
    templatesMap: loadedTemplates?.templatesMap ?? null,
  });
  const { errorFavourite } = useFavorite(true);
  const { pathname } = useLocation();

  const hasError = Boolean(mainConversationError || conversationsListError || errorFavourite);

  useEffect(() => {
    if (loadedTemplates !== null) {
      // TODO Remove this hack when implementing the new conversations API.
      const promptTranslations = Object.entries(loadedTemplates.templatesMap).reduce(
        (acc, [templateName, template]) => {
          const promptKey = `prompt_${templateName}`;
          const promptValue = template.info.prompt || '';
          return { ...acc, [promptKey]: { [locale]: promptValue } };
        },
        {}
      );
      localize.addTranslations(promptTranslations);
    }

    if (!hasError && pathname.includes(routes.login)) {
      goToHome();
    }
  }, [errorTemplates, pathname, loadedTemplates, locale, hasError]);

  if (!(conversationsLoaded || actualConversations.length > 0) || !templatesLoaded.value || !externalAppsLoaded.value)
    return createPortal(<Spinner backgroundColor='white' />, document.body);

  return <>{children}</>;
});
