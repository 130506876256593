import { useEffect } from 'react';

import { useSignal } from '@preact/signals-react';
import { useParams } from 'react-router';

import appHistory from './appHistory';
import { isAlpha } from './isAlpha';
import { Conversation } from './types/chat';

export const routes = {
  login: '/login',
  mainConversation: '/',
  templates: '/templates',
  conversation: '/conversations/:conversationId',
  account: '/account',
  corporate: '/corporate',
  myFiles: '/myfiles',
  analytics: '/account/analytics',
  limits: '/account/limits',
  apikeys: '/account/apikeys',
  externalapps: '/account/externalapps',
};

export const goToMainConversation = () => {
  appHistory.push(routes.mainConversation);
};

export const goToConversation = (id: Conversation['id']) => {
  appHistory.push(routes.conversation.replace(':conversationId', id));
};

export const goToTemplates = () => {
  appHistory.push(routes.templates);
};

export const goToHome = isAlpha ? goToTemplates : goToMainConversation;

export const conversationLink = (id: Conversation['id']) => {
  return routes.conversation.replace(':conversationId', id);
};

export const useConversationId = () => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const cID = useSignal(conversationId);
  useEffect(() => {
    cID.value = conversationId;
  }, [cID, conversationId]);
  return cID;
};

export const goToAccount = () => {
  appHistory.push(routes.account);
};

export const goToMyFiles = () => {
  appHistory.push(routes.myFiles);
};

export const goToAccountAnalytics = () => {
  appHistory.push(routes.analytics);
};

export const goToAccountLimits = () => {
  appHistory.push(routes.limits);
};

export const goToAccountApiKeys = () => {
  appHistory.push(routes.apikeys);
};

export const goToAccountExternalApps = () => {
  appHistory.push(routes.externalapps);
};

export const goToCorporate = () => {
  appHistory.push(routes.corporate);
};

export const goToLogin = () => {
  appHistory.push(routes.login);
};

export const telegramRoutes = {
  base: '/telegram*',
  telegram: '/telegram',
  telegramDetail: '/telegram/:templateId',
};

export const goTo403 = () => {
  if (!appHistory.location.pathname.endsWith('/403')) {
    appHistory.push('/403');
  }
};
