import React, { ComponentType } from 'react';

import { DynamicTemplateListResponseTemplates } from '@just-ai/api/src/generated/AppsAdapter';
import {
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  IconButton,
  SwitchButton,
  useTranslation,
} from '@just-ai/just-ui';
import cn from 'classnames';
import { format } from 'date-fns';

import styles from './styles.module.scss';
import { t } from '../../../../localization';
import { CrudModalTypes } from '../../tabs/ExternalAppsTab';

export type DTemplateRow = DynamicTemplateListResponseTemplates & {
  editActions?: {
    edit?: () => void;
    delete?: () => void;
    enable?: () => void;
  };
};

type DTemplateTableProps = {
  dynamicTemplates: DynamicTemplateListResponseTemplates[];
  openModalHandler: (args: { type: CrudModalTypes; value?: DynamicTemplateListResponseTemplates }) => void;
  enableHandler: (templateId: string) => void;
};

const RowComponent: ComponentType<{ data: DTemplateRow; index: number }> = ({ data, index }) => {
  return (
    <>
      <td className='text-left pl-4'>
        <SwitchButton
          data-test-id={`DTemplates:table:dropdown:switch:${index}`}
          onChange={data.editActions?.enable}
          id={`tableSwitch_${data.id}`}
          value={data.enabled}
        />
      </td>
      <td className='text-left' style={{ minWidth: '300px' }}>
        <p>{`${data.icon} ${data.title}`}</p>
        <p className='text-secondary mt-2'>{t(`DTemplates:table:type:${data.type}`)}</p>
      </td>
      <td>{data.createdAt ? format(new Date(data.createdAt), 'dd.MM.yyyy') : '—'}</td>
      <td className='text-left pl-4'>{data.updatedAt ? format(new Date(data.updatedAt), 'dd.MM.yyyy') : '—'}</td>
      <td className='pr-4'>
        <DropdownButton data-test-id={`DTemplates:table:dropdown:actions:${index}`} direction='down'>
          <DropdownToggle color='none' tag='div'>
            <IconButton className='ml-auto' name='farEllipsisH' data-test-id={`DTemplates:table:row:edit:${index}`} />
          </DropdownToggle>
          <DropdownMenu positionFixed>
            <DropdownItem
              data-test-id={`DTemplates:table:dropdown:edit:${index}`}
              className={cn(styles.Table__dropdownItem, 'flex items-center')}
              onClick={data.editActions?.edit}
            >
              {t('edit')}
            </DropdownItem>
            <DropdownItem
              data-test-id={`DTemplates:table:dropdown:delete:${index}`}
              className={cn(styles.Table__dropdownItem, styles['Table__dropdownItem--red'], 'flex items-center')}
              color='red'
              iconSize='md'
              onClick={data.editActions?.delete}
            >
              {t('delete')}
            </DropdownItem>
          </DropdownMenu>
        </DropdownButton>
      </td>
    </>
  );
};

export default function DTemplatesTable(props: DTemplateTableProps) {
  const { dynamicTemplates = [], openModalHandler, enableHandler } = props;

  const { t } = useTranslation();

  return (
    <>
      <table className={cn(styles.Table, styles.Table__limits, 'tableWithBorderedRows')}>
        <thead>
          <tr>
            <td className='text-left pl-4'>{t('AccountPage:dTemplate:state')}</td>

            <td className='text-left'>{t('AccountPage:dTemplate:title')}</td>

            <td>
              <div className='flex gap-8 align-center justify-end'>
                {t('AccountPage:dTemplate:createdAt')}
                {/* <Icon className='cursor-pointer' name='faSort' size='sm' onClick={() => sortHandler('createdAt')} /> */}
              </div>
            </td>

            <td className='text-left pl-4'>
              <div className='flex gap-8 align-center'>
                {t('AccountPage:dTemplate:updatedAt')}
                {/* <Icon className='cursor-pointer' name='faSort' size='sm' onClick={() => sortHandler('updatedAt')} /> */}
              </div>
            </td>
            <td> </td>
          </tr>
        </thead>
        <tbody>
          {dynamicTemplates.map((dTemplate, index) => (
            <React.Fragment key={dTemplate.id}>
              <tr>
                <RowComponent
                  data={{
                    ...dTemplate,
                    editActions: {
                      delete: () => openModalHandler({ type: 'delete', value: dTemplate }),
                      edit: () => openModalHandler({ type: 'update', value: dTemplate }),
                      enable: () => enableHandler(dTemplate.id),
                    },
                  }}
                  index={index}
                />
              </tr>
              <tr>
                <td style={{ borderStyle: 'none', padding: '4px 0' }} colSpan={6}></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
}
