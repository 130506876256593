import React, { FC, memo, useCallback } from 'react';

import { FileResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { Checkbox, useTranslation } from '@just-ai/just-ui';
import { computed } from '@preact/signals-react';
import { mainConversationSg } from 'models/conversations/signals';

import styles from './style.module.scss';
import { Selected } from './useSelected';
import { findConversation } from '../../../models/conversations';
import { FileRowDropdown } from '../../../pages/Files/FileRowDropdown';
import { useFileUploadProvider } from '../../../pages/Files/FilesUploadProvider';
import { FilterRowIcon } from '../../../pages/Files/FilterRowIcon';
import { getOnlyDate, getSourceSize, mimeToExt } from '../../../pages/Files/utils';
import { goToConversation, goToMainConversation } from '../../../routes';
import { chatFileFromFileService, chatTextAppendSignal } from '../../Chat/signals/ChatUpdateSignal';
import { FileTemplateName } from '../FileTemplateName';

type TFileRowProps = {
  selected?: Selected;
  source: FileResponse;
  hideTemplateName?: boolean;
  hideTableRowDropdown?: boolean;
  tableRowOnClick?: (file: FileResponse) => unknown;
};

export const FileTableRow: FC<TFileRowProps> = memo(
  ({ tableRowOnClick, selected, source, hideTemplateName, hideTableRowDropdown }) => {
    const { t } = useTranslation();

    const { deleteFileHandle } = useFileUploadProvider();

    const downLoad = useCallback(() => {
      window.open(source.url, '_blank');
    }, [source.url]);

    const deleteHandle = useCallback(() => {
      deleteFileHandle(source.id);
    }, [deleteFileHandle, source.id]);

    const hasMainConversation = Boolean(mainConversationSg.value);
    const openInAssistant = useCallback(() => {
      if (!hasMainConversation) {
        return;
      }
      goToMainConversation();
      if ('contentType' in source) {
        chatFileFromFileService.value = source;
      } else {
        chatTextAppendSignal.value = source.url;
      }
    }, [source, hasMainConversation]);

    const openInDialog = useCallback(() => {
      if (source.conversationId) {
        goToConversation(source.conversationId);
      }
    }, [source.conversationId]);

    const dialogWasDeleted = computed(() => {
      if (source.conversationId) return !Boolean(findConversation(source.conversationId));
    });

    const tableRowOnClickHandle = useCallback(() => {
      tableRowOnClick?.(source);
    }, [source, tableRowOnClick]);

    return (
      <tr className={styles.tr} data-test-id={`GenFilesRow.${source.id}`} onClick={tableRowOnClickHandle}>
        {selected && (
          <td style={{ transform: 'translateY(-3px)' }}>
            <Checkbox
              name={`checkbox${source.id}`}
              value={selected.isIncludes(source)}
              onChange={() => selected.selectedHandler(source)}
              data-test-id='GenFilesRow.Checkbox'
            />
          </td>
        )}
        <td data-test-id='GenFilesRow.Name'>
          <div className='d-flex align-items-center gap-8'>
            <FilterRowIcon mimeType={source.contentType} publicUrl={source.url} />
            {source.name}
          </div>
        </td>
        <td data-test-id='GenFilesRow.Format'>
          {mimeToExt(source.contentType, source.name?.slice(source.name?.lastIndexOf('.') + 1 - source.name.length))}
        </td>
        {!hideTemplateName && (
          <td data-test-id='GenFilesRow.AppTemplate'>
            <FileTemplateName template={source.conversationAppTemplate} />
          </td>
        )}
        <td data-test-id='GenFilesRow.CreatedAt'>{getOnlyDate(source.createdAt || Date.now())}</td>
        <td data-test-id='GenFilesRow.Size'>{getSourceSize(source.contentSize, t('Files:Table:Size-unknown'))}</td>
        {hideTableRowDropdown ? null : (
          <td>
            <FileRowDropdown
              downLoad={downLoad}
              deleteItem={deleteHandle}
              openInAssistant={hasMainConversation ? openInAssistant : null}
              openInDialog={source.conversationId ? openInDialog : undefined}
              dialogWasDeleted={source.conversationId ? dialogWasDeleted.value : undefined}
            />
          </td>
        )}
      </tr>
    );
  }
);
