import React, { memo, useCallback, useState } from 'react';

import { Button, ButtonProps, useTranslation } from '@just-ai/just-ui';
import { useComputed, useSignalEffect } from '@preact/signals-react';
import cn from 'classnames';
import { uniqueId } from 'lodash';

import styles from './style.module.scss';
import { useShowUiToolResponses } from './useShowUiToolResponses.hook';
import { findConversationIndex } from '../../models/conversations';
import { allConversationsSg, setConversationsValue } from '../../models/conversations/signals';
import { externalApps } from '../../models/externalApps/signals';
import { type Conversation } from '../../types/chat';

interface ImageShowUiButtonProps extends ButtonProps {
  imageUrl: string;
  selectedConversationId: Conversation['id'];
}

const ImageShowUiButton = memo(({ imageUrl, selectedConversationId, ...props }: ImageShowUiButtonProps) => {
  const { t } = useTranslation();
  const [isImageUiShown, setIsImageUiShown] = useState(false);

  const imageApp = useComputed(() => {
    // TODO: what if there are several external apps that work with images?
    return externalApps.value.find(
      // TODO: add support for `acceptedFilesGroups` in external app manifests and use them instead.
      app => app.acceptedFiles?.find(mimeType => mimeType.includes('image'))
    );
  });

  const getShowUiToolResponses = useShowUiToolResponses(
    [
      {
        function: {
          name: 'show_ui',
          arguments: JSON.stringify({ externalAppId: imageApp.value?.name, fileUrl: imageUrl }),
        },
        id: uniqueId('manual_call_'),
      },
    ],
    selectedConversationId
  );

  const showUi = useCallback(async () => {
    setIsImageUiShown(true);

    const {
      instanceIds: [instanceId],
    } = await getShowUiToolResponses();

    setConversationsValue(storeConversations => {
      const conversationIndex = findConversationIndex(selectedConversationId);
      if (conversationIndex > -1) {
        storeConversations[conversationIndex].externalInstances[instanceId] = {
          externalAppId: imageApp.value?.name,
          // The line below implicitly requires that the external app blackbox structure support `currentImageUrl`.
          // The current image editor fits, but theoretically this is not scalable for other external apps.
          blackbox: JSON.stringify({ currentImageUrl: imageUrl }),
        };
      }
    });
  }, [imageApp.value, imageUrl, selectedConversationId, getShowUiToolResponses]);

  useSignalEffect(() => {
    const conversationIndex = findConversationIndex(selectedConversationId);
    const externalInstances = conversationIndex > -1 ? allConversationsSg.value[conversationIndex].externalInstances : [];
    setIsImageUiShown(Object.values(externalInstances).some(({ blackbox }) => !!blackbox?.includes(imageUrl)));
  });

  if (!imageApp.value) {
    return null;
  }

  return (
    <Button
      className={cn(styles.chat__showUiButton, 'text-center padding-12 bg-hover-color-gray-200')}
      onClick={() => showUi()}
      disabled={isImageUiShown}
      outline={true}
      color='secondary'
      dataTestId={`Chat.Message.ImageShowUiButton-${imageUrl}`}
      {...props}
    >
      {t('ImageShowUiButton:Title')}
    </Button>
  );
});

export default ImageShowUiButton;
