import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';

import { CanvasInstance } from './CanvasHolderContext';
import apiClient from '../../../api/client';
import { setSilentMessages } from '../../../models/conversations/canvas/silentMessage.signal';
import {
  dropFileToUploadForInstance,
  FileToUpdate,
  FileToUpload,
  setFilesToUpload,
} from '../../../models/conversations/canvas/uploadFiles.signal';
import useApiService from '../../../services/useApiService';
import { isDev } from '../../../utils/app/common';
import { IframeEventTransport, ToolEventTransport } from '../../../utils/iFrameEventBus';
import { createFileFromArrayBuffer, createFileFromBase64OrString } from '../utils';

export const useIframeRegistration = (
  conversationId: string,
  CanvasTransport: MutableRefObject<Record<string, ToolEventTransport>>,
  setChatInstances: Dispatch<SetStateAction<CanvasInstance[]>>
): [
  registerIfame: (iframe: HTMLIFrameElement, instanceId: string) => void,
  unregisterIframe: (instanceId: string) => void,
] => {
  const { uploadFile, updateFile, updateExternalInstanceTools, updateExternalInstanceDepiction } = useApiService();

  const registerIframe = useCallback(
    (iframe: HTMLIFrameElement, instanceId: string) => {
      if (!CanvasTransport.current) return;
      const emitter = new ToolEventTransport(new IframeEventTransport(iframe));
      CanvasTransport.current[instanceId] = emitter;

      const listenForGetFileFunction = async (
        event: MessageEvent<{ event: 'canvas:get_file'; data: { uri: string } }>
      ) => {
        if (event.data.event === 'canvas:get_file') {
          try {
            const { data } = await apiClient.get(new URL(event.data.data.uri).pathname + '?persistent=true', { //добавить к урлу '?persistent=true'
              responseType: 'arraybuffer',
              withCredentials: true,
            });
            emitter.transport.postMessage('get_file', {
              data,
              type: 'image/png',
              name: 'image.png',
            });
          } catch (e) {
            console.error(e);
          }
        }
      };

      const listenForUploadFileFunction = async (
        event: MessageEvent<{
          event: 'canvas:upload_file';
          data: FileToUpload;
        }>
      ) => {
        if (event.data.event === 'canvas:upload_file') {
          const { data } = await uploadFile(
            typeof event.data.data.content === 'string'
              ? await createFileFromBase64OrString(
                  event.data.data.content,
                  event.data.data.fileName,
                  event.data.data.fileType
                )
              : await createFileFromArrayBuffer(
                  event.data.data.content,
                  event.data.data.fileName,
                  event.data.data.fileType!
                ),
            conversationId
          );
          emitter.transport.postMessage('upload_file', {
            url: isDev()
              ? data.url.replace('http://localhost:3000', process.env.REACT_APP_COPILOT_PROXY || '')
              : data.url,
            fileId: data.id,
          });
        }
      };

      const listenForUpdateFileFunction = async (
        event: MessageEvent<{
          event: 'canvas:update_file';
          data: FileToUpdate;
        }>
      ) => {
        if (event.data.event === 'canvas:update_file') {
          const { data } = await updateFile(event.data.data.fileId, event.data.data.content, undefined);
          emitter.transport.postMessage('update_file', {
            fileId: data.id,
            url: isDev()
              ? data.url.replace('http://localhost:3000', process.env.REACT_APP_COPILOT_PROXY || '')
              : data.url,
          });
        }
      };

      const listenQueueFileFunction = async (
        event: MessageEvent<{
          event: 'canvas:queue_upload_file';
          data: FileToUpload;
        }>
      ) => {
        if (event.data.event === 'canvas:queue_upload_file') {
          setFilesToUpload(filesToUpload => {
            const foundFileIndex = filesToUpload.findIndex(file => file.instanceId === instanceId);
            if (foundFileIndex > -1) {
              filesToUpload[foundFileIndex].content = event.data.data.content;
              filesToUpload[foundFileIndex].fileName = event.data.data.fileName;
              filesToUpload[foundFileIndex].fileType = event.data.data.fileType;
            } else {
              filesToUpload.push({
                instanceId,
                content: event.data.data.content,
                fileName: event.data.data.fileName,
                fileType: event.data.data.fileType,
              } as FileToUpload);
            }
          });
          emitter.transport.postMessage(
            'queue_upload_file',
            'ok'
            // isDev() ? data.url.replace('http://localhost:3000', 'https://copilot.dev01.lo.test-ai.net') : data.url
          );
        }
      };
      const listenUpdateToolsFunction = async (event: MessageEvent) => {
        if (event.data.event === 'canvas:update_tools') {
          await updateExternalInstanceTools(conversationId, instanceId, event.data.data);
          setChatInstances(prevChatInstances => {
            const onLoadInstance = [...prevChatInstances].find(instance => instance.instanceId === instanceId);
            if (onLoadInstance && onLoadInstance.onLoad) {
              onLoadInstance.onLoad();
              onLoadInstance.onLoad = undefined;
            }
            return prevChatInstances;
          });

          emitter.transport.postMessage('update_tools', 'ok');
        }
      };
      const listenUpdateDepiction = async (
        event: MessageEvent<
          | {
              event: 'canvas:update_state';
              data: { blackbox: string; id: never; content: never };
            }
          | {
              event: 'canvas:update_description';
              data: {
                content: ({ text?: string; image_url: never } | { image_url?: string; text: never })[];
                id?: string;
              };
            }
          | {
              event: 'canvas:delete_update_description';
              data: {
                id: string;
                content: never;
              };
            }
        >
      ) => {
        switch (event.data.event) {
          case 'canvas:update_state':
            await updateExternalInstanceDepiction(conversationId, instanceId, event.data.data.blackbox, '');
            emitter.transport.postMessage('update_state', 'ok');
            break;

          case 'canvas:update_description':
            setSilentMessages(prevSilentMessages => {
              if (event.data.data.id) {
                const prevSilentMessagesMessageIndex = prevSilentMessages.findIndex(
                  message => message.id === event.data.data.id
                );
                if (prevSilentMessagesMessageIndex > -1) {
                  prevSilentMessages.splice(prevSilentMessagesMessageIndex, 1, {
                    id: event.data.data.id,
                    instanceId,
                    content: event.data.data.content,
                  });
                } else {
                  prevSilentMessages.push({
                    instanceId,
                    id: event.data.data.id,
                    content: event.data.data.content,
                  });
                }
              } else {
                prevSilentMessages.push({
                  instanceId,
                  content: event.data.data.content,
                });
              }
            });
            emitter.transport.postMessage('update_description', 'ok');
            break;

          case 'canvas:delete_update_description':
            if (!event.data.data.id) return;
            setSilentMessages(prevSilentMessages => {
              prevSilentMessages = prevSilentMessages.filter(message => message.id !== event.data.data.id);
            });
            emitter.transport.postMessage('delete_update_description', 'ok');
            break;
        }
      };

      emitter.transport.addEventListener(listenForUploadFileFunction);
      emitter.transport.addEventListener(listenForGetFileFunction);
      emitter.transport.addEventListener(listenQueueFileFunction);
      emitter.transport.addEventListener(listenUpdateToolsFunction);
      emitter.transport.addEventListener(listenUpdateDepiction);
      emitter.transport.addEventListener(listenForUpdateFileFunction);

      return () => {
        emitter.transport.removeEventListener(listenForUploadFileFunction);
        emitter.transport.removeEventListener(listenForGetFileFunction);
        emitter.transport.removeEventListener(listenQueueFileFunction);
        emitter.transport.removeEventListener(listenUpdateToolsFunction);
        emitter.transport.removeEventListener(listenUpdateDepiction);
      };
    },
    [
      CanvasTransport,
      conversationId,
      setChatInstances,
      updateExternalInstanceDepiction,
      updateExternalInstanceTools,
      updateFile,
      uploadFile,
    ]
  );

  const unregisterIframe = useCallback(
    (instanceId: string) => {
      if (CanvasTransport.current) {
        CanvasTransport.current[instanceId].destroy();
        dropFileToUploadForInstance(instanceId);
        delete CanvasTransport.current[instanceId];
      }
    },
    [CanvasTransport]
  );

  return [registerIframe, unregisterIframe];
};
