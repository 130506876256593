import React, { memo } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import classnames from 'classnames';
import { ContentContainer } from 'pages/FullContainer/ContentContainer';

import { MobileHeader } from './MobileHeader';
import TemplatePanel from '../../components/Agents';
import { NoMoreThan3RequestsPopup } from '../../components/Agents/NoMoreThan3RequestsBlink';
import { isShownBlinkRequestsWarn } from '../../components/Agents/NoMoreThan3RequestsBlink/signal';
import styles from '../../components/Chat/style.module.scss';
import { isAlpha } from '../../isAlpha';

export const Templates = memo(() => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <div className={classnames('mx-md-auto flex flex-col', styles.chat__agentsList)}>
        <div className={classnames('justify-flex-start d-none d-sm-flex', styles.chat__agentsHeader)}>
          <h1>
            {isAlpha
              ? t('welcomeAlfa')
              : t('welcome', { productName: t('defaultProductName'), brand: t('defaultBrand') })}
          </h1>
        </div>
        <MobileHeader noBorder fixed />
        <TemplatePanel />
      </div>
      <NoMoreThan3RequestsPopup isShown={isShownBlinkRequestsWarn.value} />
    </ContentContainer>
  );
});
Templates.displayName = 'memo(Templates)';
