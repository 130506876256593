import React, { useEffect } from 'react';

import { Spinner } from '@just-ai/just-ui';
import { registerPreloadedIcons } from '@just-ai/just-ui/dist/Icon';
import { useSignalEffect } from '@preact/signals-react';
import { Chat } from 'components/Chat/Chat';
import { LoadingContainer } from 'components/LoadingContainer';
import { UseAuthLoader } from 'components/UseCCLoader';
import { TelegramContextProvider } from 'contexts/TelegramContextProvider';
import { register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { Page403 } from 'pages/Page403';
import TagManager from 'react-gtm-module';
import { Redirect } from 'react-router';
import { Route, Router, Switch } from 'react-router-dom';
import { getGoogleTag, processUtmTags } from 'utils/app/common';

import { appOptions, useCloudLogin, useKeycloakLogin, userKeycloakAuthHeaderSet, ccOptions } from './api/cc.api';
import appHistory from './appHistory';
import AccountPage from './components/Account';
import CorporatePage from './components/Corporate';
import { Login } from './components/Login';
import JGuardPanel from './components/TopPanel/JGuardPanel';
import MemoAssistantTour from './components/Tour';
import localize, { engT, t } from './localization';
import { jGuardFailedToProtect } from './models/conversations/signals';
import { utmTagsList } from './models/currentUser';
import { creatingNewAgentFromFavorite } from './models/favourite/favourite.api';
import { FilesPage } from './pages/Files';
import { FullContainer } from './pages/FullContainer';
import { Templates } from './pages/Templates';
import { TelegramTemplatesList } from './pages/TelegramTemplatesList';
import './App.scss';
import { routes, telegramRoutes } from './routes';

import './styles/globals.css';

registerPreloadedIcons([
  'faCircleNotch',
  'faPause',
  'faPlay',
  'farPlus',
  'faPen',
  'faJustSelect',
  'faGripVertical',
  'farPodcast',
  'farTrashAlt',
]);

function App() {
  useEffect(() => {
    const gtmId = getGoogleTag();
    utmTagsList.value = processUtmTags(appHistory.location.search);
    async function initExtendableMediaRecorder() {
      await register(await connect());
    }
    initExtendableMediaRecorder();
    if (!gtmId) return;
    const tagManagerArgs = {
      gtmId: gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useSignalEffect(() => {
    const contactUsEmail = ccOptions.value?.domains.copilot?.email?.support;
    localize.setConfigVariables({
      productName: {
        eng: appOptions.value?.product?.name || engT('defaultProductName'),
        ru: appOptions.value?.product?.name || t('defaultProductName'),
      },
      dataGuardName: {
        eng: engT('dataGuardProductName'),
        ru: t('dataGuardProductName'),
      },
      supportMail: {
        eng: contactUsEmail,
        ru: contactUsEmail,
      },
    });
  });

  const fullContainerRoutes = Object.entries(routes)
    .filter(([key, value]) => key !== 'login')
    .map(([key, value]) => value);

  return (
    <Router history={appHistory}>
      <Switch>
        <Route path={telegramRoutes.base} exact>
          <TelegramContextProvider>
            <Route path={telegramRoutes.telegramDetail} exact component={() => <>telegramDetail</>} />
            <Route path={telegramRoutes.telegram} exact component={TelegramTemplatesList} />
          </TelegramContextProvider>
        </Route>
        <Route path={Object.values(routes)} exact>
          <UseAuthLoader>
            {useKeycloakLogin.value && !userKeycloakAuthHeaderSet.value ? (
              <Spinner />
            ) : (
              <FullContainer>
                <Route path={fullContainerRoutes} exact>
                  {jGuardFailedToProtect.value && <JGuardPanel />}
                  <LoadingContainer>
                    {creatingNewAgentFromFavorite.value && <Spinner />}
                    <MemoAssistantTour />
                    <Switch>
                      <Route path={routes.myFiles} exact component={FilesPage} />
                      <Route path={[routes.conversation, routes.mainConversation]} exact component={Chat} />
                      <Route path={routes.templates} exact component={Templates} />
                      <Route
                        path={[routes.account, routes.analytics, routes.limits, routes.apikeys]}
                        component={AccountPage}
                      />
                      <Route path={routes.corporate} exact component={CorporatePage} />
                    </Switch>
                  </LoadingContainer>
                </Route>
                <Route
                  path={routes.login}
                  exact
                  component={useCloudLogin.value ? () => <Redirect to={routes.mainConversation} /> : Login}
                />
              </FullContainer>
            )}
          </UseAuthLoader>
        </Route>
        <UseAuthLoader innerLoadingDefaultState={false}>
          <Route path='/403' exact component={Page403} />
        </UseAuthLoader>
        <Route path='*'>
          <Redirect to={routes.mainConversation} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
