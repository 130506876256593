export type Base64FileToUpload = {
  instanceId: string;
  fileName?: string;
  fileType?: string;
  content: string;
};

export type ArrayBufferFileToUpload = {
  instanceId: string;
  fileType: string;
  content: ArrayBuffer;
  fileName?: string;
};

export type FileToUpload = Base64FileToUpload | ArrayBufferFileToUpload;
export type FileToUpdate = {
  instanceId: string;
  content: string;
  fileId: string;
};

export let filesToUpload: FileToUpload[] = [];

export const setFilesToUpload = (cbOrFiles: ((files: FileToUpload[]) => unknown) | FileToUpload[]) => {
  if (typeof cbOrFiles === 'function') {
    cbOrFiles(filesToUpload);
  } else {
    filesToUpload = cbOrFiles;
  }
};

export const dropFilesToUpload = () => {
  setFilesToUpload([]);
};
export const dropFileToUploadForInstance = (instanceId: string) => {
  setFilesToUpload(files => files.filter(file => file.instanceId !== instanceId));
};
