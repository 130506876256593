import React, { FC } from 'react';

import { Modal, useTranslation } from '@just-ai/just-ui';
import DOMPurify from 'dompurify';

import { appOptions } from '../../api/cc.api';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const HelpDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  // Render nothing if the dialog is not open.
  if (!open) {
    return null;
  }

  // Render the dialog.
  return (
    <Modal title={t('support')} isOpen onClickCloseIcon={onClose}>
      <div
        className='mb-2 text-black'
        dangerouslySetInnerHTML={{
          __html: appOptions.value?.product?.supportText
            ? DOMPurify.sanitize(appOptions.value?.product?.supportText)
            : t('supportMessage', { productName: t('defaultProductName') }),
        }}
      />
    </Modal>
  );
};
