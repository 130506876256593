import React, { memo, useEffect, useMemo, useState } from 'react';

import { useTranslation, Tabs } from '@just-ai/just-ui';
import { useLocation } from 'react-router';

import { AnalyticsTab } from './tabs/AnalyticsTab';
import ApiKeysTab from './tabs/ApiKeysTab';
import ExternalAppsTab from './tabs/ExternalAppsTab';
import LimitsTab from './tabs/LimitsTab';
import { MainTab } from './tabs/MainTab';
import { appHasSystemFeature } from '../../api/cc.api';
import { isTovie } from '../../isTovie';
import { hasFeature, hasPermission } from '../../models/currentUser';
import { ContentContainer } from '../../pages/FullContainer/ContentContainer';
import { MobileHeader } from '../../pages/Templates/MobileHeader';
import {
  goToAccount,
  goToAccountAnalytics,
  goToAccountApiKeys,
  goToAccountExternalApps,
  goToAccountLimits,
  routes,
} from '../../routes';

export type JGuardKeyStatus = {
  keyStatus: 'off' | 'on' | 'error';
  emailSent: boolean;
};

const TabsTypes = {
  MAIN: 'main',
  ANALYTICS: 'analytics',
  LIMITS: 'limits',
  APIKEYS: 'apikeys',
  EXTERNALAPPS: 'externalapps',
};

const AccountPage = memo(function AccountPage() {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(TabsTypes.MAIN);

  const location = useLocation();

  const tabs = useMemo(() => {
    const tabsList = [
      {
        value: TabsTypes.MAIN,
        name: t('AccountPage:MainTabs:Main'),
      },
      {
        value: TabsTypes.ANALYTICS,
        name: t('AccountPage:MainTabs:Analytics'),
      },
    ];
    if (appHasSystemFeature('dynamic_templates') && hasPermission('COPILOT_MANAGE_ACCOUNT')) {
      tabsList.push({
        value: TabsTypes.EXTERNALAPPS,
        name: t('AccountPage:MainTabs:ExternalApps'),
      });
    }
    if (hasFeature('copilot_api_key_admin') && hasPermission('COPILOT_MANAGE_API_KEYS'))
      tabsList.push({
        value: TabsTypes.APIKEYS,
        name: t('AccountPage:MainTabs:ApiKeys'),
      });

    if (hasPermission('COPILOT_MANAGE_USER_LIMITS'))
      tabsList.push({
        value: TabsTypes.LIMITS,
        name: t('AccountPage:MainTabs:Limits'),
      });
    return tabsList;
  }, [t]);

  const changeTabHandler = (value: string) => {
    if (value === TabsTypes.MAIN) {
      return goToAccount();
    }
    if (value === TabsTypes.ANALYTICS) {
      return goToAccountAnalytics();
    }
    if (value === TabsTypes.LIMITS) {
      return goToAccountLimits();
    }
    if (value === TabsTypes.APIKEYS) {
      return goToAccountApiKeys();
    }
    if (value === TabsTypes.EXTERNALAPPS) {
      return goToAccountExternalApps();
    }
    setCurrentTab(value);
  };

  useEffect(() => {
    if (location.pathname === routes.account) {
      return setCurrentTab(TabsTypes.MAIN);
    }
    if (location.pathname === routes.analytics) {
      return setCurrentTab(TabsTypes.ANALYTICS);
    }
    if (
      location.pathname === routes.externalapps &&
      appHasSystemFeature('dynamic_templates') &&
      hasPermission('COPILOT_MANAGE_ACCOUNT')
    ) {
      return setCurrentTab(TabsTypes.EXTERNALAPPS);
    }
    if (location.pathname === routes.limits && hasPermission('COPILOT_MANAGE_USER_LIMITS')) {
      return setCurrentTab(TabsTypes.LIMITS);
    }
    if (
      location.pathname === routes.apikeys &&
      hasFeature('copilot_api_key_admin') &&
      hasPermission('COPILOT_MANAGE_API_KEYS')
    ) {
      return setCurrentTab(TabsTypes.APIKEYS);
    }
  }, [location.pathname]);

  return (
    <ContentContainer withLicense={!isTovie}>
      <MobileHeader pageName={t('account')} />
      <div className='p-5 h-full flex flex-column'>
        <h1 className='d-none d-sm-block mb-5'>{t('account')}</h1>
        <Tabs tabs={tabs} activeTab={currentTab} onChange={changeTabHandler} className='mb-5' />
        {currentTab === TabsTypes.MAIN && <MainTab />}
        {currentTab === TabsTypes.ANALYTICS && <AnalyticsTab />}
        {currentTab === TabsTypes.LIMITS && <LimitsTab />}
        {currentTab === TabsTypes.APIKEYS && <ApiKeysTab />}
        {currentTab === TabsTypes.EXTERNALAPPS && <ExternalAppsTab />}
      </div>
    </ContentContainer>
  );
});

export default AccountPage;
