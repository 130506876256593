import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';

import { Modal, UploadArea, usePromiseProcessing, useTranslation } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import { useController, useForm } from 'react-hook-form';

import { modalFilesUploadLocalization } from './modalFilesUpload.loc';
import { bytesInMb, maxFileSize, maxFileSizeMb, UPLOAD_STATUSES, UploadSource } from './types';
import { ModalUploadFileRow } from './UploadFileRow';
import localize from '../../../localization';
import { templates } from '../../../models/templates';
import { useFileUploadProvider } from '../../../pages/Files/FilesUploadProvider';
import { AgentType } from '../../../types/agents';
import { localCategoriesMap } from '../../Agents/Categories';
import { appOptions } from '../../../api/cc.api';
import { createDocsLink } from '../../../utils/app/common';

localize.addTranslations(modalFilesUploadLocalization);

export function getFilesName(files: UploadSource[]) {
  if (!files || !files.length) return '';
  return files.map(el => el.name).join(', ');
}

export const ModalUploadFiles: FC<{ isModalOpen: boolean; setCloseModal: () => unknown }> = memo(
  ({ isModalOpen, setCloseModal }) => {
    const { t } = useTranslation();

    const { batchUploadFilesHandle } = useFileUploadProvider();

    const { control, reset, setValue, setError, formState, getValues } = useForm<{ files: UploadSource[] }>({
      shouldUnregister: false,
      reValidateMode: 'onChange',
      mode: 'onChange',
      defaultValues: {
        files: [],
      },
    });
    const onCloseModalHandle = useCallback(() => {
      reset();
      setCloseModal();
    }, [reset, setCloseModal]);

    const { field } = useController({
      name: 'files',
      control,
    });

    const onSelectFiles = useCallback(
      (acceptedFiles?: FileList, rejectedFiles?: FileList) => {
        if (!acceptedFiles || !rejectedFiles) {
          return;
        }
        if (Array.from(acceptedFiles).reduce((acc, file) => acc + file.size, 0) > maxFileSize) {
          setError('files', { message: t('Sources:Error:MaxFilesSize', { maxFilesSize: maxFileSizeMb }) });
          return;
        }
        setError('files', { message: undefined });

        const fileArray: UploadSource[] = [];

        for (const file of acceptedFiles) {
          const newFile: UploadSource = {
            name: file.name,
            size: parseFloat((file.size / bytesInMb).toFixed(1)) || 0.1,
            file: file,
            status: UPLOAD_STATUSES.READY,
          };
          if ([...fileArray, ...field.value].some(el => el.name === file.name)) {
            newFile.status = UPLOAD_STATUSES.ERROR;
            newFile.error = t('Sources:Upload:DuplicateError');
          }
          fileArray.unshift(newFile);
        }
        for (const file of rejectedFiles) {
          const newFile: UploadSource = {
            name: file.name,
            size: parseFloat((file.size / bytesInMb).toFixed(1)) || 0.1,
            file: file,
            status: UPLOAD_STATUSES.ERROR,
            error: t('Sources:Error:FormatError'),
          };

          fileArray.unshift(newFile);
        }

        setValue('files', [...fileArray, ...field.value]);
      },
      [field.value, setError, setValue, t]
    );
    const onResetFiles = () => {};

    const [{ loading: isUploading }, uploadFiles] = usePromiseProcessing(batchUploadFilesHandle, {
      throwOnError: true,
    });

    const onDeleteFile = useCallback(
      (id: string) => {
        const fieldValues = getValues('files');
        const newValues = fieldValues.filter(source => source?.id !== id);
        setValue('files', newValues);
      },
      [getValues, setValue]
    );

    useEffect(() => {
      if (!!formState.errors?.files?.message) return;

      let fieldValue = field.value;

      const filesToUploadIndexes = fieldValue.reduce((acc, file, index) => {
        if (file.status === UPLOAD_STATUSES.READY) {
          acc.push(index);
        }
        return acc;
      }, [] as number[]);
      if (filesToUploadIndexes.length > 0) {
        const newFieldValue = [...fieldValue];
        const filesToUpload: File[] = [];
        filesToUploadIndexes.forEach(fileToUploadIndex => {
          newFieldValue[fileToUploadIndex].status = UPLOAD_STATUSES.LOADING;
          filesToUpload.push(newFieldValue[fileToUploadIndex].file);
        });
        setValue('files', newFieldValue);
        uploadFiles(filesToUpload)
          .then(({ data }) => {
            data.forEach((fileEntity, index) => {
              const currentFiles = getValues('files');
              if ('contentSize' in fileEntity) {
                const formFileToChange = currentFiles.find(file => file.file.size === fileEntity.contentSize);
                if (formFileToChange) {
                  formFileToChange.status = UPLOAD_STATUSES.SUCCESS;
                  formFileToChange.id = fileEntity.id;
                }
              }
              if ('error' in fileEntity) {
                const formFileToChange = currentFiles.find(file => file.name === filesToUpload[index].name);
                if (formFileToChange) {
                  formFileToChange.status = UPLOAD_STATUSES.ERROR;
                  formFileToChange.error = fileEntity.error;
                }
              }

              setValue('files', [...currentFiles]);
            });
          })
          .catch(AppLogger.createErrorHandler('', { forToast: true }));
        //actually upload files and handle errors
      }
    }, [field.value, formState.errors?.files?.message, getValues, setValue, uploadFiles]);

    const templatesList = templates.value?.templatesList as AgentType[];

    const accept = useMemo(() => {
      return Array.from(
        new Set(
          templatesList
            .filter(
              template =>
                !!template.categories &&
                !template.requiredFeature &&
                template.categories.some(templateCategory => localCategoriesMap.includes(templateCategory))
            )
            .map(template => template.acceptedFiles?.split(','))
            .flat()
        )
      )
        .filter(value => !!value)
        .join(',');
    }, [templatesList]);

    const docs = appOptions.value?.product?.docs;

    const linkUrl =
      docs && Object.keys(docs?.links).includes('files')
        ? `${createDocsLink(t('docsUrl'), docs?.links?.files, docs.queryParams)}/#supported-types`
        : '';

    return (
      <Modal
        isOpen={isModalOpen}
        buttonSubmitTestId='UploadModal.SubmitButton'
        buttonCancelTestId='UploadModal.RejectButton'
        onCancelClick={onCloseModalHandle}
        buttonCancelText={t('close')}
        buttonCancelDisabled={isUploading}
        title={t('ModalUploadFiles:Title')}
        size='xl'
        scrollable={false}
        buttonSubmitDisabled={formState.isDirty && !formState.isValid}
        disableActionButtonAutoFocus
      >
        <UploadArea
          language={localize.getLocale().substring(0, 2)}
          accept={accept}
          onChange={onSelectFiles}
          onReset={onResetFiles}
          dataTestId='UploadFiles'
          invalid={!!formState.errors?.files?.message}
          disabled={false}
          multiple
          description={
            !formState.errors?.files?.message
              ? t('ModalUploadFiles:UploadArea:Description', {
                  linkUrl,
                  linkText: t('ModalUploadFiles:UploadArea:Description:LinkText'),
                })
              : ''
          }
          errorText={formState.errors?.files?.message || ''}
        />
        {field.value.length > 0 && (
          <div className='flex flex-column gap-16 margin-top-24'>
            {field.value.map(file => (
              <ModalUploadFileRow key={file.name} file={file} onDeleteFile={onDeleteFile} />
            ))}
          </div>
        )}
      </Modal>
    );
  }
);
