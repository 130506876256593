import React from 'react';

import ConversationItem from './ConversationItem';
import { Conversation } from '../../../types/chat';

interface Props {
  conversations: Conversation[];
  onConversationClick: (conversation: Conversation) => void;
}

export const Conversations = ({ conversations, onConversationClick: handleConversationClick }: Props) => {
  return (
    <div className='padding-16 padding-right-10 flex flex-col flex-grow-1 overflow-scroll'>
      <div className='flex w-full flex-col gap-1 flex-grow-1' data-test-id='Conversations:Container'>
        {/* Even if no conversations is present, we keep container as placeholder, to push footer elements down */}
        {conversations.map((conversation, index) => (
          <ConversationItem
            key={conversation.id}
            dataTestId={`Conversation.${index || 0}`}
            conversation={conversation}
            onClick={handleConversationClick}
          />
        ))}
      </div>
    </div>
  );
};
