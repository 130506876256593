export async function createFileFromBase64OrString(
  dataUrl: string,
  fileName: string = 'file',
  fileType?: string
): Promise<File> {
  if (dataUrl.startsWith('data:')) {
    const type = fileType || dataUrl.match(/^data:(.+);base64/)?.[1];
    const res = await fetch(dataUrl);
    const blob = await res.blob();
    return new File([blob], fileName + `.${type?.split('/')[1] || 'png'}`, { type });
  }
  const blob = new Blob([dataUrl], { type: fileType || 'text/plain' });
  return new File([blob], fileName, { type: fileType || 'text/plain' });
}

export async function createFileFromArrayBuffer(
  dataArrayBuffer: ArrayBuffer,
  fileName: string = 'file',
  fileType: string
): Promise<File> {
  return new File([new Blob([dataArrayBuffer], { type: fileType })], fileName + `.${fileType}`, { type: fileType });
}
