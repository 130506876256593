export const accountLocalization = {
  'Billing:Modal:title:outOfTokens': {
    eng: 'Not enough tokens',
    ru: 'Не хватает токенов',
  },
  token_one: {
    eng: 'token',
    ru: 'токен',
  },
  token_two: {
    eng: 'tokens',
    ru: 'токена',
  },
  token_few: {
    eng: 'tokens',
    ru: 'токена',
  },
  token_many: {
    eng: 'tokens',
    ru: 'токенов',
  },
  token_other: {
    eng: 'tokens',
    ru: 'токенов',
  },
  day_one: {
    eng: 'day',
    ru: 'день',
  },
  day_two: {
    eng: 'days',
    ru: 'дня',
  },
  day_few: {
    eng: 'days',
    ru: 'дня',
  },
  day_many: {
    eng: 'days',
    ru: 'дней',
  },
  day_other: {
    eng: 'days',
    ru: 'дней',
  },
  jayToken_one: {
    eng: 'J-token',
    ru: 'J-токен',
  },
  jayToken_two: {
    eng: 'J-tokens',
    ru: 'J-токена',
  },
  jayToken_few: {
    eng: 'J-tokens',
    ru: 'J-токена',
  },
  jayToken_many: {
    eng: 'J-tokens',
    ru: 'J-токенов',
  },
  jayToken_other: {
    eng: 'J-tokens',
    ru: 'J-токенов',
  },
  process_one: {
    ru: 'процесс',
    eng: 'process',
  },
  process_two: {
    ru: 'процесса',
    eng: 'processes',
  },
  process_few: {
    ru: 'процесса',
    eng: 'processes',
  },
  process_many: {
    ru: 'процессов',
    eng: 'processes',
  },
  process_other: {
    ru: 'процессов',
    eng: 'processes',
  },
  image_one: {
    eng: 'image',
    ru: 'изображение',
  },
  image_two: {
    eng: 'images',
    ru: 'изображения',
  },
  image_few: {
    eng: 'images',
    ru: 'изображения',
  },
  image_many: {
    eng: 'images',
    ru: 'изображений',
  },
  image_other: {
    eng: 'images',
    ru: 'изображений',
  },
  request_one: {
    eng: 'request',
    ru: 'запрос',
  },
  request_two: {
    eng: 'requests',
    ru: 'запроса',
  },
  request_few: {
    eng: 'requests',
    ru: 'запроса',
  },
  request_many: {
    eng: 'requests',
    ru: 'запросов',
  },
  request_other: {
    eng: 'requests',
    ru: 'запросов',
  },
  character_one: {
    eng: 'character',
    ru: 'символ',
  },
  character_two: {
    eng: 'characters',
    ru: 'символа',
  },
  character_few: {
    eng: 'characters',
    ru: 'символа',
  },
  character_many: {
    eng: 'characters',
    ru: 'символов',
  },
  character_other: {
    eng: 'characters',
    ru: 'символов',
  },
  'Billing:Modal:title': {
    eng: 'Top up balance',
    ru: 'Пополнить баланс',
  },
  'Billing:Modal:title:org': {
    eng: 'Not enough tokens',
    ru: 'Не хватает токенов',
  },
  'Billing:Modal:info:org': {
    eng: 'You have run out of tokens. Please contact our technical support.',
    ru: 'У вас закончились токены. Обратитесь в техническую поддержку.',
  },
  'Billing:Modal:info:admin': {
    eng: 'The token balance is exhausted. Please contact your account administrator.',
    ru: 'Баланс токенов исчерпан. Обратитесь к администратору аккаунта.',
  },
  'Billing:Modal:info': {
    eng: 'You have {balance} {tokenString} left. It is not enough for your request. Buy tokens to continue using #{config.productName}.',
    ru: 'У вас осталось {balance} {tokenString}. Этого не хватит, чтобы выполнить ваш запрос. Купите токены, чтобы продолжить использовать #{config.productName}.',
  },
  'Billing:Card:title': {
    eng: '{amount} {tokenString}',
    ru: '{amount} {tokenString}',
  },
  'Billing:Card:title:corporate': {
    eng: 'Enterprise plan',
    ru: 'Корпоративный тариф',
  },
  'Billing:Card:info:corporate': {
    eng: 'We will provide access to #{config.productName} for you and your colleagues, find an optimal subscription plan and payment method',
    ru: 'Дадим доступ к #{config.productName} для вас и ваших коллег, подберем оптимальный тариф и способ оплаты',
  },
  'Billing:Modal:buy': {
    eng: 'Top up',
    ru: 'Пополнить',
  },
  'Billing:Modal:continue': {
    eng: 'Proceed',
    ru: 'Продолжить',
  },
  'Billing:Modal:sub': {
    eng: 'Pricing details',
    ru: 'Особенности тарификации',
  },
  RequestForFeature: {
    eng: 'You request has been sent',
    ru: 'Заявка отправлена',
  },
  'Modal_RequestFeatureFor_corporate:title': {
    eng: 'Enterprise plan request',
    ru: 'Заявка на корпоративный тариф',
  },
  'Modal_RequestFeatureFor_corporate:info': {
    eng: 'We will install #{config.productName}  for your company, discuss advanced usage, and provide flexible pricing.',
    ru: 'Установим #{config.productName} для вашей компании, обсудим расширенное применение, подберем гибкую тарификацию.',
  },
  'Modal_RequestFeatureFor_justGPT:title': {
    eng: 'Be the first to try JustGPT',
    ru: 'Попробуйте JustGPT первыми',
  },
  'Modal_RequestFeatureFor_justGPT:header': {
    eng: 'Use JustGPT — an LLM which is perfect for working with content in Russian',
    ru: 'Используйте JustGPT — LLM, которая отлично подходит для работы с контентом на русском языке',
  },
  'Modal_RequestFeatureFor_justGPT:body': {
    eng: 'This model is trained on more than 70 billion parameters and can handle various business tasks: it generates marketing content, writes code, solves complex problems, and is great for working with legal documents. The application allows you to set up parameters with which you can control the generation of model responses.',
    ru: 'Модель, обученная на более чем 70 миллиардах параметров, прекрасно справляется с различными бизнес-задачами: генерирует маркетинговый контент, пишет код, решает сложные задачи, отлично походит для работы с юридическими документами. В приложении доступна настройка параметров, с помощью которых вы можете управлять генерацией ответов модели.',
  },
  'Modal_RequestFeatureFor:submit': {
    eng: 'Send request',
    ru: 'Оставить заявку',
  },
  'Modal_RequestFeatureFor_mistral:title': {
    eng: 'Try Saiga Mistral first',
    ru: 'Попробуйте приложение Сайга-Мистраль первыми',
  },
  'Modal_RequestFeatureFor_mistral:header': {
    eng: 'Saiga-Mistral is now in #{config.productName}',
    ru: 'Сайга-Мистраль уже в #{config.productName}',
  },
  'Modal_RequestFeatureFor_mistral:body': {
    eng: 'We’ve added the Saiga-Mistral application to #{config.productName} so you can test it out. Be the first to try!',
    ru: 'Мы добавили приложение прямого доступа к модели Сайга-Мистраль, чтобы вы могли потестировать ее. Будьте первыми!',
  },
  'Modal_RequestFeatureFor:subBody': {
    eng: 'Send a request. We will contact you to discuss the project details.',
    ru: 'Оставьте заявку, и мы свяжемся с вами, чтобы обсудить детали проекта.',
  },
  'Modal_RequestFeatureFor_openAiFileSearch:title': {
    eng: 'Try the “Knowledge base Lite” application',
    ru: 'Попробуйте приложение «База знаний Лайт»',
  },
  'Modal_RequestFeatureFor_openAiFileSearch:header': {
    eng: 'Create your own knowledge base!',
    ru: 'Создайте свою базу знаний!',
  },
  'Modal_RequestFeatureFor_openAiFileSearch:body': {
    eng: 'Use your data to create your own knowledge base. The “Knowledge base Lite” application can work with up to 3 documents at once. These can be any materials: regulatory legal acts, user manuals, data tables. The knowledge base provides instant and convenient access to the information you need.',
    ru: 'Используйте ваши данные, чтобы создать свою базу знаний. Приложение «База знаний Лайт» может работать сразу с 3 документами. Это могут быть любые материалы: нормативные правовые акты, руководства пользователя, таблицы с данными. База знаний обеспечит мгновенный и удобный доступ к нужной информации.',
  },
  'Modal_RequestFeatureFor_openAiFileSearch:subBody': {
    eng: 'Leave a request, and we’ll get in touch with you.',
    ru: 'Оставьте заявку, и мы свяжемся с вами.',
  },
  'Modal_RequestFeatureFor_openAiLegalKnowledgeBase:title': {
    eng: '',
    ru: 'Вопросно-ответная система по законам',
  },
  'Modal_RequestFeatureFor_openAiLegalKnowledgeBase:header': {
    eng: '',
    ru: 'Оставьте заявку на использование приложения',
  },
  'Modal_RequestFeatureFor_openAiLegalKnowledgeBase:body': {
    eng: '',
    ru: 'Приложение «Вопросно-ответная система по законодательству» доступно только по запросу. Укажите свои контакты, и мы свяжемся с вами в ближайшее время.',
  },
  'Modal_RequestFeatureFor_openAiLegalKnowledgeBase:subBody': {
    eng: '',
    ru: ' ',
  },
  'Modal_RequestFeatureFor_openAiTenderKnowledgeBase:title': {
    eng: '',
    ru: 'Вопросно-ответная система по госзакупкам',
  },
  'Modal_RequestFeatureFor_openAiTenderKnowledgeBase:header': {
    eng: '',
    ru: 'Оставьте заявку на использование приложения',
  },
  'Modal_RequestFeatureFor_openAiTenderKnowledgeBase:body': {
    eng: '',
    ru: 'Приложение «Вопросно-ответная система по госзакупкам» доступно только по запросу. Укажите свои контакты, и мы свяжемся с вами в ближайшее время.',
  },
  'Modal_RequestFeatureFor_openAiTenderKnowledgeBase:subBody': {
    eng: '',
    ru: ' ',
  },
  'Modal_RequestFeatureFor_docCompare:title': {
    eng: '',
    ru: 'Сверка разных редакций документа',
  },
  'Modal_RequestFeatureFor_docCompare:header': {
    eng: '',
    ru: 'Оставьте заявку на использование приложения',
  },
  'Modal_RequestFeatureFor_docCompare:body': {
    eng: '',
    ru: 'Приложение «Сверка разных редакций документа» доступно только по запросу. Укажите свои контакты, и мы свяжемся с вами в ближайшее время.',
  },
  'Modal_RequestFeatureFor_docCompare:subBody': {
    eng: '',
    ru: ' ',
  },
  'Modal_RequestFeatureFor_database:title': {
    eng: '',
    ru: 'Подключить Базу знаний',
  },
  'Modal_RequestFeatureFor_database:header': {
    eng: '',
    ru: 'Поможем создать Базу знаний, обученную на ваших документах.',
  },
  'Modal_RequestFeatureFor_database:body': {
    eng: '',
    ru: 'Это могут быть любые текстовые материалы: внутренняя база знаний, шаблоны ответов поддержки, руководства пользователя. Вы сможете задавать вопросы по их содержанию в готовом приложении.',
  },
  account: {
    eng: 'Account',
    ru: 'Личный кабинет',
  },
  tokenBalance: {
    eng: 'J-token balance',
    ru: 'Баланс J-токенов',
  },
  tokenTooltip: {
    eng: 'J-tokens are the billing units in #{config.productName}. They measure your application’s requests and responses. J-tokens are not the same as the tokens typically used by AI models.',
    ru: 'J-токены — это единицы тарификации в #{config.productName}. В них измеряются ваши запросы и ответы приложений. J-токены не равны токенам, которыми обычно оперируют нейросети.',
  },
  'tokenTooltip:more': {
    eng: 'More info about #{config.productName} billing',
    ru: 'Подробнее о тарификации #{config.productName}',
  },
  payBtnCaption: {
    eng: 'Top up',
    ru: 'Пополнить',
  },
  tokenLimit: {
    eng: 'Your J-token balance',
    ru: 'Ваш остаток J-токенов',
  },
  inviteUsers: {
    eng: 'Invite users',
    ru: 'Пригласить пользователей',
  },
  code: {
    eng: 'Code',
    ru: 'Код',
  },
  promocode: {
    eng: 'Promocode',
    ru: 'Промокод',
  },
  enterPromocode: {
    eng: 'Enter promocode',
    ru: 'Введите промокод',
  },
  promocodeSuccess: {
    eng: 'Promo code activated, your balance topped up by {amount} {pluralString}',
    ru: 'Промокод активирован, баланс пополнен на {amount} {pluralString}',
  },
  activatePromocode: {
    eng: 'Activate',
    ru: 'Активировать',
  },
  'Payment:pending_one': {
    eng: 'Your payment is being processed. We will notify you as soon as it is completed.',
    ru: 'Ваш платеж обрабатывается. Мы сообщим вам, как только он будет завершен.',
  },
  'Payment:pending_many': {
    en: '{amount} {pluralString} of payment have been initiated. We will keep you updated as the payments are processed',
    ru: 'Запущено {amount} {pluralString} оплаты. Мы будем сообщать вам по мере того, как платежи будут обрабатываться',
  },
  'Payment:done': {
    eng: 'Token balance has been topped up. Thank you for using #{config.productName}!',
    ru: 'Баланс токенов пополнен. Спасибо, что пользуетесь #{config.productName}!',
  },
  'Payment:failed': {
    eng: 'Payment failed. Please try again or contact our support team.',
    ru: 'Оплата не прошла. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'Modal_RequestFeature:option:ecom': {
    eng: 'E-com',
    ru: 'E-com',
  },
  'Modal_RequestFeature:option:fintech': {
    eng: 'Fintech',
    ru: 'Fintech',
  },
  'Modal_RequestFeature:option:it': {
    eng: 'IT/Software development',
    ru: 'IT/Разработка ПО',
  },
  'Modal_RequestFeature:option:gov': {
    eng: 'State company',
    ru: 'Гос. компания',
  },
  'Modal_RequestFeature:option:housing': {
    eng: 'Housing and communal services',
    ru: 'ЖКХ',
  },
  'Modal_RequestFeature:option:logistics': {
    eng: 'Logistics/transportation/customs',
    ru: 'Логистика/перевозки/таможня',
  },
  'Modal_RequestFeature:option:marketing': {
    eng: 'Marketing',
    ru: 'Маркетинг',
  },
  'Modal_RequestFeature:option:medicine': {
    eng: 'Medicine/pharmacology/beauty',
    ru: 'Медицина/фармакология/красота',
  },
  'Modal_RequestFeature:option:realEstate': {
    eng: 'Real estate',
    ru: 'Недвижимость',
  },
  'Modal_RequestFeature:option:edu': {
    eng: 'Education/training',
    ru: 'Образование/тренинги',
  },
  'Modal_RequestFeature:option:production': {
    eng: 'Production',
    ru: 'Производство',
  },
  'Modal_RequestFeature:option:industry': {
    eng: 'Industry',
    ru: 'Промышленность',
  },
  'Modal_RequestFeature:option:horeca': {
    eng: 'Restaurants (hospitality industry, delivery)',
    ru: 'Рестораны (HoReCa, доставка)',
  },
  'Modal_RequestFeature:option:retail': {
    eng: 'Retail/FMCG',
    ru: 'Ритейл/FMCG',
  },
  'Modal_RequestFeature:option:trading': {
    eng: 'Retail',
    ru: 'Розничная торговля',
  },
  'Modal_RequestFeature:option:telecom': {
    eng: 'Telecom (contact centers and networks)',
    ru: 'Телеком (КЦ и сети)',
  },
  'Modal_RequestFeature:option:other': {
    eng: 'Other',
    ru: 'Другое',
  },
  'Modal_RequestFeature:label:name': {
    eng: 'Full name',
    ru: 'Имя и фамилия',
  },
  'Modal_RequestFeature:label:companyName': {
    eng: 'Company name',
    ru: 'Название компании',
  },
  'Modal_RequestFeature:label:phone': {
    eng: 'Work phone number',
    ru: 'Рабочий номер телефона',
  },
  'Modal_RequestFeature:label:industry': {
    eng: 'Industry',
    ru: 'Отрасль/индустрия',
  },
  'Modal_RequestFeature:label:email': {
    eng: 'Work email',
    ru: 'Рабочий email',
  },
  'Modal_RequestFeature:label:comment': {
    eng: 'Comment (optional)',
    ru: 'Комментарий (опционально)',
  },
  'Account:Apikey:Modal:title': {
    eng: 'New API key generated',
    ru: 'Новый API-ключ сгенерирован',
  },
  'Account:Apikey:Modal:titleDel': {
    eng: 'Delete API key?',
    ru: 'Удалить API-ключ?',
  },
  'Account:Apikey:Modal:infoDel': {
    eng: 'The API key will be deleted and will no longer work. All integrations using this key will stop functioning.',
    ru: 'API-ключ будет удален и больше не будет работать. Все интеграции с ним перестанут функционировать.',
  },
  'Account:Apikey:Modal:info': {
    eng: 'The key was copied to the clipboard. Save the key, you cannot get it again. If you have any questions, please contact technical support.',
    ru: 'Ключ скопирован в буфер обмена. Сохраните ключ, получить его повторно невозможно. По всем вопросам обращайтесь в техническую поддержку.',
  },
  'AccountPage:MainTabs:Main': {
    eng: 'General',
    ru: 'Общее',
  },
  'AccountPage:MainTabs:Analytics': {
    eng: 'Analytics',
    ru: 'Аналитика',
  },
  'AccountPage:MainTabs:Limit': {
    eng: 'Limit',
    ru: 'Лимит',
  },
  'AccountPage:MainTabs:Limits': {
    eng: 'Limits',
    ru: 'Лимиты',
  },
  'AccountPage:MainTabs:ApiKeys': {
    eng: 'API keys',
    ru: 'API-ключи',
  },
  'AccountPage:MainTabs:ExternalApps': {
    eng: 'External apps',
    ru: 'Внешние приложения',
  },
  'Analytics:period': {
    eng: 'Period',
    ru: 'Период',
  },
  'Analytics:Tab:Expenses': {
    eng: 'Expenses',
    ru: 'Расходы',
  },
  'Analytics:Tab:MyExpenses': {
    eng: 'My expenses',
    ru: 'Мои расходы',
  },
  'Analytics:Tab:UserExpenses': {
    eng: 'Users expenses',
    ru: 'Расходы по пользователям',
  },
  'Analytics:Tab:ExpensesByDay': {
    eng: 'Expenses by day',
    ru: 'Расходы по дням',
  },
  'Analytics:Tab:Replenishment': {
    eng: 'Replenishment',
    ru: 'Пополнения',
  },
  'Analytics:Button:Download': {
    eng: 'Download the report in XLSX',
    ru: 'Скачать отчет в XLSX',
  },
  'Analytics:ExpensesByPeriod': {
    eng: 'Consumption for the selected period: ',
    ru: 'Расход за выбранный период: ',
  },
  Tokens: {
    eng: '{tokenAmount} JT',
    ru: '{tokenAmount} JT',
  },
  Expenses: {
    eng: 'Expense',
    ru: 'Расход',
  },
  'Analytics:UserExpenses': {
    eng: "User's expense history",
    ru: 'История расходов пользователя',
  },
  'Analytics:chatHistory': {
    eng: '',
    ru: 'История диалога',
  },
  'Analytics:Search:Placeholder': {
    eng: 'Login, email, or username',
    ru: 'Логин, email или имя пользователя',
  },
  'Analytics:Expenses:Empty': {
    eng: 'During this period, #{config.productName} was not used',
    ru: 'В этом периоде #{config.productName} не пользовались',
  },
  'Analytics:Expenses:Empty:Search': {
    eng: 'There are no such users',
    ru: 'Таких пользователей нет',
  },
  'Analytics:Replenish:Empty': {
    eng: 'There are no top-ups in this period',
    ru: 'В этом периоде не было пополнений',
  },
  'Analytics:TokenReplenishType:started': {
    eng: 'Bonus replenishment',
    ru: 'Бонусное пополнение',
  },
  'Analytics:TokenReplenishType:bonus': {
    eng: 'Bonus replenishment',
    ru: 'Бонусное пополнение',
  },
  'Analytics:TokenReplenishType:promo': {
    eng: 'Promocode',
    ru: 'Промокод ',
  },
  'Analytics:TokenReplenishType:paid': {
    eng: 'Purchasing a package of tokens',
    ru: 'Покупка пакета токенов',
  },
  'Analytics:TokenReplenishType:tariff': {
    eng: 'Replenishment according to tariff',
    ru: 'Пополнение по тарифу',
  },
  'Account:limit:amount': {
    eng: 'Limit: {amount} {tokenString}',
    ru: 'Лимит: {amount} {tokenString}',
  },
  'Account:limit:refresh': {
    eng: 'Refresh',
    ru: 'Обновление',
  },
  'Analytics:limit:period': {
    eng: 'Period',
    ru: 'Период',
  },
  'Account:limit:refreshWithDate': {
    eng: 'Refresh: {date}',
    ru: 'Обновление: {date}',
  },
  'Account:limits:info': {
    eng: 'The account administrator can set limits for users to control J‑token expenses',
    ru: 'Администратор аккаунта может установить лимиты для пользователей, чтобы контролировать расходы J‑токенов',
  },
  'Account:limits:addLimit': {
    eng: 'Add limit',
    ru: 'Добавить лимит',
  },
  'Account:limits:addLimitShort': {
    eng: 'Add',
    ru: 'Добавить',
  },
  'Account:limits:userEmail': {
    eng: 'User email',
    ru: 'Email пользователя',
  },
  'Account:limits:noLimit': {
    eng: 'No limit',
    ru: 'Нет лимита',
  },
  'Account:limits:generalLimit': {
    eng: 'General limit',
    ru: 'Общий лимит',
  },
  'Account:limits:generalLimitSub': {
    eng: 'For each user',
    ru: 'Для каждого пользователя',
  },
  'Limits:error': {
    eng: 'J-token limit reached, next limit refresh is on {date}',
    ru: 'Лимит J-токенов исчерпан, ближайшее обновление лимита — {date}',
  },
  'Limits:warning': {
    eng: 'You have less than {amount} {pluralString} remaining in the current period',
    ru: 'У вас осталось меньше {amount} {pluralString} в текущем периоде',
  },
  'Limits:deleteLimit': {
    eng: 'Delete limit',
    ru: 'Удалить лимит',
  },
  'Limits:deleteLimitInfo': {
    eng: 'The limit for user {email} will be removed. The general limit will apply to this user.',
    ru: 'Лимит для пользователя {email} будет удален. Для этого пользователя будет установлен общий лимит.',
  },
  'Limits:deleteLimitInfo:multiple': {
    eng: 'The limits for the following users will be removed: \n {email}. The general limit will apply to these users.',
    ru: 'Лимит для пользователей будет удален: \n {email}. Для этих пользователей будет установлен общий лимит.',
  },
  'Limits:editLimit': {
    eng: 'Edit limit',
    ru: 'Редактировать лимит',
  },
  'Limits:createLimit': {
    eng: 'New limit',
    ru: 'Новый лимит',
  },
  'Limits:specify': {
    eng: 'Specify',
    ru: 'Указать',
  },
  'Limits:specifyNo': {
    eng: 'No',
    ru: 'Нет',
  },
  'Limits:periodWeekly': {
    eng: 'Weekly',
    ru: 'Еженедельно',
  },
  'Limits:periodWeeklyMon': {
    eng: 'Weekly, starting Monday',
    ru: 'Еженедельно, с понедельника',
  },
  'Limits:periodSpecify': {
    eng: 'Custom period in days (starting today)',
    ru: 'Свой период в днях (начиная с сегодня)',
  },
  'Limits:limitFormHeader:limit': {
    eng: 'J-token limit per user',
    ru: 'Лимит J-токенов на пользователя',
  },
  'Limits:limitFormHeader:period': {
    eng: 'Refresh period',
    ru: 'Период обновления',
  },
  'Limits:generalEditInfo': {
    eng: 'Here you can set how many J-tokens each user can spend and the limit refresh period.',
    ru: 'Здесь вы можете настроить, сколько J-токенов может потратить каждый пользователь и период обновления лимита.',
  },
  'Limits:newLimitInfo': {
    eng: 'Here you can set how many J-tokens specific users can spend and specify the limit refresh period',
    ru: 'Здесь вы можете настроить, сколько J-токенов могут потратить конкретные пользователи и указать период обновления лимита',
  },
  'Limits:editLimitInfo': {
    eng: 'You can change the J-token limit and its refresh period for the user',
    ru: 'Вы можете изменить лимит J-токенов и период его обновления для пользователя',
  },
  'Limits:users': {
    eng: 'Users',
    ru: 'Пользователи',
  },
  'Limits:periodDaysString': {
    eng: '{amount} {pluralString}',
    ru: '{amount} {pluralString}',
  },
  'Account:apikeys:info': {
    eng: 'API keys are required to authenticate when using the #{config.productName} API. With the API, you can set up integrations with your #{config.productName} applications.',
    ru: 'API-ключи нужны, чтобы авторизоваться при работе с API #{config.productName}. \nС помощью API вы можете, например, настраивать интеграции с текущими приложениями #{config.productName}.',
  },
  'Account:apikeys:more': {
    eng: 'Documentation for #{config.productName} API',
    ru: 'Документация API #{config.productName}',
  },
  'Account:apiKeys:createKey': {
    eng: 'Create API Key',
    ru: 'Создать API-ключ',
  },
  'AccountPage:apikey:key': {
    eng: 'Key',
    ru: 'Ключ',
  },
  'AccountPage:apikey:owner': {
    eng: 'Owner',
    ru: 'Владелец',
  },
  'AccountPage:apikey:created': {
    eng: 'Created',
    ru: 'Создан',
  },
  'AccountPage:apikey:lastUsed': {
    eng: 'Last used',
    ru: 'Последнее использование',
  },
  'AccountPage:apikey:added': {
    eng: 'Added',
    ru: 'Добавлен',
  },
  'AccountPage:apikey:createFirstHeader': {
    eng: 'Create your first API key…',
    ru: 'Создайте первый API-ключ…',
  },
  'AccountPage:apikey:createFirstInfo': {
    eng: 'And you will be able to use the #{config.productName} API',
    ru: 'И вы сможете использовать API #{config.productName}',
  },
  'AccountPage:apikey:fetchError': {
    eng: 'Failed to retrieve the list of keys',
    ru: 'Не удалось получить список ключей',
  },
  'AccountPage:dTemplate:fetchError': {
    eng: 'Failed to retrieve the list of applications',
    ru: 'Не удалось получить список приложений',
  },
  'AccountPage:dTemplate:createFirstHeader': {
    eng: '',
    ru: 'Нет приложений',
  },
  'AccountPage:dTemplate:createFirstInfo': {
    eng: '',
    ru: 'Создайте свое первое приложение',
  },
  'Account:dTemplates:info': {
    eng: '',
    ru: 'Добавляйте приложения и интеграции, тестируйте их и делитесь с пользователями вашего аккаунта',
  },
  'Account:dTemplates:more': {
    eng: '',
    ru: 'Как создавать приложения',
  },
  'Account:dTemplates:create': {
    eng: '',
    ru: 'Добавить',
  },
  'Account:dTemplates:kHubOption': {
    eng: '',
    ru: 'Базу знаний',
  },
  'Account:dTemplates:kHubOptionInfo': {
    eng: '',
    ru: 'Из Jay Knowledge Hub',
  },
  'Account:dTemplates:customApp': {
    eng: '',
    ru: 'Свое приложение',
  },
  'Account:dTemplates:comingSoon': {
    eng: '',
    ru: 'Скоро будет доступно',
  },
  'AccountPage:dTemplate:state': {
    eng: '',
    ru: 'Статус',
  },
  'AccountPage:dTemplate:title': {
    eng: '',
    ru: 'Название',
  },
  'AccountPage:dTemplate:createdAt': {
    eng: '',
    ru: 'Добавлено',
  },
  'AccountPage:dTemplate:updatedAt': {
    eng: '',
    ru: 'Дата изменения',
  },
  'DynamicTemplate:Title:Label': {
    eng: '',
    ru: 'Название приложения',
  },
  'DynamicTemplate:Title:Hint': {
    eng: '',
    ru: 'Будет отображаться в каталоге',
  },
  'DynamicTemplate:Description:Label': {
    eng: '',
    ru: 'Описание (опционально)',
  },
  'DynamicTemplate:AuthToken:Label': {
    eng: '',
    ru: 'API-ключ базы знаний',
  },
  'DynamicTemplate:AuthToken:Hint': {
    eng: '',
    ru: 'Получить ключ можно в настройках вашего проекта Jay Knowledge Hub',
  },
  'DynamicTemplate:Category:Label': {
    eng: '',
    ru: 'Раздел каталога',
  },
  'DynamicTemplate:Apps:Limit': {
    eng: '',
    ru: 'приложений',
  },
  'Account:DTemplate:Modal:titleDel': {
    eng: '',
    ru: 'Удалить приложение',
  },
  'Account:DTemplate:Modal:infoDel': {
    eng: '',
    ru: 'Вы уверены, что хотите удалить приложение «{name}»? Оно станет недоступным для других пользователей текущего аккаунта.',
  },
  'Account:DTemplate:Modal:titleCreate': {
    eng: '',
    ru: 'Добавить базу знаний из Jay Knowledge Hub',
  },
  'DTemplates:table:type:khubProxy': {
    eng: '',
    ru: 'Проект Jay Knowledge Hub',
  },
};
