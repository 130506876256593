export const agentsRelatedLocalization = {
  Russian: {
    eng: 'Russian',
    ru: 'Русский',
  },
  English: {
    eng: 'English',
    ru: 'Английский',
  },
  German: {
    eng: 'German',
    ru: 'Немецкий',
  },
  Spanish: {
    eng: 'Spanish',
    ru: 'Испанский',
  },
  French: {
    eng: 'French',
    ru: 'Французский',
  },
  Italian: {
    eng: 'Italian',
    ru: 'Итальянский',
  },
  Portuguese: {
    eng: 'Portuguese',
    ru: 'Португальский',
  },
  'Chinese (simplified)': {
    eng: 'Chinese (simplified)',
    ru: 'Китайский (упрощенный)',
  },
  Turkish: {
    eng: 'Turkish',
    ru: 'Турецкий',
  },
  Adventure: {
    eng: 'Adventure',
    ru: 'Приключение',
  },
  Comedy: {
    eng: 'Comedy',
    ru: 'Комедия',
  },
  Detective: {
    eng: 'Detective',
    ru: 'Детектив',
  },
  Epistolary: {
    eng: 'Epistolary',
    ru: 'Эпистолярный рассказ',
  },
  Fable: {
    eng: 'Fable',
    ru: 'Басня',
  },
  Fairytale: {
    eng: 'Fairytale',
    ru: 'Сказка',
  },
  Fantasy: {
    eng: 'Fantasy',
    ru: 'Фэнтези',
  },
  Heroic: {
    eng: 'Heroic',
    ru: 'Эпос',
  },
  Historical: {
    eng: 'Historical',
    ru: 'Исторический рассказ',
  },
  Horror: {
    eng: 'Horror',
    ru: 'Ужасы',
  },
  'Love story': {
    eng: 'Love story',
    ru: 'Романтика',
  },
  Philosophical: {
    eng: 'Philosophical',
    ru: 'Философский рассказ',
  },
  Religious: {
    eng: 'Religious',
    ru: 'Религиозный рассказ',
  },
  Satiric: {
    eng: 'Satiric',
    ru: 'Сатира',
  },
  'Science fiction': {
    eng: 'Science fiction',
    ru: 'Научная фантастика',
  },
  Thriller: {
    eng: 'Thriller',
    ru: 'Триллер',
  },
  Tragic: {
    eng: 'Tragic',
    ru: 'Трагедия',
  },
  '3D graphics': {
    eng: '3D graphics',
    ru: '3D-графика',
  },
  'Sci-fi': {
    eng: 'Sci-fi',
    ru: 'Sci-fi',
  },
  Anime: {
    eng: 'Anime',
    ru: 'Аниме',
  },
  Comic: {
    eng: 'Comic',
    ru: 'Комикс',
  },
  Cyberpunk: {
    eng: 'Cyberpunk',
    ru: 'Киберпанк',
  },
  Photo: {
    eng: 'Photo',
    ru: 'Фотография',
  },
  'Pixel art': {
    eng: 'Pixel art',
    ru: 'Пиксель-арт',
  },
  Postapocalyptic: {
    eng: 'Postapocalyptic',
    ru: 'Постапокалипсис',
  },
  Realistic: {
    eng: 'Realistic',
    ru: 'Реализм',
  },
  Steampunk: {
    eng: 'Steampunk',
    ru: 'Стимпанк',
  },
  'Acrylic painting': {
    eng: 'Acrylic painting',
    ru: 'Акрил',
  },
  Oil: {
    eng: 'Oil',
    ru: 'Масло',
  },
  Pencil: {
    eng: 'Pencil',
    ru: 'Карандаш',
  },
  'Rough charcoal sketch': {
    eng: 'Rough charcoal sketch',
    ru: 'Уголь',
  },
  'Watercolor painting': {
    eng: 'Watercolor painting',
    ru: 'Акварель',
  },
  'GPT-3.5': {
    eng: 'gpt-3.5-turbo',
    ru: 'gpt-3.5-turbo',
  },
  'GPT-4': {
    eng: 'gpt-4',
    ru: 'gpt-4',
  },
  agentUpdateModalText: {
    eng: 'You want to update the application settings. The chat history will be saved, but the application will start responding to your requests without taking past messages into account. Do you want to continue?',
    ru: 'Вы хотите обновить настройки приложения. История переписки сохранится, но приложение начнет отвечать на ваши запросы без учета прошлых сообщений. Продолжить?',
  },
  agentUpdateModalTitle: {
    eng: 'Confirm updating',
    ru: 'Подтвердите обновление',
  },
  agentUpdateSuccess: {
    eng: 'Applet updated successfully',
    ru: 'Приложение успешно обновлено',
  },
  agentContextReloadSuccess: {
    eng: 'Context has been reset',
    ru: 'Контекст сброшен',
  },
  TemplateFormInvalid: {
    eng: 'Form is not valid. Please check fields before saving.',
    ru: 'Заполните все обязательные поля перед сохранением приложения',
  },
  'TemplateList:Category:allCategories': {
    eng: 'All categories',
    ru: 'Все категории',
  },
  'TemplateList:CategoryModal:allCategories': {
    eng: 'Applet categories',
    ru: 'Категории приложений',
  },
  'TemplateList:Category:all': {
    eng: 'All',
    ru: 'Все',
  },
  'TemplateList:Category:beta': {
    eng: 'Beta',
    ru: 'Beta',
  },
  'TemplateList:Category:favor': {
    eng: 'Favorites',
    ru: 'Избранное',
  },
  'TemplateList:Category:assistants': {
    eng: 'Personal productivity',
    ru: 'Личная эффективность',
  },
  'TemplateList:Category:assistants-sm': {
    eng: 'Helpers',
    ru: 'Помощники',
  },
  'TemplateList:Category:data': {
    eng: 'Files and data',
    ru: 'Работа с файлами и данными',
  },
  'TemplateList:Category:data--alpha': {
    eng: 'Customer service',
    ru: 'Клиентский сервис',
  },
  'TemplateList:Category:data--alpha-sm': {
    eng: 'Customers',
    ru: 'Клиенты',
  },
  'TemplateList:Category:data-sm': {
    eng: 'Data',
    ru: 'Данные',
  },
  'TemplateList:Category:marketing': {
    eng: 'Marketing',
    ru: 'Маркетинг',
  },
  'TemplateList:Category:marketing--alpha': {
    eng: 'Marketing and communications',
    ru: 'Маркетинг и коммуникации',
  },
  'TemplateList:Category:marketing--alpha-sm': {
    eng: 'Marketing',
    ru: 'Маркетинг',
  },
  'TemplateList:Category:texts': {
    eng: 'Texts',
    ru: 'Тексты',
  },
  'TemplateList:Category:secretary': {
    eng: 'Personal secretary',
    ru: 'Личный секретарь',
  },
  'TemplateList:Category:texts--alpha': {
    eng: 'Documents',
    ru: 'Документы',
  },
  'TemplateList:Category:images': {
    eng: 'Images',
    ru: 'Изображения',
  },
  'TemplateList:Category:images--alpha': {
    eng: 'Design',
    ru: 'Дизайн',
  },
  'TemplateList:Category:content': {
    eng: 'Content',
    ru: 'Контент',
  },
  'TemplateList:Category:popular': {
    eng: 'Popular',
    ru: 'Популярное',
  },
  'TemplateList:Category:directAccess': {
    eng: 'Direct access to AI',
    ru: 'Прямой доступ к нейросетям',
  },
  'TemplateList:Category:directAccess-sm': {
    eng: 'AI',
    ru: 'Нейросети',
  },
  'TemplateList:Category:it': {
    eng: 'Programming',
    ru: 'Программирование',
  },
  'TemplateList:Category:it-sm': {
    eng: 'Code',
    ru: 'Код',
  },
  'TemplateList:Category:it--alpha': {
    eng: 'IT development',
    ru: 'IT-разработка',
  },
  'TemplateList:Category:it--alpha-sm': {
    eng: 'Code',
    ru: 'Код',
  },
  'TemplateList:Category:database': {
    eng: 'Knowledge base',
    ru: 'База знаний',
  },
  'TemplateList:Category:legal': {
    ru: 'Ассистенты юриста',
  },
  'TemplateList:Category:legal-sm': {
    ru: 'Ассистенты юриста',
  },
  'TemplateList:Category:external': {
    eng: '',
    ru: 'Внешние приложения',
  },
  'TemplatesList:categoryEmpty': {
    eng: 'More applications coming soon here!',
    ru: 'Скоро здесь появятся новые приложения!',
  },
  Essay: {
    eng: 'Essay',
    ru: 'Эссе',
  },
  Advertisement: {
    eng: 'Advertisement',
    ru: 'Реклама',
  },
  'Blog record': {
    eng: 'Blog article',
    ru: 'Статья для блога',
  },
  'Magazine article': {
    eng: 'Magazine article',
    ru: 'Статья для журнала',
  },
  News: {
    eng: 'News',
    ru: 'Новость',
  },
  'Press release': {
    eng: 'Press releasee',
    ru: 'Пресс-релиз',
  },
  'Tabloid article': {
    eng: 'Tabloid article',
    ru: 'Статья для таблоида',
  },
  speaker: {
    eng: 'Speaker',
    ru: 'Диктор',
  },
  retelling: {
    eng: 'Podcast with one participant',
    ru: 'Подкаст с одним участником',
  },
  'podcast-2': {
    eng: 'Podcast with two participants',
    ru: 'Подскаст с двумя участниками',
  },
  'podcast-3': {
    eng: 'Podcast with three participants',
    ru: 'Подкаст с тремя участниками',
  },
};
