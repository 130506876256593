import { useState } from 'react';

import { goToTemplates } from '../../../routes';
import { Conversation } from '../../../types/chat';

export default function useConversationDeleteAction({
  selectedConversation,
  submitConversationDelete,
  pathname,
}: {
  selectedConversation: Conversation;
  submitConversationDelete: (conversationId: string) => void;
  pathname: string;
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const handleConfirmDelete = () => {
    setIsDeleting(false);
    if (pathname.includes(selectedConversation.id)) {
      goToTemplates();
    }
    submitConversationDelete(selectedConversation.id);
  };

  return {
    isDeleting,
    handleTriggerDelete: () => setIsDeleting(true),
    handleCancelDelete: () => setIsDeleting(false),
    handleConfirmDelete,
  };
}
