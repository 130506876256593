import { ConversationResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { computed, signal } from '@preact/signals-react';

import { Draft, produce } from 'immer';
import { Conversation } from '../../types/chat';
import { reduxConnectSignal } from '../../utils/reduxConnector';
import { createImmerSignal } from '../hooks';

export const [rawConversations, setRawConversations] = createImmerSignal<ConversationResponse[]>([]);
export const rawConversationsLoaded = signal(false);
reduxConnectSignal(
  'Raw Conversations',
  computed(() => ({ rawConversations: rawConversations.value, rawConversationsLoaded: rawConversationsLoaded.value }))
);

export const [conversationsSg, setOrdinaryConversationsValue] = createImmerSignal<Conversation[]>([]);
export const [mainConversationSg, setMainConversation] = createImmerSignal<Conversation | null>(null);
export const conversationsLoadedSg = signal(false);
export const conversationsInvalidatedSg = signal(false);
export const allConversationsSg = computed(() => {
  const mainConversation = mainConversationSg.value;
  if (mainConversation) {
    return [mainConversation, ...conversationsSg.value];
  }
  return conversationsSg.value;
});

function produceNextConversationsValue(callback) {
  return produce(allConversationsSg.value, (draft: Draft<Conversation[]>) => {
    callback(draft);
  });
}

export function setConversationsValue(updateOrCallback: Conversation[] | ((prev: Draft<Conversation[]>) => void)) {
  const updatedConversations =
    typeof updateOrCallback === 'function'
      ? [...produceNextConversationsValue(updateOrCallback)]
      : [...updateOrCallback];

  const mainConversation = mainConversationSg.value;
  const mainConversationIndex = mainConversation
    ? updatedConversations.findIndex(conversation => conversation.id === mainConversation.id)
    : -1;
  if (mainConversation && mainConversationIndex >= 0) {
    const mainConversationUpdate = updatedConversations.splice(mainConversationIndex, 1)[0];
    if (mainConversationUpdate !== mainConversation) {
      mainConversationSg.value = mainConversationUpdate;
    }
  }
  conversationsSg.value = updatedConversations;
}

export const newConversationLoading = signal(false);
reduxConnectSignal(
  'Conversations',
  computed(() => ({ conversations: allConversationsSg.value }))
);

export const defaultContextValue: Conversation['contextValue'] = {
  fullness: 0,
  messagesTruncationMode: false,
};

export const jGuardFailedToProtect = signal<boolean | undefined>(false);
export const setJGuardFailureToProtect = (value?: boolean) => {
  jGuardFailedToProtect.value = value;
};

export const jGuardModalShown = signal<boolean>(false);
export const setJGuardModalShown = (value: boolean) => {
  jGuardModalShown.value = value;
};
