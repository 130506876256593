import React, { FC, memo, useCallback } from 'react';

import { LinkResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { Checkbox } from '@just-ai/just-ui';

import styles from './style.module.scss';
import { Selected } from './useSelected';
import { mainConversationSg } from '../../../models/conversations/signals';
import { FileRowDropdown } from '../../../pages/Files/FileRowDropdown';
import { useFileUploadProvider } from '../../../pages/Files/FilesUploadProvider';
import { FilterRowIcon } from '../../../pages/Files/FilterRowIcon';
import { getOnlyDate } from '../../../pages/Files/utils';
import { goToMainConversation } from '../../../routes';
import { chatTextAppendSignal } from '../../Chat/signals/ChatUpdateSignal';

type TLinkTableRow = {
  link: LinkResponse;
  selected?: Selected;
  hideTableRowDropdown?: boolean;
  tableRowOnClick?: (file: LinkResponse) => unknown;
};

export const LinkTableRow: FC<TLinkTableRow> = memo(({ link, selected, hideTableRowDropdown, tableRowOnClick }) => {
  const { deleteLink } = useFileUploadProvider();

  const deleteItem = useCallback(() => {
    deleteLink(link.id);
  }, [deleteLink, link.id]);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(link.url);
  }, [link.url]);

  const openInNewTab = useCallback(() => {
    window.open(link.url, '_blank');
  }, [link.url]);

  const hasMainConversation = Boolean(mainConversationSg.value);
  const openInAssistant = useCallback(() => {
    if (!hasMainConversation) {
      return;
    }
    goToMainConversation();
    chatTextAppendSignal.value = link.url;
  }, [link.url, hasMainConversation]);

  const tableRowOnClickHandle = useCallback(() => {
    tableRowOnClick?.(link);
  }, [link, tableRowOnClick]);

  return (
    <tr className={styles.tr} data-test-id={`GenLinksRow.${link.id}`} onClick={tableRowOnClickHandle}>
      {selected && (
        <td style={{ transform: 'translateY(-3px)' }}>
          <Checkbox
            name={`checkbox${link.id}`}
            value={selected.isIncludes(link)}
            onChange={() => selected.selectedHandler(link)}
            data-test-id='GenLinkRow.Checkbox'
          />
        </td>
      )}

      <td data-test-id='GenLinkRow.Name'>
        <div className='d-flex align-items-center gap-8'>
          <FilterRowIcon favicon={link.faviconUrl} />
          <div className='flex flex-column'>
            {link.title === link.url ? (
              <div>{link.url}</div>
            ) : (
              <>
                <div>{link.title}</div>
                <div className='text-muted'>{link.url}</div>
              </>
            )}
          </div>
        </div>
      </td>
      <td data-test-id='GenLinkRow.CreatedAt'>{getOnlyDate(link.createdAt)}</td>
      {hideTableRowDropdown ? null : (
        <td>
          <FileRowDropdown
            deleteItem={deleteItem}
            copyLink={copyLink}
            openInNewTab={openInNewTab}
            openInAssistant={hasMainConversation ? openInAssistant : null}
          />
        </td>
      )}
    </tr>
  );
});
