import React, { useCallback, useEffect, useState } from 'react';

import { DynamicTemplateListResponseTemplates } from '@just-ai/api/src/generated/AppsAdapter';
import {
  Button,
  Icon,
  useTranslation,
  usePromiseProcessing,
  Spinner,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import axios from 'axios';
import cn from 'classnames';

import styles from './styles.module.scss';
import { appOptions } from '../../../api/cc.api';
import { addAlert } from '../../../models/alerts';
import { appsAdapterService } from '../../../services/service';
import DynamicTemplateModal from '../components/DynamicTemplateModal';
import DTemplatesTable from '../components/Tables/DynamicTemplatesTable';

const MAX_TEMPLATES = 5;

export type CrudModalTypes = 'create' | 'delete' | 'update';

export default function ExternalAppsTab() {
  const { t } = useTranslation();

  const [userDynamicTemplates, setUserDynamicTemplates] = useState<
    DynamicTemplateListResponseTemplates[] | undefined
  >();

  const [dTemplateModalShown, setDTemplateModalShown] = useState<{
    type: CrudModalTypes;
    value?: DynamicTemplateListResponseTemplates;
  }>();

  const [idsToEnable, setIdsToEnable] = useState<string[]>([]);

  // const [sortState, setSortState] = useState({ field: 'created', dir: 'desc' });

  const getDTemplatesList = useCallback(async () => {
    const { data: dTemplatesRes } = await appsAdapterService.getDynamicTemplates();
    setUserDynamicTemplates(dTemplatesRes.templates);
  }, []);

  const [{ loading }, fetchDTemplatesList] = usePromiseProcessing(async () => getDTemplatesList(), {
    deps: [getDTemplatesList],
    onError: error => {
      addAlert(t('AccountPage:dTemplate:fetchError'));
      AppLogger.error({ message: 'failed to fetch account dynamic templates', exception: error });
    },
  });

  const switchTemplateEnablement = useCallback(
    (templateId: string, value?: boolean) => {
      const templateToUpdateIndex = userDynamicTemplates?.findIndex(udt => udt.id === templateId);
      setUserDynamicTemplates(prevTemplates => {
        if (!prevTemplates || templateToUpdateIndex === undefined || !prevTemplates[templateToUpdateIndex])
          return prevTemplates;
        const updatedArr = [...prevTemplates];
        updatedArr[templateToUpdateIndex] = {
          ...updatedArr[templateToUpdateIndex],
          enabled: value !== undefined ? value : !updatedArr[templateToUpdateIndex].enabled,
        };
        return updatedArr;
      });
    },
    [userDynamicTemplates]
  );

  const updateTemplateEnablement = useCallback(
    async (templateId: string) => {
      try {
        if (idsToEnable?.includes(templateId)) return;
        switchTemplateEnablement(templateId);
        setIdsToEnable(prevIds => [...prevIds, templateId]);
        const { data: fullTemplateData } = await appsAdapterService.getDynamicTemplateById(templateId);
        await appsAdapterService.editDynamicTemplate(templateId, {
          ...fullTemplateData,
          enabled: !fullTemplateData.enabled,
        });
      } catch (error) {
        switchTemplateEnablement(templateId, false);
        if (axios.isAxiosError<{ error: string; errors: { field: string; problem: string }[] }>(error)) {
          if (error.response?.data.error === 'khub.proxy.project_not_exist') {
            return addAlert(t(error.response?.data.error));
          }
        }
        addAlert(t('enableTemplateError'));
      } finally {
        setIdsToEnable(prevIds => prevIds.filter(id => id !== templateId));
      }
    },
    [idsToEnable, switchTemplateEnablement, t]
  );

  // const handleSortClick = async (value: string) => {
  //   return setSortState(prevState => {
  //     if (prevState.field === value) return { field: value, dir: prevState.dir === 'desc' ? 'asc' : 'desc' };
  //     return { dir: 'desc', field: value };
  //   });
  // };

  useEffect(() => {
    fetchDTemplatesList();
  }, [fetchDTemplatesList]);

  const docs = appOptions.value?.product?.docs;

  return (
    <div className={cn('flex flex-column', styles.Limits__wrapper)}>
      <p className='mb-3'>{t('Account:dTemplates:info')}</p>
      {docs?.links['dynamic-templates'] && Object.keys(docs?.links).includes('dynamic-templates') && (
        <a
          className='flex gap-4 mb-5'
          href={`${t('docsUrl')}/${docs?.links['dynamic-templates']}/?${new URLSearchParams(docs.queryParams).toString()}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon name='farQuestionCircle' color='primary' />
          <b>{t('Account:dTemplates:more')}</b>
        </a>
      )}

      <div className='flex gap-8 items-center'>
        <DropdownButton direction='down' disabled={userDynamicTemplates?.length === MAX_TEMPLATES}>
          <DropdownToggle
            disabled={userDynamicTemplates?.length === MAX_TEMPLATES}
            color='primary'
            className='flex gap-6 items-center'
            data-test-id='DTemplates:create:dropdown'
          >
            {t('Account:limits:addLimitShort')}
            <Icon name='farChevronDown' />
          </DropdownToggle>
          <DropdownMenu data-test-id='dropdown-menu'>
            <DropdownItem
              data-test-id='DTemplates:create:dropdown:item'
              className='flex flex-column'
              onClick={() => setDTemplateModalShown({ type: 'create' })}
            >
              {t('Account:dTemplates:kHubOption')}
              <span className='text-small text-secondary'>{t('Account:dTemplates:kHubOptionInfo')}</span>
            </DropdownItem>
            <DropdownItem disabled className='flex flex-column'>
              {t('Account:dTemplates:customApp')}
              <span className='text-small text-secondary'>{t('Account:dTemplates:comingSoon')}</span>
            </DropdownItem>
          </DropdownMenu>
        </DropdownButton>
      </div>
      {loading && !dTemplateModalShown ? (
        <div className='h-full flex'>
          <Spinner />
        </div>
      ) : Boolean(userDynamicTemplates?.length) ? (
        <>
          <p className='mt-4'>
            <b>{`${userDynamicTemplates?.length}/${MAX_TEMPLATES} ${t('DynamicTemplate:Apps:Limit')}`}</b>
          </p>
          <DTemplatesTable
            dynamicTemplates={userDynamicTemplates || []}
            openModalHandler={setDTemplateModalShown}
            enableHandler={updateTemplateEnablement}
          />
        </>
      ) : (
        <div className={cn(styles.apiTab__empty, 'flex flex-column items-center justify-end')}>
          <Icon name='farGhost' size='3x' color='secondary' />
          <h3 className='mt-4'>{t('AccountPage:dTemplate:createFirstHeader')}</h3>
          <p className='mt-3'>{t('AccountPage:dTemplate:createFirstInfo')}</p>
          <Button
            data-test-id='DTemplates:create:table:btn'
            className='mt-4'
            color='primary'
            outline
            onClick={() => setDTemplateModalShown({ type: 'create' })}
          >
            {t('Create')}
          </Button>
        </div>
      )}
      {dTemplateModalShown && (
        <DynamicTemplateModal
          type={dTemplateModalShown.type}
          updateCallback={() => fetchDTemplatesList()}
          closeModal={() => setDTemplateModalShown(undefined)}
          templateData={dTemplateModalShown.value}
        />
      )}
    </div>
  );
}
