import React, { memo } from 'react';

import { Icon, useTranslation } from '@just-ai/just-ui';

import { appOptions } from '../../api/cc.api';
import { createDocsLink } from '../../utils/app/common';

function FilesHeader() {
  const { t } = useTranslation();

  const docs = appOptions.value?.product?.docs;

  return (
    <div className='flex-column gap-8'>
      <h1>{t('Files:Page:title')}</h1>
      <p>{t('Files:Page:subtitle')}</p>
      {docs && Object.keys(docs?.links).includes('files') && (
        <a
          className='font-weight-600 color-primary d-flex align-items-center gap-4 text-decoration-none'
          href={createDocsLink(t('docsUrl'), docs?.links?.files, docs?.queryParams)}
        >
          <Icon name='farQuestionCircle' />
          {t('Files:Page:link-text')}
        </a>
      )}
    </div>
  );
}

export default memo(FilesHeader);
