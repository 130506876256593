import React from 'react';

import { Button, Icon, Tooltip, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';

import { ConversationContext } from '../../../../types/chat';
import styles from '../header.module.scss';

type Props = {
  contextValue: ConversationContext;
  messageIsStreaming?: boolean;
  actionButtonTitle: string;
  onActionButtonClick: () => unknown;
};

export default function ChatHeaderDesktopContextControls({
  contextValue,
  messageIsStreaming,
  actionButtonTitle,
  onActionButtonClick: handleActionButtonClick,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={cn('btn-group')}>
      <div className={cn(styles.header__contextBlock)}>
        {t('ChatHeader:context')}:{' '}
        <b className={cn({ 'text-danger': contextValue.messagesTruncationMode })}>{contextValue.fullness}%</b>
        <div className={styles.header__qIcon} id='chatHeaderContextIcon'>
          <Icon className='flex' name='faQuestion' size='xs' />
        </div>
        <Tooltip target='chatHeaderContextIcon'>{t('ChatHeader:contextTooltip')}</Tooltip>
      </div>
      <Button
        size='sm'
        outline
        color='secondary'
        className={styles.header__reset}
        wrapperIconClassName={styles.header__icon}
        disabled={messageIsStreaming}
        onClick={handleActionButtonClick}
        data-test-id='Chat.ClearContextBtn'
      >
        {actionButtonTitle}
      </Button>
    </div>
  );
}
