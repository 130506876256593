import React, { memo } from 'react';

import cn from 'classnames';

import styles from './style.module.scss';
import { CloseSidebarButton } from '../../components/Sidebar/components/OpenCloseButton';
import { LogoComponent } from '../../components/Sidebar/LogoComponent';
import { useAppContext } from '../../contexts/appContext';

type HeaderProps = {
  noBorder?: boolean;
  fixed?: boolean;
  pageName?: string;
};

export const MobileHeader = memo((props: HeaderProps) => {
  const { noBorder, fixed, pageName } = props;

  const {
    state: { showChatbar },
    dispatch,
  } = useAppContext();

  return (
    <div
      className={cn('flex d-sm-none flex-grow-1', { 'border-bottom-gray-200': !noBorder, [styles.fixedHeader]: fixed })}
    >
      <div className='flex gap-16 padding-16'>
        <div className='flex d-sm-none'>
          <CloseSidebarButton onClick={() => dispatch({ field: 'showChatbar', value: !showChatbar })} side='left' />
        </div>
        {pageName ? <h3>{pageName}</h3> : <LogoComponent />}
      </div>
    </div>
  );
});

MobileHeader.displayName = 'MobileHeader';
