import React, { useState } from 'react';

import { TemplateResponse } from '@just-ai/api/dist/generated/AppsAdapter';
import { IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import { ChatHeaderSelectFileFromHistoryButton } from './buttons/ChatHeaderSelectFileFromHistoryButton';
import { CanvasLayoutDropdown } from './dropdowns/CanvasLayoutDropdown';
import { ChatLayoutDropdown } from './dropdowns/ChatLayoutDropdown';
import { isDemoMode } from '../../../../api/client';
import { showSignupModal } from '../../../../hooks/showSignup';
import filesPageStyles from '../../../../pages/Files/styles.module.scss';
import { Conversation } from '../../../../types/chat';
import { isMobile } from '../../../../utils/app/common';
import { handleAgentSettingsMobileToggle, isOpenAgentSettings } from '../../../Settings/AgentSettingsSidebar';
import { ChatHeaderContextControls, ChatHeaderContextModal } from '../ChatHeaderContextControls';
import styles from '../header.module.scss';

type Props = {
  selectedConversation: Conversation;
  currentTemplate: TemplateResponse;
  isCanvasApp: boolean;
  isMainConversation: boolean;
  isClearingInProgress: boolean;
  onTriggerConversationDelete: () => void;
  onTriggerConversationFileSelection?: (() => void) | null;
  onConfirmConversationReset: () => void;
  onConfirmAgentSettingsReset: () => void;
};

export default function ChatHeaderActionsPanel({
  selectedConversation,
  currentTemplate,
  isCanvasApp,
  isMainConversation,

  onTriggerConversationDelete: handleTriggerConversationDelete,
  onTriggerConversationFileSelection: handleTriggerConversationFileSelection,
  onConfirmConversationReset: handleConfirmConversationReset,
  onConfirmAgentSettingsReset: handleConfirmAgentSettingsReset,

  isClearingInProgress,
}: Props) {
  const isMobileLayout = isMobile();
  const isAgentSettingsVisible = isOpenAgentSettings.value;

  const shouldHideContextBlock =
    (!isMainConversation && !currentTemplate?.clientFeatures?.contextManagementEnabled) ||
    !Boolean(selectedConversation.contextValue);

  const [isClearModalShown, setIsClearModalShown] = useState(false);
  const handleClearAction = () => {
    if (isDemoMode.value) {
      showSignupModal(true);
    } else if (isMainConversation) {
      setIsClearModalShown(true);
    } else {
      handleConfirmAgentSettingsReset();
    }
  };

  const chatHeaderContextControlsNode = (
    <ChatHeaderContextControls
      selectedConversation={selectedConversation}
      isMainConversation={isMainConversation}
      isMobileLayout={isMobileLayout}
      onClearButtonClick={() => handleClearAction()}
      onTriggerConversationDelete={handleTriggerConversationDelete}
      onTriggerConversationFileSelection={handleTriggerConversationFileSelection}
    />
  );

  const chatHeaderContextModalNode = (
    <ChatHeaderContextModal
      isClearModalShown={isClearModalShown}
      isClearingInProgress={isClearingInProgress}
      onConfirmClearAction={() => {
        setIsClearModalShown(false);
        handleConfirmConversationReset();
      }}
      onCancelClearAction={() => setIsClearModalShown(false)}
    />
  );

  return (
    <div className={cn('flex ', { 'gap-8': !isMobileLayout, 'gap-16': isMobileLayout })}>
      {!isMobileLayout && !shouldHideContextBlock && !isCanvasApp ? (
        <>
          {chatHeaderContextControlsNode}
          {chatHeaderContextModalNode}
        </>
      ) : null}
      {isMainConversation ? (
        isCanvasApp ? (
          <>
            <CanvasLayoutDropdown>
              {chatHeaderContextControlsNode}
              {handleTriggerConversationFileSelection ? (
                <ChatHeaderSelectFileFromHistoryButton
                  renderAs='dropdownItem'
                  className={cn(filesPageStyles.dropdownMenu__Item, 'text-nowrap', 'mt-3')}
                  iconProps={{ color: 'secondary', size: 'md' }}
                  onClick={handleTriggerConversationFileSelection}
                />
              ) : null}
            </CanvasLayoutDropdown>
            {chatHeaderContextModalNode /* outside of dropdown expanding menu, to prevent unexpected remount */}
          </>
        ) : (
          <ChatLayoutDropdown
            selectedConversation={selectedConversation}
            onDeleteClick={null}
            onSelectFileClick={handleTriggerConversationFileSelection}
          />
        )
      ) : null}
      {isMobileLayout && isAgentSettingsVisible ? (
        <IconButton
          data-test-id='Chat.agent_settings_btn'
          className={cn(styles.header__icon, 'd-sm-none')}
          name='farTimes'
          onClick={handleAgentSettingsMobileToggle}
        />
      ) : null}
      {!(isMobileLayout && isAgentSettingsVisible) && !isMainConversation ? (
        <div className={cn('flex', { 'flex-row-reverse': isMobileLayout, 'gap-16': isMobileLayout })}>
          {isMobileLayout && !shouldHideContextBlock ? (
            <>
              {chatHeaderContextControlsNode}
              {chatHeaderContextModalNode}
            </>
          ) : (
            <ChatLayoutDropdown
              selectedConversation={selectedConversation}
              onDeleteClick={handleTriggerConversationDelete}
              onSelectFileClick={handleTriggerConversationFileSelection}
            />
          )}
        </div>
      ) : null}
    </div>
  );
}
