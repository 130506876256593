import { useEffect, useState } from 'react';

import { usePromiseProcessing } from '@just-ai/just-ui';

import { appsAdapterService } from '../../../services/service';

interface ConversationsListQuery {
  match?: {
    _id?: { $ne: string } | { $nin: string[] };
  };
}

export default function useConversationsList({
  abortController,
  enabled,
  query,
}: { abortController?: AbortController; enabled?: boolean; query?: ConversationsListQuery | null } = {}) {
  const [requested, setRequested] = useState<boolean>(false);

  const [getUserChatsStatus, requestGetUserChats] = usePromiseProcessing(
    appsAdapterService.getUserChats.bind(appsAdapterService),
    { throwOnError: true }
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }
    if (!requested) {
      setRequested(true);
      requestConversationsList();
    }
    function requestConversationsList() {
      const conversationsListQuery = {
        ...query,
        sortBy: { updated: -1 as const },
        limit: 1000,
      };

      // @ts-ignore
      requestGetUserChats(conversationsListQuery, abortController);
    }
  }, [enabled, abortController, requestGetUserChats, requested, query]);

  const loaded = requested && !getUserChatsStatus.loading;
  return {
    ...getUserChatsStatus,
    loaded,
    invalidate: () => {
      setRequested(false);
    },
  };
}
