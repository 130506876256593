import React, { useEffect, useState } from 'react';

import { Modal, useTranslation, usePromiseProcessing, Icon, IconButton } from '@just-ai/just-ui';
import cn from 'classnames';

import styles from './style.module.scss';
import useApiService from '../../services/useApiService';
import { ClipboardService } from '../../services/clipboardService';

export type ApiKeyModalProps = {
  type: 'create' | 'delete';
  selectedKeyPrefix?: string;
  closeModal: () => void;
  updateCallback: () => Promise<void>;
  deleteLoading?: boolean;
};

export default function ApiKeyModal(props: ApiKeyModalProps) {
  const { closeModal, updateCallback, type, selectedKeyPrefix } = props;

  const { t } = useTranslation();

  const { createUserApiKey, revokeUserApiKey } = useApiService();

  const [createdKey, setCreatedKey] = useState('');

  const [{ loading }, generateUserToken] = usePromiseProcessing(
    async () => {
      const { data: createdApiKey } = await createUserApiKey();

      setCreatedKey(createdApiKey);
      ClipboardService.copyTextToClipboard(createdApiKey)
      updateCallback();
    },
    { deps: [createUserApiKey] }
  );

  const [{ loading: deleteLoading }, revokeApiKeyPromise] = usePromiseProcessing(async keyPrefix =>
    revokeUserApiKey(keyPrefix)
  );

  const handleKeyRevoke = async () => {
    if (selectedKeyPrefix) await revokeApiKeyPromise(selectedKeyPrefix);
    updateCallback();
    closeModal();
  };

  useEffect(() => {
    if (type === 'create') generateUserToken();
  }, [generateUserToken, type]);

  if (type === 'delete')
    return (
      <Modal
        title={t(`Account:Apikey:Modal:titleDel`)}
        isOpen
        onCancelClick={closeModal}
        inProgress={deleteLoading}
        buttonCancelText={t('cancel')}
        buttonCancelColor='secondary'
        buttonCancelOutline
        buttonSubmitColor='danger'
        buttonSubmitText={t('delete')}
        onActionClick={handleKeyRevoke}
        size='md'
      >
        {t('Account:Apikey:Modal:infoDel')}
      </Modal>
    );

  return (
    <Modal
      title={t(`Account:Apikey:Modal:title`)}
      isOpen
      onCancelClick={closeModal}
      inProgress={loading}
      buttonCancelText={t('close')}
      buttonCancelColor='secondary'
      size='md'
    >
      <>
        <div className={cn(styles.accountPage__modal__copyWrapper)}>
          <b className='text-ellipsis'>{createdKey}</b>
          <IconButton
            name='farCopy'
            flat
            onClick={() => navigator.clipboard.writeText(createdKey)}
            data-test-id='Account.KeyCopy'
          />
        </div>
        <div className={cn(styles.accountPage__modal__info)}>
          <Icon name='faInfoCircle' color='primary' />
          <p>{t('Account:Apikey:Modal:info')}</p>
        </div>
      </>
    </Modal>
  );
}
