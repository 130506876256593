import React from 'react';

import { useTranslation } from '@just-ai/just-ui';

import ChatHeaderDesktopContextControls from './ChatHeaderDesktopContextControls';
import ChatHeaderMobileContextControls from './ChatHeaderMobileContextControls';
import { Conversation } from '../../../../types/chat';
import { handleAgentSettingsMobileToggle } from '../../../Settings/AgentSettingsSidebar';

type Props = {
  selectedConversation: Conversation;
  isMainConversation: boolean;
  isMobileLayout: boolean;
  onClearButtonClick: () => void;
  onTriggerConversationDelete: () => void;
  onTriggerConversationFileSelection?: (() => void) | null;
};

export default function ChatHeaderContextControls({
  selectedConversation,
  isMainConversation,
  isMobileLayout,
  onClearButtonClick: handleClearButtonClick,
  onTriggerConversationDelete: handleTriggerConversationDelete,
  onTriggerConversationFileSelection: handleTriggerConversationFileSelection,
}: Props) {
  const { t } = useTranslation();

  const actionButtonTitle = isMainConversation ? t('ChatHeader:newChat') : t('ChatHeader:clearHistory');

  if (isMobileLayout) {
    return (
      <ChatHeaderMobileContextControls
        selectedConversation={selectedConversation}
        actionButtonTitle={actionButtonTitle}
        onClearHistoryClick={handleClearButtonClick}
        onDeleteClick={handleTriggerConversationDelete}
        onSelectFileClick={handleTriggerConversationFileSelection}
        onSettingsClick={handleAgentSettingsMobileToggle}
      />
    );
  }
  return (
    <ChatHeaderDesktopContextControls
      contextValue={selectedConversation.contextValue}
      messageIsStreaming={selectedConversation.messageIsStreaming}
      onActionButtonClick={handleClearButtonClick}
      actionButtonTitle={actionButtonTitle}
    />
  );
}
