import React, { useEffect, useState } from 'react';

import { ApiKeyResponse } from '@just-ai/api/dist/generated/CopilotGateway';
import {
  Modal,
  useTranslation,
  usePromiseProcessing,
  Icon,
  JustSelect,
  OptionType,
  Button,
  JustSelectProps,
  SwitchButton,
} from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import cn from 'classnames';

import { JGuardKeyStatus } from '.';
import { setJGuardFailureToProtect } from '../../models/conversations/signals';
import { hasFeature } from '../../models/currentUser';
import useApiService from '../../services/useApiService';
import styles from './style.module.scss';

type JGuardKeyModalProps = {
  closeModal: () => void;
  keyStatusProp: JGuardKeyStatus;
  setKeyStatus: (status: JGuardKeyStatus) => void;
};

export default function JGuardKeyModal(props: JGuardKeyModalProps) {
  const { closeModal, keyStatusProp, setKeyStatus } = props;

  const emailSent = keyStatusProp.emailSent;

  const keyStatus = keyStatusProp.keyStatus;

  const { t } = useTranslation();

  const { getCurrentJGuardKey, getJGuardKeys, disableJGuardKey, setJGuardKey } = useApiService();

  const [keys, setKeys] = useState<ApiKeyResponse[]>([]);

  const [selectValue, setSelectValue] = useState<string>();

  const [currentKey, setCurrentKey] = useState<string>();

  const [keyError, setKeyError] = useState<string>();

  const [isEmailSent, setIsEmailSent] = useState(emailSent);

  const [{ loading }, getJGuardData] = usePromiseProcessing(
    async () => {
      const { data: keysList } = await getJGuardKeys();
      const { data: currentKey } = await getCurrentJGuardKey();
      setKeys(keysList.keys || []);
      if (keyStatus === 'on') {
        setCurrentKey(currentKey.apiToken);
        setSelectValue(currentKey.apiToken);
      }
    },
    { deps: [getJGuardKeys, getCurrentJGuardKey, keyStatus] }
  );

  const [{ loading: enablementLoading }, switchKeyEnablement] = usePromiseProcessing(
    async () => {
      if ((keyStatus === 'on' && currentKey === selectValue) || (keyStatus === 'error' && !selectValue)) {
        await disableJGuardKey(currentKey);
        setCurrentKey('');
        setJGuardFailureToProtect(false);
        return setKeyStatus({ keyStatus: 'off', emailSent: false });
      }
      if (!selectValue) {
        return setKeyError(t('jayGuard:modal:noKey'));
      }
      await setJGuardKey(selectValue, isEmailSent);
      setCurrentKey(selectValue);
      setJGuardFailureToProtect(false);
      return setKeyStatus({ keyStatus: 'on', emailSent: isEmailSent });
    },
    {
      deps: [currentKey, selectValue, disableJGuardKey, setJGuardKey, setKeyStatus, keyStatus, isEmailSent],
      onError: error => {
        if (!currentKey) {
          setKeyError(t('jayGuard:modal:error', { productName: t('jayGuard') }));
        }
        AppLogger.error({ message: 'error switching the jguard key', exception: error });
        console.error('error switching the key', error);
        setCurrentKey('');
        setKeyStatus({ keyStatus: 'error', emailSent: isEmailSent });
      },
    }
  );

  const [{ loading: switchLoading }, switchEmailSent] = usePromiseProcessing(
    async value => {
      if (currentKey) {
        await setJGuardKey(currentKey, value);
        setKeyStatus({ keyStatus, emailSent: value });
      }
    },
    {
      deps: [currentKey, setJGuardKey, setKeyStatus, keyStatus],
      onError: error => {
        AppLogger.error({ message: 'error switching the jguard key email sending param', exception: error });
        console.error('error switching the key', error);
        setKeyStatus({ keyStatus: keyStatusProp.keyStatus, emailSent: false });
      },
    }
  );

  useEffect(() => {
    if (keyStatus === 'on') {
      switchEmailSent(isEmailSent);
    }
  }, [emailSent, keyStatus, isEmailSent, switchEmailSent]);

  useEffect(() => {
    getJGuardData();
  }, [getJGuardData]);

  const onSelectChange: JustSelectProps['onChange'] = value => {
    setKeyError('');
    value && setSelectValue(Array.isArray(value.toString()) ? value[0].toString() : value.toString());
  };

  const options = keys.map(key => ({ label: key.name, value: key.apiKey }) as OptionType);

  const btnStateDisable = (!!currentKey && currentKey === selectValue) || (keyStatus === 'error' && !selectValue);

  if (!hasFeature('dataguard_access')) return null;

  return (
    <Modal
      title={t('jayGuard')}
      isOpen={true}
      onClickCloseIcon={closeModal}
      inProgress={loading || enablementLoading || switchLoading}
      className='mobileBottomModal'
      size='md'
    >
      <div className='pl-1'>
        {keyStatus !== 'off' && (
          <div
            className={cn(
              styles.accountPage__modal__jStatus,
              styles[`accountPage__modal__jStatus--${keyStatus === 'on' ? 'success' : 'error'}`]
            )}
          >
            <Icon name='farShieldAlt' color={keyStatus === 'on' ? 'success' : 'danger'} />
            {t(`jayGuard:modal:${keyStatus === 'on' ? 'working' : 'errorPanel'}`, { productName: t('jayGuard') })}
          </div>
        )}
        <label>{t('jayGuard:select:label')}</label>
        <JustSelect
          options={options}
          value={selectValue}
          position='fixed'
          onChange={onSelectChange}
          fullWidth
          inputPlaceholder={t('jayGuard:select:ph')}
          invalid={!!keyError}
          errorText={keyError}
          data-test-id='JGuardModal.keySelect'
        />
        <div className={styles.accountPage__modal__settings}>
          <h3 className='mb-4'>{t('settings')}</h3>
          <SwitchButton
            color='primary'
            id='switch-toggle_email'
            label={t('jayGuard:switch:email:label')}
            name='switch-toggle_email'
            value={isEmailSent}
            data-test-id='JGuardModal.Switch.Email'
            onChange={value => setIsEmailSent(value)}
          />
          <p className='text-sm text-secondary mt-3'>{t('jayGuard:switch:email:hint')}</p>
        </div>
        <Button
          data-test-id='JGuardModal.enableButton'
          color='primary'
          outline={btnStateDisable}
          className='mt-4 mb-4 w-full'
          onClick={switchKeyEnablement}
        >
          {t(btnStateDisable ? 'disable' : 'enable')}
        </Button>
      </div>
    </Modal>
  );
}
