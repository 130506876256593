import React, { useState, useMemo, useCallback } from 'react';

import { ResourceToAccess } from '@just-ai/api/dist/generated/CopilotGateway';
import { useTranslation, Tabs, Icon, Button } from '@just-ai/just-ui';
import cn from 'classnames';
import localize from 'localization';

import CailaIcon from './CailaIcon';
import { corporateLocalization } from './corporate.loc';
import ShieldGradientIcon from './ShieldGradientIcon';
import styles from './style.module.scss';
import { appOptions } from '../../api/cc.api';
import { isTovie } from '../../isTovie';
import { addAlert } from '../../models/alerts';
import { ContentContainer } from '../../pages/FullContainer/ContentContainer';
import { MobileHeader } from '../../pages/Templates/MobileHeader';
import useApiService from '../../services/useApiService';
import { createDocsLink, isMobile } from '../../utils/app/common';
import RequestFeatureModal, { RequestFeatureModalInputs } from '../RequestFeatureModal';

localize.addTranslations(corporateLocalization);

function CorporatePage() {
  const { t, tWithCheck } = useTranslation();

  const tabs = useMemo(
    () => [
      { value: 'plans', name: t('Tab:plans') },
      { value: 'integrations', name: t('Tab:integrations') },
      { value: 'analytics', name: t('Tab:analytics') },
      { value: 'onprem', name: t('Tab:onprem') },
    ],
    [t]
  );

  const [activeTab, setActiveTab] = useState('plans');

  const [requestModalShown, setRequestModalShown] = useState(false);
  const [databaseModalShown, setDatabaseModalShown] = useState(false);

  const { sendUserRequestForFeature } = useApiService();

  const genAiSolutions = ['database', 'caila', 'jguard'];

  const aiIconsArr = {
    database: <Icon name='faBookSpells' color='primary' size='lg' />,
    caila: <CailaIcon />,
    jguard: <ShieldGradientIcon />,
  };

  const docs = appOptions.value?.product?.docs;

  const handleTabsButtonClick = (value?: string) => {
    if (value === 'integrations' && docs && docs.links) {
      window.open(createDocsLink(t('docsUrl'), docs?.links?.main, docs.queryParams), '_blank');
    } else {
      setRequestModalShown(true);
    }
  };

  const handleSolutionBtnClick = (sol: string) => {
    if (sol === 'database') {
      setDatabaseModalShown(true);
      return;
    }
    window.open(sol === 'caila' ? 'https://caila.io/' : 'https://jaycopilot.com/dataguard', '_blank');
  };

  const sendRequestForCorporate = useCallback(
    async (data: RequestFeatureModalInputs) => {
      await sendUserRequestForFeature({
        ...data,
        industry: t(`Modal_RequestFeature:option:${data.industry}`),
        fullName: data.name,
        resourceType: ResourceToAccess.CorporateAccount,
        phone: data.phone.code + data.phone.number,
      });
      addAlert(t('RequestForFeature'), 'success');
      setRequestModalShown(false);
    },
    [sendUserRequestForFeature, t]
  );

  const sendRequestForTemplate = useCallback(
    async (data: RequestFeatureModalInputs) => {
      await sendUserRequestForFeature({
        ...data,
        industry: t(`Modal_RequestFeature:option:${data.industry}`),
        fullName: data.name,
        resourceType: ResourceToAccess.Agent,
        resourceName: 'knowledgeBase',
        phone: data.phone.code + data.phone.number,
      });
      addAlert(t('RequestForFeature'), 'success');
      setDatabaseModalShown(false);
    },
    [sendUserRequestForFeature, t]
  );
  return (
    <ContentContainer withLicense={!isTovie}>
      <MobileHeader />
      <div className={cn(styles.corporatePage, 'p-5 h-full flex flex-column')}>
        <h1 className='mb-3'>{t('corporateHeader')}</h1>
        <p className='mb-5'>{t('corporateHeaderSub')}</p>
        <div className={cn(styles.corporatePage__llmsList, 'p-5 mb-5')}>
          <h3 className='text-primary mb-4'>{t('llmsListHeader')}</h3>
          <p>{t('llmsList')}</p>
        </div>
        <div className={cn(styles.corporatePage__tabs, 'mb-5')}>
          {isMobile() ? (
            <>
              {tabs.map(tab => (
                <div key={tab.value} className={cn(styles.corporatePage__tabContent, 'p-5')}>
                  <h3 className='mb-2'>{t(`Tab:${tab.value}`)}</h3>
                  <p>{t(`TabsContent:${tab.value}`)}</p>
                  {tab.value === 'integrations' && (
                    <Button
                      outline
                      color='primary'
                      className='mt-4'
                      onClick={() => handleTabsButtonClick('integrations')}
                    >
                      {t('docsCorp')}
                      <Icon name='farArrowRight' className='ml-2' />
                    </Button>
                  )}
                </div>
              ))}
              <Button color='primary' className='mt-5 w-full' onClick={() => handleTabsButtonClick()}>
                {t('contactManager')}
                <Icon name='farArrowRight' className='ml-2' />
              </Button>
            </>
          ) : (
            <>
              <Tabs activeTab={activeTab} tabs={tabs} onChange={value => setActiveTab(value)} />
              <div className={cn(styles.corporatePage__tabContent, 'p-5')}>
                <h3 className='mb-2'>{t(`Tab:${activeTab}`)}</h3>
                <p>{t(`TabsContent:${activeTab}`)}</p>
                <Button outline color='primary' className='mt-4' onClick={() => handleTabsButtonClick(activeTab)}>
                  {t(activeTab === 'integrations' ? 'docsCorp' : 'contactManager')}
                  <Icon name='farArrowRight' className='ml-2' />
                </Button>
              </div>
            </>
          )}
        </div>
        <h2 className='mb-4'>{t('genAiSolutions')}</h2>
        <div className={cn('flex gap-16', { 'flex-column': isMobile() })}>
          {genAiSolutions.map(solution => (
            <div key={solution} className={cn(styles.corporatePage__solutionBlock)}>
              <h3 className='flex gap-8'>
                {aiIconsArr[solution]}
                {t(`solutionsBlock:title:${solution}`)}
              </h3>
              <p className='mt-3 mb-4'>{t(`solutionsBlock:info:${solution}`)}</p>
              <Button outline color='primary' size='sm' onClick={() => handleSolutionBtnClick(solution)}>
                {t(`solutionsBlock:btn:${solution}`)}
                <Icon name='farArrowRight' className='ml-2' size='sm' />
              </Button>
            </div>
          ))}
        </div>
      </div>
      {requestModalShown && (
        <RequestFeatureModal
          type='paywall'
          title={t('Modal_RequestFeatureFor_corporate:title')}
          infoText={t('Modal_RequestFeatureFor_corporate:info')}
          sendRequestForTemplate={sendRequestForCorporate}
          isRequestModalOpen={requestModalShown}
          closeModal={() => setRequestModalShown(false)}
        />
      )}
      {databaseModalShown && (
        <RequestFeatureModal
          type='template'
          title={t('Modal_RequestFeatureFor_database:title')}
          infoText={
            <div className='requestTemplateModalInfo'>
              <p>
                <b>{t('Modal_RequestFeatureFor_database:header')}</b>
              </p>
              <p>{t('Modal_RequestFeatureFor_database:body')}</p>
              <p>{tWithCheck('Modal_RequestFeatureFor_database:subBody') || t('Modal_RequestFeatureFor:subBody')}</p>
            </div>
          }
          sendRequestForTemplate={sendRequestForTemplate}
          isRequestModalOpen={databaseModalShown}
          closeModal={() => setDatabaseModalShown(false)}
        />
      )}
    </ContentContainer>
  );
}

export default CorporatePage;
