export const filesLocalization = {
  'Files:Page:title': {
    ru: 'Мои файлы и ссылки',
  },
  'Files:Page:subtitle': {
    ru: 'Здесь хранятся файлы и ссылки, которые вы загружаете в Jay Copilot для работы с приложениями, а также результаты генерации',
  },
  'Files:Page:link-text': {
    ru: 'Подробнее',
  },
  'Files:Page:button-upload': {
    ru: 'Загрузить файлы',
  },
  'Files:Page:button-upload-link': {
    ru: 'Добавить ссылки',
  },
  'Files:Tabs:GeneratedFiles': {
    ru: 'Сгенерированные файлы',
  },
  'Files:Tabs:UploadedFiles': {
    ru: 'Загруженные файлы',
  },
  'Files:Tabs:UsedLinks': {
    ru: 'Ссылки',
  },
  'Files:Table:FileName': {
    ru: 'Название',
  },
  'Files:Table:FileFormat': {
    ru: 'Формат',
  },
  'Files:Table:Agent': {
    ru: 'Приложение',
  },
  'Files:Table:Added': {
    ru: 'Добавлен',
  },
  'Files:Table:Size': {
    ru: 'Размер',
  },
  'Files:Table:Size-unknown': {
    ru: 'Неизвестно',
  },
  'FilesPage:Filters:DateText': {
    ru: 'Добавлен:',
  },
  'FilesPage:Filters:InputPlaceholder': {
    ru: 'Название файла',
  },
  'FilesPage:Filters:LinkInputPlaceholder': {
    ru: 'Название ссылки',
  },
  'FilesPage:Filters:FormatText': {
    ru: 'Формат:',
  },
  'FilesPage:Filters:AppText': {
    ru: 'Приложение:',
  },
  'FilesPage:Filters:FormatDropTitle': {
    ru: 'Формат файла',
  },
  'FilesPage:Filters:AllValues': {
    ru: 'Все',
  },
  'FilesPage:Filters:ValuesSelected': {
    ru: `Выбрано: {selected}`,
  },
  'FilesPage:Filters:AppDropTitle': {
    ru: 'Приложение',
  },
  'FilesPage:FileRow:Dropdown:NewTab': {
    ru: 'Открыть в новой вкладке',
  },
  'FilesPage:FileRow:Dropdown:CopyLink': {
    ru: 'Скопировать ссылку',
  },
  'FilesPage:FileRow:Dropdown:Download': {
    ru: 'Скачать',
  },
  'FilesPage:FileRow:Dropdown:OpenInAssistant': {
    ru: 'Открыть в Ассистенте',
  },
  'FilesPage:FileRow:Dropdown:OpenInDialog': {
    ru: 'Открыть в диалоге',
  },
  'FilesPage:FileRow:Dropdown:OpenInDialog:subtext': {
    ru: 'В котором был сгенерирован файл',
  },
  'FilesPage:FileRow:Dropdown:Delete': {
    ru: 'Удалить',
  },
  'FilesPage:FileRow:Dropdown:DialogWasDeleted': {
    ru: 'В котором был сгенерирован файл',
  },
  'Files:FilterButton:delete': {
    ru: 'Удалить',
  },
  '': {
    ru: '',
  },
};
