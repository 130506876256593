import React, { useCallback } from 'react';

import { useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { useLocation } from 'react-router';

import { ChatHeaderActionsPanel } from './ChatHeaderActionsPanel';
import { ChatHeaderConfirmationPanel } from './ChatHeaderConfirmationPanel';
import { ChatName } from './ChatName';
import styles from './header.module.scss';
import useConversationDeleteAction from './useConversationDeleteAction';
import useConversationRenameAction from './useConversationRenameAction';
import useConversationResetAction from './useConversationResetAction';
import useConversationSelectFileAction from './useConversationSelectFileAction';
import { useAppContext, useSidebarActions } from '../../../contexts/appContext';
import { isTovie } from '../../../isTovie';
import { Conversation } from '../../../types/chat';
import { isMobile } from '../../../utils/app/common';
import { CloseSidebarButton } from '../../Sidebar/components/OpenCloseButton';
import { resetContextSilent } from '../signals/ChatUpdateSignal';
import { templates } from '../../../models/templates';
import { mainConversationSg } from '../../../models/conversations/signals';

type Props = {
  selectedConversation: Conversation;
  fullWidth: boolean;
  isCanvasApp: boolean;
};

export default function ChatHeader({ selectedConversation, fullWidth, isCanvasApp }: Props) {
  const {
    state: { lightMode },
    handleUpdateConversation: submitConversationUpdate,
    handleDeleteConversation: submitConversationDelete,
  } = useAppContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const isMobileLayout = isMobile();
  const [toggleSidebar] = useSidebarActions();

  const { templatesMap } = templates.value;
  const currentTemplate = templatesMap[selectedConversation.app.template];
  const isMainConversation = selectedConversation.id === mainConversationSg?.value?.id;

  const { renamingValueRef, isRenaming, handleConfirmRename, handleTriggerRename, handleCancelRename } =
    useConversationRenameAction({
      selectedConversation,
      submitConversationUpdate,
    });

  const { isResetInProgress, handleConfirmReset } = useConversationResetAction();

  const { isDeleting, handleTriggerDelete, handleConfirmDelete, handleCancelDelete } = useConversationDeleteAction({
    selectedConversation,
    submitConversationDelete,
    pathname,
  });

  const { handleTriggerFileSelection, fileSelectionModalNode } = useConversationSelectFileAction({
    selectedConversation,
    acceptedFiles: currentTemplate.acceptedFiles,
  });

  const handleConfirm = () => {
    if (isRenaming) {
      handleConfirmRename();
    } else if (isDeleting) {
      handleConfirmDelete();
    }
  };

  const handleCancel = () => {
    handleCancelRename();
    handleCancelDelete();
  };

  const handleAgentSettingsReset = useCallback(() => {
    resetContextSilent.value = true;
  }, []);

  const hasActiveConfirmableAction = isRenaming || isDeleting;

  if (!selectedConversation) return null;
  if (isTovie) return null;

  return (
    <div
      className={resolveContainerClassname({ isMobileLayout, lightMode, headerClassName: styles.header })}
      style={resolveContainerStyle({ fullWidth, isCanvasApp })}
    >
      <div className='flex d-sm-none'>
        <CloseSidebarButton onClick={toggleSidebar} side='left' />
      </div>
      <ChatName
        name={
          isMainConversation ? t('assistantJay') : selectedConversation.name || selectedConversation?.config.info.title
        }
        isRenameable={!isMainConversation}
        isRenaming={isRenaming}
        onStartRenaming={handleTriggerRename}
        onConfirmRenaming={handleConfirmRename}
        renamingValueRef={renamingValueRef}
      />
      {hasActiveConfirmableAction ? (
        <ChatHeaderConfirmationPanel
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          hasSelectedConversation={Boolean(selectedConversation)}
        />
      ) : (
        <ChatHeaderActionsPanel
          selectedConversation={selectedConversation}
          currentTemplate={currentTemplate}
          isCanvasApp={isCanvasApp}
          isMainConversation={isMainConversation}
          isClearingInProgress={isResetInProgress}
          onTriggerConversationDelete={handleTriggerDelete}
          onTriggerConversationFileSelection={handleTriggerFileSelection}
          onConfirmConversationReset={handleConfirmReset}
          onConfirmAgentSettingsReset={handleAgentSettingsReset}
        />
      )}
      {fileSelectionModalNode}
    </div>
  );
}

function resolveContainerClassname({
  isMobileLayout,
  lightMode,
  headerClassName,
}: {
  isMobileLayout: boolean;
  lightMode: string;
  headerClassName: string;
}) {
  return cn(`flex align-items-center border-bottom-gray-200 w-100 ${lightMode} overflow-hidden`, headerClassName, {
    'overflow-hidden': !isMobileLayout,
  });
}

function resolveContainerStyle({ fullWidth, isCanvasApp }: { fullWidth: boolean; isCanvasApp: boolean }) {
  if (fullWidth && !isCanvasApp) {
    return { gridColumn: '1 / -1' };
  }
  if (isCanvasApp) {
    return { gridArea: 'rightbar-header' };
  }
  return undefined;
}
