export const isDev = () => window.isDev || process.env.NODE_ENV === 'development';

export const isMobile = () => {
  return window.matchMedia('only screen and (max-width: 576px)').matches;
};

export function getGoogleTag() {
  switch (true) {
    case window.location.host.endsWith('test-ai.net'):
      return 'GTM-PDLRXQ7Z';
    case window.location.host === 'app.jaycopilot.com':
      return 'GTM-KHB42L5';
    case window.location.host === 'tai.tovie.ai':
      return 'GTM-5KSFR96';
    case window.location.host === 'smarty.just-ai.com':
      return 'GTM-WPX9XX4H';
    case window.location.host === 'x5-copilot.just-ai.com':
      return 'G-L3XZM8HTXT';
    default:
      return '';
  }
}

export function GA(
  eventCategory,
  eventAction?: string,
  eventLabel?: string,
  eventValue?: string,
  options: Record<any, any> = {}
) {
  if (process.env.NODE_ENV !== 'development') {
    window.dataLayer?.push({
      event: 'GAEvent',
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel || undefined,
      eventValue: eventValue,
      ...options,
    });
  }

  if (isDev()) {
    console.log({
      event: 'GAEvent',
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue,
      ...options,
    });
  }
}

export function processUtmTags(searchString: string) {
  const searchParams = new URLSearchParams(searchString);
  const utmTags: Record<string, string> = {};
  searchParams.forEach((value, key) => {
    if (key.includes('utm_')) {
      utmTags[key] = value;
    }
  });
  return utmTags;
}

export function setAppFavicon(icon?: string) {
  if (!icon) return;
  let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = icon;
}

export default function insetJsxInRawText(text: string, list: { start: number; end: number; jsx: JSX.Element }[]) {
  let offset = 0;
  let processedText = text;
  return list
    .reduce((acc, element) => {
      const prevText = processedText.substring(0, element.start - offset);
      if (prevText.trim()) {
        acc.push(prevText);
      }
      acc.push(element.jsx);
      processedText = processedText.substring(element.end - offset);
      offset = element.end;
      return acc;
    }, [] as any[])
    .concat([processedText]);
}

export function createDocsLink(baseUrl: string, addUrl?: string, queryParams?: { [key: string]: string }) {
  if (!addUrl && (!queryParams || !Object.keys(queryParams).length)) return baseUrl;
  if (!addUrl) return `${baseUrl}/?${new URLSearchParams(queryParams).toString()}`;
  if (!queryParams || !Object.keys(queryParams).length) return `${baseUrl}/${addUrl}`;
  return `${baseUrl}/${addUrl}/?${new URLSearchParams(queryParams).toString()}`;
}
