import React, { useCallback, useEffect, useState } from 'react';

import { UserSearchRecord } from '@just-ai/api/dist/generated/Accountsadmin';
import {
  AccountLimits,
  AccountUserLimit,
  UserData,
  RefreshPeriodType,
} from '@just-ai/api/dist/generated/CopilotGateway';
import {
  Button,
  Icon,
  useTranslation,
  SearchInput,
  usePromiseProcessing,
  useDebounce,
  Spinner,
} from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import cn from 'classnames';

import styles from './styles.module.scss';
import { TOKENS_BACKEND_MULT } from '../../../api/constants';
import { addAlert } from '../../../models/alerts';
import useApiService from '../../../services/useApiService';
import Pagination from '../../Pagination';
import { usePagination } from '../../Pagination/hook';
import LimitCrudModal, { LimitModalProps } from '../components/LimitCrudModal';
import LimitsTable, { Limit } from '../components/Tables/LimitsTable';
import { appOptions } from '../../../api/cc.api';
import { createDocsLink } from '../../../utils/app/common';

type AccountLimitsProcessed = Omit<AccountLimits, 'limits'> & { limits: Limit[] };

export type UserSearchRecordShort = Pick<UserSearchRecord, 'email' | 'fullName' | 'id'>;

export type CreateLimitData = {
  amount: number;
  period: number;
  periodType: RefreshPeriodType;
  emails?: Array<UserSearchRecordShort | UserData>;
  limitId?: number;
};

export default function LimitsTab() {
  const { t } = useTranslation();

  const [selectedLimits, setSelectedLimits] = useState<Limit[]>([]);

  const [accountLimits, setAccountLimits] = useState<AccountLimitsProcessed>();

  const [userSearch, setUserSearch] = useState('');

  const [searchLocal, setSearchLocal] = useState('');

  const [crudModalOpen, setCrudModalOpen] = useState<LimitModalProps['type']>();

  const [modalData, setModalData] = useState<Limit[]>([]);

  useDebounce(searchLocal, 500, setUserSearch);

  const {
    getAccountsLimitsList,
    getGeneralAccountUsersLimit,
    createUserLimit,
    deleteUserLimit,
    deleteUserLimitsBulk,
    updateUserLimits,
  } = useApiService();

  const { paginationInfo, changePage } = usePagination({
    pageNum: accountLimits?.page?.number ?? 0,
    pageSize: accountLimits?.page?.size ?? 10,
    totalCount: accountLimits?.page?.totalElements ?? 0,
    totalPages: accountLimits?.page?.totalPages ?? 0,
  });

  const defaultGeneralLimit: Limit = {
    id: -1,
    general: true,
  };

  const getLimitsList = async (userSearch?: string, page?: number) => {
    const userLimitsResponse = await getAccountsLimitsList(page || 0, userSearch || '');
    const { data: generalLimitResponse } = await getGeneralAccountUsersLimit();

    const generalLimitData = generalLimitResponse?.limit;

    const processedGeneralLimit: Limit = generalLimitData
      ? {
          general: true,
          amount: generalLimitData.tokenLimit / TOKENS_BACKEND_MULT,
          nextRefresh: generalLimitData.nextRefresh,
          period: String(generalLimitData.refreshPeriod.value),
          periodType: generalLimitData.refreshPeriod.type,
          userData: generalLimitData.user,
          userEmail: generalLimitData.user?.email,
          id: generalLimitData.limitId || defaultGeneralLimit.id,
        }
      : defaultGeneralLimit;

    const processedLimitsData: AccountLimitsProcessed = {
      page: userLimitsResponse.data.page,
      limits: userLimitsResponse.data.limits.map(rawLimit => ({
        userData: rawLimit.user,
        amount: rawLimit.tokenLimit / TOKENS_BACKEND_MULT,
        period: String(rawLimit.refreshPeriod.value),
        periodType: rawLimit.refreshPeriod.type,
        nextRefresh: rawLimit.nextRefresh,
        userEmail: rawLimit.user?.email,
        id: rawLimit.limitId || 0,
      })),
    };
    setAccountLimits({
      ...processedLimitsData,
      limits: [processedGeneralLimit, ...processedLimitsData.limits],
    });
  };

  const [{ loading }, fetchLimitsList] = usePromiseProcessing(
    async () => getLimitsList(userSearch, paginationInfo.pageNum),
    {
      deps: [getAccountsLimitsList, paginationInfo.pageNum, userSearch],
      onError: error => {
        setAccountLimits({ limits: [defaultGeneralLimit] });
        AppLogger.error({ message: 'failed to fetch account limits', exception: error });
      },
    }
  );

  const handleDeleteLimits = async (limits: Limit[]) => {
    if (limits.length === 1) return deleteUserLimit(limits[0].id);
    if (limits?.length) {
      return deleteUserLimitsBulk(limits.map(limit => limit.id));
    }
  };

  const handleCreateLimits = async (data: CreateLimitData, general?: boolean) => {
    if (!data.emails || !data.emails.length) return;
    const reqData: AccountUserLimit[] = data.emails.map(email => ({
      refreshPeriod: { value: data.period, type: data.periodType },
      tokenLimit: data.amount * TOKENS_BACKEND_MULT,
      user: general
        ? undefined
        : {
            ccUserId: (email as UserSearchRecordShort).id || (email as UserData).ccUserId,
            email: email.email,
            fullName: email.fullName,
          },
    }));
    return createUserLimit(reqData);
  };

  const handleUpdateLimit = async (data: CreateLimitData) => {
    if (!data.emails || data.emails.length > 1) return Promise.resolve();
    const reqData: AccountUserLimit = {
      refreshPeriod: { value: data.period, type: data.periodType },
      tokenLimit: data.amount * TOKENS_BACKEND_MULT,
      user: data.emails[0],
      limitId: data.limitId,
    };
    return updateUserLimits(reqData);
  };

  const [{ loading: modalLoading }, processModalRequest] = usePromiseProcessing(
    async (data?: CreateLimitData) => {
      if (crudModalOpen === 'delete') {
        await handleDeleteLimits(modalData);
        setSelectedLimits([]);
      }
      if (crudModalOpen === 'create' && data) {
        await handleCreateLimits(data);
      }
      if (crudModalOpen === 'general') {
        if (data) {
          const generalLimitData: CreateLimitData = {
            amount: data.amount,
            period: data.period,
            periodType: data.periodType,
            emails: [{ id: -1 }],
          };
          await handleCreateLimits(generalLimitData, true);
        }
      }
      if (crudModalOpen === 'update' && data) {
        if (data.emails?.length === 1) {
          await handleUpdateLimit(data);
        } else {
          await handleCreateLimits(data);
        }
      }
      await getLimitsList();
      setModalData([]);
      setCrudModalOpen(undefined);
      return Promise.resolve();
    },
    {
      deps: [crudModalOpen, selectedLimits, fetchLimitsList],
      onError: error => {
        addAlert(error.message);
      },
    }
  );

  useEffect(() => {
    fetchLimitsList();
  }, [fetchLimitsList, userSearch]);

  const crudModalSubmitHandler = async (data?: CreateLimitData) => {
    await processModalRequest(data);
  };

  const openModalHandler = useCallback(
    (type: LimitModalProps['type'], data?: Limit[]) => {
      setModalData(data || selectedLimits);
      setCrudModalOpen(type);
    },
    [selectedLimits]
  );

  const docs = appOptions.value?.product?.docs;

  return (
    <div className={cn('flex flex-column', styles.Limits__wrapper)}>
      <p className='mb-3'>{t('Account:limits:info')}</p>
      {docs?.links && Object.keys(docs?.links).includes('limits') && (
        <a
          className='flex gap-4 mb-5'
          href={createDocsLink(t('docsUrl'), docs?.links.limits, docs?.queryParams)}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon name='farQuestionCircle' color='primary' />
          <b>{t('LimitsMore')}</b>
        </a>
      )}

      <div className='flex gap-8 items-center'>
        <Button color='primary' className={styles.Limits__addBtn} onClick={() => setCrudModalOpen('create')}>
          {t('Account:limits:addLimit')}
        </Button>
        <SearchInput
          alwaysOpen
          className={styles.Limits__searchInput}
          value={searchLocal}
          onChange={value => setSearchLocal(value)}
          placeholder={t('Account:limits:userEmail')}
          data-test-id='Limits:userSearch'
        />

        {selectedLimits.length > 0 && (
          <div className='flex'>
            <Button
              outline
              onClick={() => {
                setModalData(selectedLimits);
                setCrudModalOpen('update');
              }}
            >{`${t('edit')} (${selectedLimits.length})`}</Button>
            <Button
              color='danger'
              onClick={() => {
                setModalData(selectedLimits);
                setCrudModalOpen('delete');
              }}
            >{`${t('delete')} (${selectedLimits.length})`}</Button>
          </div>
        )}
      </div>
      {loading ? (
        <div className='h-full flex'>
          <Spinner />
        </div>
      ) : (
        <>
          <LimitsTable
            limits={accountLimits?.limits}
            selectedLimits={selectedLimits}
            setSelectedLimits={setSelectedLimits}
            openModalHandler={openModalHandler}
          />
          {paginationInfo.totalPages > 1 && (
            <Pagination
              page={paginationInfo.pageNum}
              size={paginationInfo.pageSize}
              changePage={changePage}
              totalCount={paginationInfo.totalCount}
            />
          )}
        </>
      )}
      {crudModalOpen && (
        <LimitCrudModal
          type={crudModalOpen}
          limitData={modalData}
          closeModal={() => setCrudModalOpen(undefined)}
          submitHandler={crudModalSubmitHandler}
          loading={modalLoading}
        />
      )}
    </div>
  );
}
