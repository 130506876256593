export const errorsLocalization = {
  defaultError: {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  '403Error': {
    eng: 'You don’t have access to some features of the service. Please contact the administrator.',
    ru: 'У вас нет доступа к некоторым возможностям сервиса. Пожалуйста, обратитесь к администратору.',
  },
  fileUploadErr: {
    eng: 'Failed to upload file',
    ru: 'Не удалось загрузить файл',
  },
  agentUpdateErr: {
    eng: 'Failed to update applet',
    ru: 'Не удалось обновить приложение',
  },
  fileSizeError: {
    eng: 'The file is too big',
    ru: 'Файл слишком большой',
  },
  fileSizeError__param: {
    eng: 'The file is too big. Please attach a file up to {size} MB in size.',
    ru: 'Файл слишком большой. Прикрепите файл размером до {size} Мб.',
  },
  timeoutError: {
    eng: 'The time limit to process your request has been reached. Please try again or contact technical support.',
    ru: 'Превышено максимальное время выполнения запроса. Пожалуйста, попробуйте отправить ваш запрос снова или обратитесь в техническую поддержку.',
  },
  createTemplateError: {
    eng: '',
    ru: 'Произошла ошибка при создании приложения',
  },
  updateTemplateError: {
    eng: '',
    ru: 'Произошла ошибка при обновлении приложения',
  },
  enableTemplateError: {
    eng: '',
    ru: 'Произошла ошибка при включении приложения',
  },
  'khub.proxy.unauthorized': {
    eng: '',
    ru: 'API-ключ не найден или истек',
  },
  'khub.proxy.project_not_exist': {
    eng: '',
    ru: 'Проект в Jay Knowledge Hub не существует',
  },
  khubTokenValError: {
    eng: '',
    ru: 'Неверный формат токена',
  },
  'agentapi.common.not_authorized': {
    eng: 'You are not logged in to #{config.productName}. Sign in to continue.',
    ru: 'Вы не авторизованы в #{config.productName}. Войдите, чтобы продолжить.',
  },
  'agentapi.conversation.conversation_not_found': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.conversation.conversation_not_ready_yet': {
    eng: 'The application is not ready yet. Please wait.',
    ru: 'Приложение еще не готово. Пожалуйста, подождите.',
  },
  'agentapi.conversation.lock_acquisition_exception': {
    eng: 'The application is not ready yet. Please wait.',
    ru: 'Приложение еще не готово. Пожалуйста, подождите.',
  },
  'agentapi.agents.data_analyst_disabled': {
    eng: 'The “Data analyst” application is not available. Please try again or contact our support team.',
    ru: 'Приложение «Аналитик данных» недоступно. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.agents.unsupported_image_format': {
    eng: 'Only PNG, JPEG, and WEBP image formats are supported.',
    ru: 'Приложение работает только с изображениями в форматах: PNG, JPEG и WEBP.',
  },
  'agentapi.app.builder_not_found': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.conversation.request_is_not_acceptable': {
    eng: 'I cannot work with images. Please send me a text request.',
    ru: 'Я не умею работать с изображениями. Пожалуйста, напишите мне ваш запрос текстом.',
  },
  'agentapi.conversation.request_is_not_acceptable_seoText': {
    eng: 'I cannot work with images. Please tell me what text I should compose or what I should change in the current version.',
    ru: 'Я не умею работать с изображениями. Пожалуйста, напишите, какой текст нужно составить или что изменить в текущем варианте.',
  },
  'agentapi.conversation.request_is_not_acceptable_painter': {
    eng: 'I cannot work with files. Please tell me what I should draw or correct in the current version',
    ru: 'Я не умею работать с файлами. Пожалуйста, напишите, что нужно нарисовать или поправить в текущем варианте',
  },
  'agentapi.conversation.request_is_not_acceptable_summarizer': {
    eng: 'I cannot work with such files. Please send another file: DOC, DOCX, HTM, HTML, PDF, PPTX, XLS, or XLSX. Please note that an XLS or XLSX file should not be larger than 20 MB, and a file of other formats should not be larger than 40 MB.',
    ru: 'Я не умею работать с такими файлами. Пришлите, пожалуйста, другой файл: DOC, DOCX, HTM, HTML, PDF, PPTX, XLS или XLSX. Учитывайте, что XLS- или XLSX-файл не должен быть больше 20 Мб, файл других форматов — не больше 40 Мб.',
  },
  'agentapi.conversation.request_is_not_acceptable_gigaChat': {
    eng: 'I cannot work with such files. Please send another file: DOC, DOCX, HTM, HTML, PDF, PPTX, XLS, or XLSX. Please note that an XLS or XLSX file should not be larger than 20 MB, and a file of other formats should not be larger than 40 MB.',
    ru: 'Я не умею работать с такими файлами. Пришлите, пожалуйста, другой файл: DOC, DOCX, HTM, HTML, PDF, PPTX, XLS или XLSX. Учитывайте, что XLS- или XLSX-файл не должен быть больше 20 Мб, файл других форматов — не больше 40 Мб.',
  },
  'agentapi.conversation.request_is_not_acceptable_business-email': {
    eng: 'I cannot work with images. Please tell me what I should change in my version of the email.',
    ru: 'Я не умею работать с изображениями. Пожалуйста, напишите, что нужно изменить в моем варианте письма.',
  },
  'agentapi.conversation.request_is_not_acceptable_copywriter': {
    eng: 'I cannot work with such files. Please tell me if I should correct anything in the last paragraph or if I can continue further.',
    ru: 'Я не умею работать с такими файлами. Пожалуйста, напишите, нужно ли что-то поправить в последнем абзаце или я могу продолжить дальше.',
  },
  'agentapi.conversation.request_is_not_acceptable_advertisementPost': {
    eng: 'I cannot work with images. Please tell me if I should change anything in my text or write a new one.',
    ru: 'Я не умею работать с изображениями. Пожалуйста, скажите, нужно ли что-то изменить в моем тексте или написать новый.',
  },
  'agentapi.conversation.request_is_not_acceptable_programming': {
    eng: 'I cannot work with images. Please tell me if I should add or correct anything.',
    ru: 'Я не умею работать с изображениями. Пожалуйста, напишите, нужно ли что-то дополнить или исправить.',
  },
  'agentapi.conversation.request_is_not_acceptable_marketingEmail': {
    eng: 'I cannot work with images. Please tell me if I should change anything in my version or write a new email.',
    ru: 'Я не умею работать с изображениями. Пожалуйста, скажите, нужно ли что-то изменить в моем варианте или нужно написать новое письмо.',
  },
  'agentapi.conversation.request_is_not_acceptable_directLLM': {
    eng: 'To work with images, select the GPT-4 Vision or GPT-4o model in the application settings and click “Update”. The current model only works with text requests.',
    ru: 'Чтобы работать с изображениями, выберите модель GPT-4 Vision или GPT-4o в настройках приложения и нажмите «Обновить». Текущая модель работает только с текстовыми запросами.',
  },
  'agentapi.conversation.request_is_not_acceptable_docCreate': {
    eng: 'I cannot work with such files. Please send me a DOCX file. It should contain not more than 7,000 characters, and it should not be larger than 20 MB.',
    ru: 'Я не умею работать с такими файлами. Пожалуйста, пришлите мне файл в формате DOCX. Его размер должен быть меньше 20 Мб, а количество символов в самом документе — до 7 000.',
  },
  'agentapi.conversation.request_is_not_acceptable_openAiFileSearch': {
    eng: 'I cannot work with such files. Please update the application settings by uploading files in the acceptable formats. They are listed below the upload window.',
    ru: 'Я не умею работать с такими файлами. Пожалуйста, обновите настройки приложения, загрузив в него файлы допустимых форматов. Они перечислены под окном загрузки.',
  },
  'agentapi.conversation.conversation_failed_to_initialize': {
    eng: 'Failed to continue the dialog due to a previous error. Please try again or contact our support team.',
    ru: 'Невозможно продолжить диалог из-за ошибки ранее. Пожалуйста, попробуйте заново или обратитесь в поддержку.',
  },
  'agentapi.template.template_not_found_or_user_not_owner': {
    eng: 'The application is not available. Please try again or contact our support team.',
    ru: 'Приложение недоступно. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.aimyvoice.no_available_voices_for_language': {
    eng: 'It is not possible to synthesize a text in the selected voice in {lang}. Please select a different language or voice in the settings and update the application.',
    ru: 'Не получается озвучить текст выбранным голосом на языке {lang}. Пожалуйста, выберите другой язык или голос в настройках и обновите приложение.',
  },
  'agentapi.aimyvoice.voice_was_not_found': {
    eng: 'The voice [{voice}] is not available. Please select a different voice in the settings and update the application.',
    ru: 'Голос [{voice}] недоступен. Пожалуйста, выберите другой голос в настройках и обновите приложение.',
  },
  'agentapi.aimyvoice.bad_text_length': {
    eng: 'The text cannot be synthesized: it must contain from 4 to 500 characters.',
    ru: 'Не удается озвучить текст: он должен содержать от 4 до 500 символов.',
  },
  'agentapi.assembly.transcribing_failed': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.chatgpt.chatgpt_failed_to_respond': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.chatgpt.bad_response': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.openai.openai_error_response': {
    eng: 'An error occurred while contacting ChatGPT. Please try again or submit a request to our support team.',
    ru: 'Произошла ошибка при обращении в ChatGPT. Пожалуйста, попробуйте еще раз или напишите в поддержку.',
  },
  'agentapi.yandexgpt.completion_error': {
    eng: 'An error occurred while contacting YandexGPT. Please try again or submit a request to our support team.',
    ru: 'Произошла ошибка при обращении к YandexGPT. Пожалуйста, попробуйте еще раз или напишите в поддержку.',
  },
  'agentapi.gigachat.gigachat_completion_error': {
    eng: 'An error occurred while contacting GigaChat. Please try again or submit a request to our support team.',
    ru: 'Произошла ошибка при обращении к GigaChat. Пожалуйста, попробуйте еще раз или напишите в поддержку.',
  },
  'agentapi.mlp.completion_error': {
    eng: 'An error occurred while contacting {modelName}. Please try again or submit a request to our support team.',
    ru: 'Произошла ошибка при обращении к модели {modelName}. Пожалуйста, попробуйте еще раз или напишите в поддержку.',
  },
  'agentapi.elevenlabs.voice_was_not_found': {
    eng: 'The voice {voice} is not available. Please select a different voice in the settings and update the application.',
    ru: 'Голос {voice} недоступен. Пожалуйста, выберите другой голос в настройках и обновите приложение.',
  },
  'agentapi.file.file_upload_error': {
    eng: 'Error loading file. Please try again or upload another file.',
    ru: 'Ошибка загрузка файла. Пожалуйста, попробуйте еще раз или загрузите другой файл.',
  },
  'agentapi.parser.file_is_too_long': {
    eng: 'I cannot work with such large files. Please send another file: an XLS or XLSX file should not be larger than 20 MB, files of other formats should not be larger than 40 MB. The amount of text should not exceed 200 000 characters.',
    ru: 'Я не умею работать с такими большими файлами. Пришлите, пожалуйста, другой: XLS- или XLSX-файл не должен быть больше 20 Мб, файл других форматов — не больше 40 Мб. Объем текста не должен превышать 200 000 символов.',
  },
  'agentapi.parser.language_not_found': {
    eng: 'Failed to determine the recording language. Please send another file or a link to it.',
    ru: 'Не получилось определить язык записи. Пожалуйста, отправьте другой файл или ссылку на него.',
  },
  'agentapi.parser.generic_error': {
    eng: 'Unable to recognize the content of the file or webpage. Please check the link or file format and try again.',
    ru: 'Не удалось распознать содержимое файла или веб-страницы. Пожалуйста, проверьте правильность ссылки или формата файла и попробуйте снова.',
  },
  'agentapi.common.common_error': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'mlp.open.ai.bad.request': {
    eng: 'Unfortunately, I can’t process this request. The image might be inappropriate or forbidden. Let’s try to rephrase the request or draw something else?',
    ru: 'К сожалению, я не могу обработать такой запрос. Изображение может быть нежелательным или запрещенным. Давайте попробуем сформулировать запрос иначе или нарисовать что-то другое?',
  },
  'agentapi.retrievalqa.cannot_parse_url': {
    eng: 'An error occurred while processing the file or link. Please create a new application and try sending another file or a link to it.',
    ru: 'Произошла ошибка при обработке файла или ссылки. Пожалуйста, создайте новое приложение и попробуйте отправить другой файл или ссылку на него.',
  },
  'agentapi.retrievalqa.no_text_found_in_url': {
    eng: 'The document does not contain text. Please create a new application and upload another file.',
    ru: 'Документ не содержит текст. Пожалуйста, создайте новое приложение и загрузите другой файл.',
  },
  'agentapi.retrievalqa.no_text_found_in_document': {
    eng: 'The document does not contain text. Please create a new application and upload another file.',
    ru: 'Файл не содержит текст. Пожалуйста, создайте новое приложение и загрузите другой файл.',
  },
  'agentapi.retrievalqa.file_is_too_long': {
    eng: 'I cannot work with such large files. Please create a new application and send another file: an XLS or XLSX file should not be larger than 20 MB, files of other formats should not be larger than 40 MB. The amount of text should not exceed 200 000 characters.',
    ru: 'Я не умею работать с такими большими файлами. Пожалуйста, создайте новое приложение и загрузите другой: XLS- или XLSX-файл не должен быть больше 20 Мб, файл других форматов — не больше 40 Мб. Объем текста не должен превышать 200 000 символов.',
  },
  'agentapi.data_analyst.unsupported_file_format': {
    eng: 'Your dataset file should be in CSV or XLSX format. Please specify a different file or link in the settings and click “Update”.',
    ru: 'Файл с набором данных должен быть в формате CSV или XLSX. Пожалуйста, укажите в настройках другой файл или ссылку и нажмите «Обновить».',
  },
  'agentapi.summarizer.no_text_found_in_url': {
    eng: 'The file or link doesn’t contain any text. Please specify a different file or link in the settings and click “Update”.',
    ru: 'Файл или ссылка не содержит текст. Пожалуйста, укажите в настройках другой файл или ссылку и нажмите «Обновить».',
  },
  'agentapi.summarizer.no_text_found_in_document': {
    eng: 'The file doesn’t contain any text. Please specify a different file or link in the settings and click “Update”.',
    ru: 'Файл не содержит текст. Пожалуйста, укажите в настройках другой файл и нажмите «Обновить».',
  },
  'agentapi.apify.cannot_parse_content': {
    eng: 'The link does not lead to text. Please send another link.',
    ru: 'По ссылке нет текста. Пожалуйста, пришлите другую ссылку.',
  },
  'agentapi.file_converter.failed_to_convert_file_to_markdown': {
    eng: 'An error occurred in the “Document creator” application. Please try again or contact our support team.',
    ru: 'В работе приложения «Создание документа по образцу» произошла ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.file_converter.failed_to_convert_markdown_to_file': {
    eng: 'An error occurred in the “Document creator” application. Please try again or contact our support team.',
    ru: 'В работе приложения «Создание документа по образцу» произошла ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.summarize_multi.number_of_urls_exceeded_limit': {
    eng: 'Too many links. The maximum is {maxUrls}. Please delete extra links in the settings and update the application.',
    ru: 'Слишком много ссылок. Максимум — {maxUrls}. Пожалуйста, удалите часть ссылок в настройках и обновите приложение.',
  },
  'agentapi.files.invalid_name': {
    eng: 'Invalid file name. Please rename the file and try again.',
    ru: 'Недопустимое название файла. Пожалуйста, переименуйте файл и повторите попытку.',
  },
  'dataguard.common.invalid_access_token': {
    eng: 'Your request includes data that can’t be openly sent to AI. This data needs to be secured using #{config.dataGuardName}. However, the access key that is used for integrating #{config.dataGuardName} and #{config.productName} is invalid. Please update the access key or contact your administrator. Once the key is updated, please try resending your request.',
    ru: 'Ваш запрос содержит данные, которые нельзя передавать в открытом виде нейросети. Данные должны быть скрыты при помощи #{config.dataGuardName}. Но для интеграции #{config.dataGuardName} и #{config.productName} используется недействительный ключ доступа. Обновите ключ доступа или обратитесь к администратору. После обновления ключа отправьте запрос заново.',
  },
  'dataguard.common.expired_access_token': {
    eng: 'Your request includes data that can’t be openly sent to AI. This data needs to be secured using #{config.dataGuardName}. However, the access key that is used for integrating #{config.dataGuardName} and #{config.productName} has expired. Please update the access key or contact your administrator. Once the key is updated, please try resending your request.',
    ru: 'Ваш запрос содержит данные, которые нельзя передавать в открытом виде нейросети. Данные должны быть скрыты при помощи #{config.dataGuardName}. Но для интеграции #{config.dataGuardName} и #{config.productName} используется истекший ключ доступа. Обновите ключ доступа или обратитесь к администратору. После обновления ключа отправьте запрос заново.',
  },
  'dataguard.common.common_error': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'gateway.balance.not_enough_tokens': {
    eng: 'Not enough tokens',
    ru: 'Не хватает токенов',
  },
  'gateway.balance.token_limit_exceeded': {
    eng: 'You’ve reached the limit of tokens you can spend. To continue working, contact the administrator or try again later.',
    ru: 'Вы достигли лимита токенов, которые можете потратить. Чтобы продолжить работу, свяжитесь с администратором или попробуйте позже.',
  },
  'gateway.common.too_many_requests': {
    eng: 'Too many requests. Please try sending your message later.',
    ru: 'Слишком много запросов. Попробуйте отправить сообщение позже.',
  },
  'gateway.report.too_many_rows': {
    eng: 'The report contains more than 100,000 rows. We cannot generate such a report. Please select a shorter period and try again.',
    ru: 'Отчет содержит больше 100 000 строк. Мы не можем сформировать такой отчет. Пожалуйста, выберите период короче и повторите попытку.',
  },
  'agentapi.assistant.inappropriate_content': {
    eng: 'I don’t create images on such a topic, but I’d be happy to draw something else for you.',
    ru: 'Я не создаю изображения на такую тематику, но с радостью нарисую вам что-то другое.',
  },
  'gigachat.assistant.500_server_error': {
    eng: 'There is an error on GigaChat’s end. Please try again later or submit a request to our support team.',
    ru: 'На стороне GigaChat произошла ошибка. Пожалуйста, попробуйте позже или обратитесь в поддержку #{config.productName}.',
  },
  'agentapi.common.no_text_found': {
    eng: 'Your document doesn’t contain any text. Please, upload another file',
    ru: 'Ваш документ не содержит текст. Пожалуйста, загрузите другой файл.',
  },
  // Deprecated error codes
  'agentapi.bing.unauthorized_request': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.justgpt.justgpt_not_enabled': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.justgpt.justgpt_error_response': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.mistral.completion_error': {
    eng: 'An error occurred while contacting Saiga Mistral. Please try again or submit a request to our support team.',
    ru: 'Произошла ошибка при обращении к модели Сайга-Мистраль. Пожалуйста, попробуйте еще раз или напишите в поддержку.',
  },
  'agentapi.api.cannot_fetch_api_schema': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.api.api_does_not_contain_ids_with_summary_or_description': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  'agentapi.agents.content_filter_error': {
    eng: 'The response was filtered due to the prompt triggering OpenAI’s content management policy. Please modify your prompt and retry.',
    ru: 'Ответ был отфильтрован, так как ваш запрос нарушает политику управления контентом OpenAI. Пожалуйста, измените свой запрос и попробуйте снова.',
  },
  'mlp.open.ai.proxy.unknown_internal_exception': {
    eng: 'An internal error has occurred. Please try again or contact our support team.',
    ru: 'Произошла внутренняя ошибка. Пожалуйста, попробуйте еще раз или обратитесь в поддержку.',
  },
  assistantApiRunRequestTimeout: {
    eng: 'An error occurred on the AI model provider’s side. Please try again.',
    ru: 'На стороне провайдера нейросети произошла ошибка. Пожалуйста, попробуйте еще раз.',
  },
  assistantApiMessageUnsupported: {
    eng: 'An error occurred on the AI model provider’s side. Please try again.',
    ru: 'На стороне провайдера нейросети произошла ошибка. Пожалуйста, попробуйте еще раз.',
  },
  assistantApiOpenAiError: {
    eng: 'An error occurred on the AI model provider’s side. Please try again.',
    ru: 'На стороне провайдера нейросети произошла ошибка. Пожалуйста, попробуйте еще раз.',
  },
  assistantFilesRequired: {
    eng: 'An error occurred on the AI model provider’s side. Please try again.',
    ru: 'На стороне провайдера нейросети произошла ошибка. Пожалуйста, попробуйте еще раз.',
  },
  'assistantApi.uploadFileToOpenAi.error': {
    eng: 'An error occurred on the AI model provider’s side. Please try again.',
    ru: 'На стороне провайдера нейросети произошла ошибка. Пожалуйста, попробуйте еще раз.',
  },
  'validation:Error:Header': {
    eng: 'Application blocked',
    ru: 'Приложение заблокировано',
  },
  'validation:Error:Message:Header': {
    eng: 'Message blocked',
    ru: 'Сообщение заблокировано',
  },
  'validation:Error:Summary': {
    eng: 'Your company blocks sending this data to AI models:',
    ru: 'Ваша компания блокирует отправку этих данных нейросети:',
  },
  'validation:Error:Tooltip': {
    eng: 'Edit the field values and try again. If you believe this is a mistake, please contact your administrator.',
    ru: 'Отредактируйте значения поля и повторите попытку. Если вы считаете, что произошла ошибка, свяжитесь со своим администратором.',
  },
  'validation:Error:Message:Tooltip': {
    eng: 'Edit the message and try again. If you believe this is a mistake, please contact your administrator.',
    ru: 'Отредактируйте сообщение и повторите попытку. Если вы считаете, что произошла ошибка, свяжитесь со своим администратором.',
  },
  'validation:Redact:Summary': {
    eng: 'Your company masks this data before sending it to AI models:',
    ru: 'Ваша компания маскирует эти данные перед отправкой нейросети:',
  },
  'validation:Redact:Tooltip': {
    eng: 'This may affect the application response.',
    ru: 'Это может повлиять на ответ приложения.',
  },
  emptyVoiceMessageError: {
    eng: 'Unable to recognize the voice message text. Please try again',
    ru: 'Не удается распознать текст голосового сообщения. Пожалуйста, попробуйте еще раз',
  },
  'gateway.promo_code.not_found': {
    eng: 'This promo code does not exist',
    ru: 'Такого промокода не существует',
  },
  'gateway.promo_code.already_applied': {
    eng: 'This promo code has already been activated',
    ru: 'Этот промокод уже был активирован',
  },
  'gateway.promo_code.too_many_requests': {
    eng: 'Too many requests. Please try again later',
    ru: 'Слишком много запросов. Пожалуйста, попробуйте позже',
  },
  'gateway.promo_code.already_applied_for_account': {
    eng: 'This promo code has already been activated for your account',
    ru: 'Этот промокод уже был активирован для вашего аккаунта',
  },
  'gateway.promo_code.cant_be_used_for_organization': {
    eng: 'This promo code cannot be used for an organization',
    ru: 'Этот промокод нельзя использовать в рамках организации',
  },
  'gateway.promo_code.expired': {
    eng: 'This promo code has expired',
    ru: 'Срок действия этого промокода истек',
  },
  'gateway.promo_code.usage_limit_reached': {
    eng: 'The promo code usage limit has been reached',
    ru: 'Лимит применения этого промокода исчерпан',
  },
};
