import GatewayService from '@just-ai/api/dist/services/CopilotGatewayService';
import { signal } from '@preact/signals-react';
import axios, { AxiosError, AxiosInstance } from 'axios';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';

import initMock from './axiosMock';
import {
  keycloakInstance,
  keycloakInitOptions,
  useCloudLogin,
  useKeycloakLogin,
  userKeycloakAuthHeaderSet,
  ccOptions,
  goToSelectAccount,
} from './cc.api';
import { showBillingModal } from '../hooks/showBilling';
import { isX5 } from '../isX5';
import localize from '../localization';
import { addAlert } from '../models/alerts';
import { utmTagsList } from '../models/currentUser';
import { goTo403, goToLogin, goToHome } from '../routes';
import { AgentApiError } from '../types/errors';
import { isDev } from '../utils/app/common';

const inlineMoveUtmTagsBetweenUrls = (redirectUrlObject: URL, destinationUrl: URL) => {
  const existingSearchParams = new URLSearchParams(window.location.search);
  Object.entries(utmTagsList.value).forEach(([key, value]) => {
    if (existingSearchParams.get(key)) {
      redirectUrlObject.searchParams.delete(key);
    }
    destinationUrl.searchParams.set(key, value);
    return;
  });
};

export const goToCloud = (destination: 'login' | 'register', redirectUrl?: string) => {
  const redirectUrlObject = new URL(redirectUrl || window.location.href);
  const destinationPath = destination === 'login' ? '/c/login' : '/c/register';
  const destinationUrl = isDev()
    ? new URL(`https://localhost:3001${destinationPath}`)
    : new URL(`${redirectUrlObject.origin}${destinationPath}`);

  inlineMoveUtmTagsBetweenUrls(redirectUrlObject, destinationUrl);

  destinationUrl.searchParams.set('redirectUrl', redirectUrlObject.href);
  window.location.href = destinationUrl.href;
};

export const setUserAuthKeycloak = (apiClientInstance: AxiosInstance, userToken: string) => {
  apiClient.defaults.headers['authorization'] = `Bearer ${userToken}`;
  userKeycloakAuthHeaderSet.value = true;
};

export const goToInvitationPage = (accountId: number | undefined) => {
  let inviteDomain = window.location.host;
  if (!accountId) return;

  const domains = ccOptions.value?.domains;
  if (!domains) return;

  const ccDomain = domains['cc'].domain;

  const currentOriginDomainData = Object.values(domains).find(domain => domain.domain === inviteDomain);

  if (!currentOriginDomainData || currentOriginDomainData.appTitle !== 'copilot') {
    const domainWithAppTitle = Object.values(domains).find(domain => domain.appTitle === 'copilot');
    inviteDomain = domainWithAppTitle?.domain ?? inviteDomain;
  }

  window.open(`${window.location.protocol}//${ccDomain}/c/accounts/${accountId}/invite/${inviteDomain}`, '_blank');
};

let keyCloakInited = false;

const keycloakLogin = async () => {
  await keycloakInstance?.login({ redirectUri: window.location.origin });
  return;
};

export const X5_User_Should_haveGroup_Error = signal(false);

export const hasDemo = signal(false);

export const isDemoMode = signal(false);

export const goToKeycloakLogin = async (
  apiClientInstance: AxiosInstance,
  keycloakInstance: Keycloak,
  keycloakInitOptions: KeycloakInitOptions,
  callBacks?: {
    callbackPositive?: () => void;
    callbackNegative?: () => void;
  }
) => {
  if (keyCloakInited) {
    await keycloakLogin();
  }
  keyCloakInited = true;
  try {
    const data = await keycloakInstance?.init(keycloakInitOptions);
    apiClientInstance.interceptors.request.use(async config => {
      return new Promise((resolve, reject) => {
        keycloakInstance
          .updateToken(5)
          .then(() => {
            config.headers = {
              ...config.headers,
              Authorization: 'Bearer ' + keycloakInstance.token,
            };
            resolve(config);
          })
          .catch(error => {
            reject(error);
          });
      });
    });
    // keycloakInstance.onTokenExpired = async () => {
    //   const response = keycloakInstance?.updateToken(5);
    //   if (!response) await keycloakLogin();
    // };
    if (!data) {
      await keycloakInstance?.login({ redirectUri: keycloakInitOptions.redirectUri });
    } else {
      if (isX5) {
        if (!(keycloakInstance.tokenParsed?.group || []).includes('AiCDO')) {
          if (callBacks?.callbackNegative) callBacks.callbackNegative();
          goTo403();
          X5_User_Should_haveGroup_Error.value = true;
          return;
        } else {
          if (window.location.pathname.endsWith('/403')) {
            goToHome();
            X5_User_Should_haveGroup_Error.value = false;
          }
        }
      }
    }
    setUserAuthKeycloak(apiClientInstance, keycloakInstance?.token || '');
  } catch (error) {
    console.error('error logging in to keycloak', error);
    keyCloakInited = false;
    await keycloakLogin();
  }
};

const apiClient = axios.create({
  headers: {
    accept: 'application/json',
    'Z-userToken': localStorage.getItem('token'),
  },
  validateStatus: status => {
    switch (status) {
      case 401: {
        if (useCloudLogin.value) {
          if (hasDemo.value && !isDemoMode.value) {
            isDemoMode.value = true;
            initMock();
            return true;
          }
          goToCloud('login');
        } else if (useKeycloakLogin.value && keycloakInstance && keycloakInitOptions) {
          goToKeycloakLogin(apiClient, keycloakInstance, keycloakInitOptions);
        } else {
          goToLogin();
        }
        return false;
      }
      case 403: {
        if (useCloudLogin.value && !isDemoMode.value) {
          if (hasDemo.value) {
            isDemoMode.value = true;
            initMock();
            return true;
          }
          addAlert(localize.translate('403Error'), 'error', localize.translate('accessDenied'));
          return false;
        }
        if (useKeycloakLogin.value && keycloakInstance && keycloakInitOptions) {
          if (isX5) {
            goTo403();
            return false;
          }
          goToKeycloakLogin(apiClient, keycloakInstance, keycloakInitOptions);
        }
        return false;
      }
      default:
        return status >= 100 && status < 300;
    }
  },
});

apiClient.defaults.timeout = 600000; //10min

export const setUserToken = userToken => {
  apiClient.defaults.headers['Z-userToken'] = userToken;
};
export const setZuserId = userId => {
  apiClient.defaults.headers['Z-userId'] = userId;
};

const gatewayApi = new GatewayService(axios);

apiClient.interceptors.response.use(
  response => {
    const { data, config } = response;
    if (typeof data === 'string' && data.endsWith('</html>')) {
      console.log(
        '%cBACKEND / NGINX ERROR!',
        'color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: var(--gray-950) 1px 1px;'
      );
      console.log(
        `%crequest ${config?.method?.toUpperCase()} to ${config.url} returns html`,
        'color: red; font-family: sans-serif; font-size: 2em; font-weight: normal; text-shadow: var(--gray-950) 1px 1px;'
      );
      return Promise.reject(
        `BACKEND/NGINX ERROR: request ${config?.method?.toUpperCase()} to ${config.url} returns html`
      );
    }
    return response;
  },
  error => {
    const response = (error as AxiosError<AgentApiError>)?.response;
    if (response?.status === 402 && response.data.error === 'gateway.balance.not_enough_tokens') {
      gatewayApi.sendEvent({
        eventName: 'OutOfTokens',
      });
      showBillingModal('outOfTokens');
    }
    if (
      response?.status === 403 &&
      (response.data.error === 'account.access.denied' || response.data.error === 'gateway.user.internal') &&
      !hasDemo.value &&
      useCloudLogin.value
    ) {
      goToSelectAccount();
    }

    throw error;
  }
);

export default apiClient;
