import React, { ComponentType } from 'react';

import { ApiKeyIdentifier } from '@just-ai/api/dist/generated/CopilotGateway';
import {
  DropdownButton,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  IconButton,
  useTranslation,
} from '@just-ai/just-ui';
import cn from 'classnames';
import { format } from 'date-fns';

import styles from './styles.module.scss';
import { t } from '../../../../localization';
import { ApiKeyModalProps } from '../../GenerateApiKeyModal';

export type ApiKeyRow = ApiKeyIdentifier & {
  editActions?: {
    edit?: () => void;
    delete?: () => void;
  };
};

type ApiKeysTableProps = {
  apiKeys: ApiKeyIdentifier[];
  openModalHandler: (args: { type: ApiKeyModalProps['type']; value?: ApiKeyIdentifier }) => void;
  sortHandler: (column: string) => void;
  adminTable?: boolean;
};

const RowComponent: ComponentType<{ data: ApiKeyRow; index: number; adminTable?: boolean }> = ({
  data,
  index,
  adminTable,
}) => {
  return (
    <>
      <td className='text-left pl-4'>{data.prefix}_*********************</td>
      {adminTable && <td>{data.owner?.email}</td>}
      <td>{data.created ? format(new Date(data.created), 'dd.MM.yyyy') : '—'}</td>
      <td>{data.lastUsage ? format(new Date(data.lastUsage), 'dd.MM.yyyy') : '—'}</td>
      <td className='pr-4'>
        <DropdownButton direction='down'>
          <DropdownToggle color='none' tag='div'>
            <IconButton className='ml-auto' name='farEllipsisH' data-test-id={`Limits:table:row:edit:${index}`} />
          </DropdownToggle>
          <DropdownMenu positionFixed>
            <DropdownItem
              data-test-id={`Limits:table:dropdown:delete:${index}`}
              className={cn(styles.Table__dropdownItem, styles['Table__dropdownItem--red'], 'flex items-center')}
              iconLeft='farTrashAlt'
              color='red'
              iconSize='md'
              onClick={data.editActions?.delete}
            >
              {t('delete')}
            </DropdownItem>
          </DropdownMenu>
        </DropdownButton>
      </td>
    </>
  );
};

export default function LimitsTable(props: ApiKeysTableProps) {
  const { apiKeys = [], openModalHandler, adminTable, sortHandler } = props;

  const { t } = useTranslation();

  return (
    <>
      <table className={cn(styles.Table, styles.Table__limits, 'tableWithBorderedRows')}>
        <thead>
          <tr>
            <td className='text-left pl-4'>{t('AccountPage:apikey:key')}</td>
            {adminTable ? (
              <>
                <td>{t('AccountPage:apikey:owner')}</td>
                <td>
                  <div className='flex gap-8 align-center pl-4 justify-end'>
                    {t('AccountPage:apikey:created')}
                    <Icon className='cursor-pointer' name='faSort' size='sm' onClick={() => sortHandler('created')} />
                  </div>
                </td>
              </>
            ) : (
              <td>
                <div className='flex gap-8 align-center justify-end'>
                  {t('AccountPage:apikey:added')}
                  <Icon className='cursor-pointer' name='faSort' size='sm' onClick={() => sortHandler('created')} />
                </div>
              </td>
            )}
            <td>
              <div className='flex gap-8 align-center justify-end'>
                {t('AccountPage:apikey:lastUsed')}
                <Icon className='cursor-pointer' name='faSort' size='sm' onClick={() => sortHandler('lastUsage')} />
              </div>
            </td>
            <td> </td>
          </tr>
        </thead>
        <tbody>
          {apiKeys.map((apikey, index) => (
            <React.Fragment key={apikey.prefix}>
              <tr>
                <RowComponent
                  data={{
                    ...apikey,
                    editActions: {
                      delete: () => openModalHandler({ type: 'delete', value: apikey }),
                    },
                  }}
                  index={index}
                  adminTable={adminTable}
                />
              </tr>
              <tr>
                <td style={{ borderStyle: 'none', padding: '4px 0' }} colSpan={6}></td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  );
}
