import React, { memo } from 'react';

import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import { useSidebarActions } from '../../contexts/appContext';
import { isAlpha } from '../../isAlpha';
import { isTovie } from '../../isTovie';
import { isX5 } from '../../isX5';
import { routes } from '../../routes';
import styles from './style.module.scss';
import { appOptions } from '../../api/cc.api';

const logoLoader = import(
  isAlpha
    ? '../../images/Alfabank_logo.png'
    : isTovie
    ? '../../images/tovie_logo.png'
    : isX5
    ? '../../images/X5Group.svg'
    : '../../images/CopilotLogo.svg'
);

let logo = '';
logoLoader.then(pathToSource => (logo = pathToSource.default));

export const LogoComponent = memo(() => {
  const [, closeSidebar] = useSidebarActions();

  return (
    <NavLink
      to={routes.templates}
      onClick={closeSidebar}
      className={cn('flex items-center text-decoration-none', styles.logo__wrapper)}
      data-test-id='Logo.wrapper'
    >
      <>
        <img
          src={appOptions.value?.product?.logo || logo}
          className={cn(styles.logo, {
            [styles.logo_isAlpha]: isAlpha,
            [styles.logo_isTovie]: isTovie,
            [styles.logo_isX5]: isX5,
          })}
          alt=''
        />
        {Boolean(!isTovie && !isX5) && (
          <p className='text-black ml-2 font-size-20'>
            {isAlpha ? 'Smarty' : appOptions.value?.product?.name || 'Jay Copilot'}
          </p>
        )}
      </>
    </NavLink>
  );
});

LogoComponent.displayName = 'LogoComponent';
