import React, { memo, MouseEventHandler, useCallback, useContext, useState } from 'react';

import { Icon } from '@just-ai/just-ui';
import cn from 'classnames';
import { useLocation, useParams } from 'react-router';

import styles from './style.module.scss';
import AppContext from '../../../contexts/appContext';
import { goToTemplates } from '../../../routes';
import { Conversation } from '../../../types/chat';
import SidebarActionButton from '../../Buttons/SidebarActionButton';

interface Props {
  conversationId: Conversation['id'];
  conversationMessageIsStreaming: Conversation['messageIsStreaming'];
}

interface ParamTypes {
  conversationId?: string;
}

const ConversationDelete = memo(({ conversationId, conversationMessageIsStreaming }: Props) => {
  const { handleDeleteConversation } = useContext(AppContext);

  const params = useParams<ParamTypes>();
  const isAlwaysVisible = conversationId === params.conversationId;

  const [isDeleting, setIsDeleting] = useState(false);

  const { pathname } = useLocation();

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();

      if (isDeleting) {
        if (pathname.includes(conversationId)) {
          goToTemplates();
        }
        handleDeleteConversation(conversationId);
      }
      setIsDeleting(false);
    },
    [conversationId, handleDeleteConversation, isDeleting, pathname]
  );

  const handleCancel: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsDeleting(false);
  };

  const handleOpenDeleteModal = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      if (conversationMessageIsStreaming) return;
      setIsDeleting(true);
    },
    [conversationMessageIsStreaming]
  );

  return (
    <>
      {isDeleting ? (
        <div className='flex gap-8'>
          <SidebarActionButton handleClick={handleConfirm}>
            <Icon name='farCheck' />
          </SidebarActionButton>
          <SidebarActionButton handleClick={handleCancel}>
            <Icon name='farTimes' />
          </SidebarActionButton>
        </div>
      ) : (
        <div className='flex align-items-center gap-16'>
          <Icon
            name='farTrashAlt'
            className={cn({
              'color-gray-400 hover-color-gray-400 cursor-not-allowed': conversationMessageIsStreaming,
              'color-gray-600 hover-color-gray-800 cursor-pointer': !conversationMessageIsStreaming,
            })}
            wrapperClassName={isAlwaysVisible ? styles.navLink__icon_visible : styles.navLink__icon}
            onClick={handleOpenDeleteModal}
          />
        </div>
      )}
    </>
  );
});

ConversationDelete.displayName = 'memo(ConversationDelete)';

export default ConversationDelete;
