import React from 'react';

import { Spinner, useTranslation } from '@just-ai/just-ui';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import DivInsteadOfAnchor from './DivInsteadOfAnchor';
import styles from './style.module.scss';
import { routes } from '../../../routes';
import { Conversation } from '../../../types/chat';
import { isMobile } from '../../../utils/app/common';

interface Props {
  conversation: Conversation;
  onClick: (conversation: Conversation) => void;
}

export default function MainConversation({ conversation, onClick: handleClick }: Props) {
  const { t } = useTranslation();

  return (
    <NavLink
      component={DivInsteadOfAnchor}
      to={routes.mainConversation}
      exact={true}
      activeClassName={styles['selected--main']}
      data-test-id='JayAssistant'
      className={cn(
        styles.navLink,
        styles['navLink--main'],
        'relative flex items-center text-decoration-none w-100 overflow-hidden'
      )}
    >
      <div
        className='flex cursor-pointer flex-grow-1 font-size-16 justify-between rounded-lg p-3 transition-colors align-items-center overflow-hidden'
        onClick={() => handleClick(conversation)}
      >
        {t('assistantJay')}
        {conversation.status === 'BUILDING' || conversation.messageIsStreaming ? (
          <Spinner size={isMobile() ? 'lg' : 'md'} inline />
        ) : null}
        {conversation.hasUnreadChanges ? <div className={styles.tip} /> : null}
      </div>
    </NavLink>
  );
}
