import React, { CSSProperties, FC, memo, useCallback, useEffect, useMemo } from 'react';

import {
  FileResponse,
  LinkResponse,
  ResourceSearchRequest,
  ResourceType,
} from '@just-ai/api/dist/generated/AppsAdapter';
import { Icon, SearchInput, useTranslation } from '@just-ai/just-ui';
import { useSignalEffect } from '@preact/signals-react';
import cn from 'classnames';
import { merge } from 'lodash';

import { defaultDate, FilterFileFormatOptions } from './consts';
import { filesLocalization } from './files.loc';
import { FilesSpinner } from './FilesSpinner';
import { FilesUploadProvider } from './FilesUploadProvider';
import {
  dropFilters,
  filters,
  setFilterDates,
  setFilters,
  setTabFilters,
  toggleSortDirection,
} from './FIlters.Contorller';
import styles from './styles.module.scss';
import { appOptions } from '../../api/cc.api';
import { AnalyticsDatePicker } from '../../components/Account/components/AnalyticsDatePicker';
import { localCategoriesMap } from '../../components/Agents/Categories';
import { DeleteSelected } from '../../components/Files/DeleteSelected';
import FilesHeader from '../../components/Files/FilesHeader';
import { FilesTable } from '../../components/Files/FilesTable';
import { FileTableRow } from '../../components/Files/FilesTable/FileTableRow';
import { LinkTableRow } from '../../components/Files/FilesTable/LinkTableRow';
import useSelected from '../../components/Files/FilesTable/useSelected';
import { FilesTabs } from '../../components/Files/FilesTabs';
import { FilesUpload } from '../../components/Files/FilesUpload';
import { FilterContainer } from '../../components/Files/Filter/FilterContainer';
import { LinksAdd } from '../../components/Files/LinksAddModal/LinksAdd';
import Pagination from '../../components/Pagination';
import { isTovie } from '../../isTovie';
import { isX5 } from '../../isX5';
import localize from '../../localization';
import { filesAndLinks, resourcesLoaded, resourcesLoading, useResources } from '../../models/files';
import { templates } from '../../models/templates';
import { AgentType } from '../../types/agents';

localize.addTranslations(filesLocalization);

type FilesPage = {
  initialActiveTab?: ResourceType;
  hideHeader?: boolean;
  hideTabs?: boolean;
  hideUploadButtons?: boolean;
  hideFormatsFilter?: boolean;
  hideAppsFilter?: boolean;
  hideTos?: boolean;
  hideAllFilters?: boolean;
  noPagePadding?: boolean;
  hideTableRowSelection?: boolean;
  hideTableRowDropdown?: boolean;
  filtersParam?: {
    filters: Partial<ResourceSearchRequest['filters']>;
    sort?: Partial<ResourceSearchRequest['sort']>;
    page?: ResourceSearchRequest['page'];
  };
  pageStyle?: CSSProperties;
  tableRowOnClick?: (target: FileResponse | LinkResponse) => unknown;
};
export const FilesPage: FC<FilesPage> = memo(
  ({
    initialActiveTab = ResourceType.AppFiles,
    hideHeader,
    hideTabs,
    hideUploadButtons,
    hideFormatsFilter,
    hideAppsFilter,
    hideAllFilters,
    hideTos,
    hideTableRowSelection,
    hideTableRowDropdown,
    noPagePadding,
    pageStyle = {},
    filtersParam,
    tableRowOnClick,
  }) => {
    const {
      batchCreateLinks,
      deleteLinks,
      deleteLink,
      getResources,
      deleteFilesHandle,
      batchUploadFilesHandle,
      uploadFileHandle,
      deleteFileHandle,
      tabs,
      activeTab,
      onTabChangeHandle,
    } = useResources({
      initialLoad: true,
      initialActiveTab: initialActiveTab,
      initialFilters: {
        filters: merge(
          {
            type: initialActiveTab,
            periodFrom: defaultDate().periodFrom.toISOString(),
            periodTo: defaultDate().periodTo.toISOString(),
          },
          filters.value[initialActiveTab].filters,
          filtersParam?.filters || {}
        ),
      },
    });
    const updateResources = useCallback(() => {
      if (!resourcesLoading.value) {
        getResources(filters.value[activeTab]);
      }
    }, [activeTab, getResources]);
    const { t } = useTranslation();
    const templatesList = templates.value?.templatesList as AgentType[];

    const templateOptionValues = useMemo(
      () =>
        templatesList
          .filter(
            template =>
              !!template.categories &&
              !template.requiredFeature &&
              template.categories.some(templateCategory => localCategoriesMap.includes(templateCategory))
          )
          .map(template => ({ label: template.info.title, value: template.template })),
      [templatesList]
    );

    const genFilesSelected = useSelected('id', filesAndLinks.value.resources[ResourceType.AppFiles]);
    const appFilesSortDirection = filters.value[ResourceType.AppFiles]?.sort?.direction || 'asc';
    const genFilesColumns = useMemo(() => {
      return [
        { title: t('Files:Table:FileName') },
        { title: t('Files:Table:FileFormat') },
        { title: t('Files:Table:Agent') },
        {
          title: (
            <div
              className='d-flex align-items-center gap-8 cursor-pointer'
              onClick={() => toggleSortDirection(ResourceType.AppFiles)}
            >
              {t('Files:Table:Added')}
              {/*<Icon name='faSort' color='secondary' size='sm' />*/}
              {appFilesSortDirection === 'asc' ? (
                <Icon name='faSortUp' color='secondary' size='sm' />
              ) : (
                <Icon name='faSortDown' color='secondary' size='sm' />
              )}
            </div>
          ),
        },
        { title: t('Files:Table:Size') },
        ...(hideTableRowDropdown ? [] : [{ title: '', width: 38 }]),
      ];
    }, [t, appFilesSortDirection, hideTableRowDropdown]);

    const uploadedFilesSelected = useSelected('id', filesAndLinks.value.resources[ResourceType.UserFiles]);
    const userFilesSortDirection = filters.value[ResourceType.UserFiles]?.sort?.direction || 'asc';
    const uploadedFilesColumns = useMemo(() => {
      return [
        { title: t('Files:Table:FileName') },
        { title: t('Files:Table:FileFormat') },
        {
          title: (
            <div
              className='d-flex align-items-center gap-8 cursor-pointer'
              onClick={() => {
                toggleSortDirection(ResourceType.UserFiles);
                updateResources();
              }}
            >
              {t('Files:Table:Added')}
              {userFilesSortDirection === 'asc' ? (
                <Icon name='faSortUp' color='secondary' size='sm' />
              ) : (
                <Icon name='faSortDown' color='secondary' size='sm' />
              )}
            </div>
          ),
        },
        { title: t('Files:Table:Size') },
        ...(hideTableRowDropdown ? [] : [{ title: '', width: 38 }]),
      ];
    }, [hideTableRowDropdown, t, updateResources, userFilesSortDirection]);

    const linksSortDirection = filters.value[ResourceType.Links]?.sort?.direction || 'asc';
    const linkColumns = useMemo(() => {
      return [
        { title: t('Files:Table:FileName') },
        {
          title: (
            <div
              className='d-flex align-items-center gap-8 cursor-pointer'
              onClick={() => {
                toggleSortDirection(ResourceType.Links);
                updateResources();
              }}
            >
              {t('Files:Table:Added')}
              {linksSortDirection === 'asc' ? (
                <Icon name='faSortUp' color='secondary' size='sm' />
              ) : (
                <Icon name='faSortDown' color='secondary' size='sm' />
              )}
            </div>
          ),
        },
        ...(hideTableRowDropdown ? [] : [{ title: '', width: 38 }]),
      ];
    }, [t, linksSortDirection, hideTableRowDropdown, updateResources]);

    const linkSelected = useSelected('id', filesAndLinks.value.resources[ResourceType.Links]);

    const setSearchHandler = useCallback(
      (value: string) => {
        setFilters(prevFilters => {
          prevFilters[activeTab].filters.searchText = value.trimStart();
        });
      },
      [activeTab]
    );

    const onSelectFormatChange = useCallback(
      (values: string[]) => {
        setFilters(prevFilters => {
          prevFilters[activeTab].filters.formats = values.length ? values : undefined;
        });
        updateResources();
      },
      [activeTab, updateResources]
    );
    const onTemplateChange = useCallback(
      (values: string[]) => {
        setFilters(prevFilters => {
          prevFilters[activeTab].filters.templates = values.length ? values : undefined;
        });
        updateResources();
      },
      [activeTab, updateResources]
    );

    useEffect(() => {
      return () => {
        dropFilters();
      };
    }, []);
    // if user delete all sources on page, drop page to 0
    useSignalEffect(() => {
      if (
        !resourcesLoading.value &&
        resourcesLoaded.value &&
        (filters.value[activeTab]?.page?.index || 0) > 0 &&
        filesAndLinks.value.resources[activeTab].length === 0
      ) {
        setFilters(prevFilters => {
          merge(prevFilters[activeTab], { page: { index: 0 } });
        });
        updateResources();
      }
    });

    const defaultDates = defaultDate();

    const filesInTable = (
      activeTab === ResourceType.AppFiles
        ? filesAndLinks.value.resources[ResourceType.AppFiles]
        : filesAndLinks.value.resources[ResourceType.UserFiles]
    ) as FileResponse[];

    const linksInTable = filesAndLinks.value.resources[ResourceType.Links] as LinkResponse[];

    return (
      <FilesUploadProvider
        value={{
          getResources,
          deleteFilesHandle,
          batchUploadFilesHandle,
          uploadFileHandle,
          deleteFileHandle,
          batchCreateLinks,
          deleteLinks,
          deleteLink,
        }}
      >
        <div
          className={cn({ 'padding-x-24 padding-top-24': !noPagePadding }, 'd-flex flex-column gap-24')}
          style={{ height: '100dvh', ...pageStyle }}
        >
          <FilesSpinner />
          {hideHeader ? null : <FilesHeader />}
          {hideUploadButtons ? null : (
            <div className='d-flex gap-8'>
              {!isX5 && <FilesUpload />}
              <LinksAdd />
            </div>
          )}

          {hideTabs ? null : (
            <div>
              <FilesTabs tabs={tabs} activeTab={activeTab} onTabChange={onTabChangeHandle} />
            </div>
          )}
          {hideAllFilters ? null : (
            <div className='flex w-full gap-8 items-center'>
              <SearchInput
                className={styles.searchInputSize}
                maxWidth={300}
                maxLength={300}
                alwaysOpen
                clearable={Boolean(filters.value[activeTab]?.filters?.searchText || '')}
                autoFocus
                onChange={setSearchHandler}
                onClear={() => {
                  setSearchHandler('');
                  updateResources();
                }}
                value={filters.value[activeTab]?.filters?.searchText || ''}
                size='md'
                placeholder={
                  activeTab === ResourceType.Links
                    ? t('FilesPage:Filters:LinkInputPlaceholder')
                    : t('FilesPage:Filters:InputPlaceholder')
                }
                onKeyDown={e => {
                  if (e.key === 'Enter') updateResources();
                }}
              />
              {hideFormatsFilter || activeTab === ResourceType.Links ? null : (
                <FilterContainer
                  onSelectChange={onSelectFormatChange}
                  buttonText={t('FilesPage:Filters:FormatText')}
                  displayValue={
                    FilterFileFormatOptions.length === (filters.value[activeTab]?.filters?.formats || []).length
                      ? t('FilesPage:Filters:AllValues')
                      : (filters.value[activeTab]?.filters?.formats || []).length > 0
                        ? t('FilesPage:Filters:ValuesSelected', {
                            selected: (filters.value[activeTab]?.filters?.formats || []).length,
                          })
                        : t('FilesPage:Filters:AllValues')
                  }
                  options={FilterFileFormatOptions}
                  DropDownTitle={t('FilesPage:Filters:FormatDropTitle')}
                  AllValueSelect={t('FilesPage:Filters:AllValues')}
                />
              )}
              {hideAppsFilter || [ResourceType.UserFiles, ResourceType.Links].includes(activeTab) ? null : (
                <FilterContainer
                  buttonText={t('FilesPage:Filters:AppText')}
                  displayValue={
                    templateOptionValues.length === (filters.value[activeTab]?.filters?.templates || []).length
                      ? t('FilesPage:Filters:AllValues')
                      : (filters.value[activeTab]?.filters?.templates || []).length > 0
                        ? t('FilesPage:Filters:ValuesSelected', {
                            selected: (filters.value[activeTab]?.filters?.templates || []).length,
                          })
                        : t('FilesPage:Filters:AllValues')
                  }
                  onSelectChange={onTemplateChange}
                  options={templateOptionValues}
                  DropDownTitle={t('FilesPage:Filters:AppDropTitle')}
                  AllValueSelect={t('FilesPage:Filters:AllValues')}
                />
              )}

              <AnalyticsDatePicker
                dates={{
                  startDate: new Date(filters.value[activeTab]?.filters.periodFrom || defaultDates.periodFrom),
                  endDate: new Date(filters.value[activeTab]?.filters.periodTo || defaultDates.periodTo),
                }}
                setDates={dates => {
                  if (
                    JSON.stringify({
                      periodFrom: filters.value[activeTab]?.filters.periodFrom,
                      periodTo: filters.value[activeTab]?.filters.periodTo,
                    }) !==
                    JSON.stringify({ periodFrom: dates.startDate.toISOString(), periodTo: dates.endDate.toISOString() })
                  ) {
                    setFilterDates(activeTab, dates);
                    updateResources();
                  }
                }}
                buttonText={t('FilesPage:Filters:DateText')}
              />
              <DeleteSelected
                activeTab={activeTab}
                linkSelected={linkSelected}
                genFilesSelected={genFilesSelected}
                uploadedFilesSelected={uploadedFilesSelected}
              />
            </div>
          )}

          <div className='d-flex flex-grow-1 flex-column gap-16' style={{ overflow: 'hidden' }}>
            <div className='d-flex flex-grow-1' style={{ maxHeight: '100%', overflow: 'scroll' }}>
              {ResourceType.Links === activeTab && (
                <FilesTable
                  selected={hideTableRowSelection ? undefined : linkSelected}
                  columns={linkColumns}
                  tableClassName={styles.tableFixHead}
                >
                  {linksInTable.map(link => (
                    <LinkTableRow
                      tableRowOnClick={tableRowOnClick}
                      key={link.id}
                      selected={hideTableRowSelection ? undefined : linkSelected}
                      link={link}
                      hideTableRowDropdown={hideTableRowDropdown}
                    />
                  ))}
                </FilesTable>
              )}
              {[ResourceType.AppFiles, ResourceType.UserFiles].includes(activeTab) && (
                <FilesTable
                  selected={
                    hideTableRowSelection
                      ? undefined
                      : activeTab === ResourceType.AppFiles
                        ? genFilesSelected
                        : uploadedFilesSelected
                  }
                  columns={activeTab === ResourceType.AppFiles ? genFilesColumns : uploadedFilesColumns}
                  tableClassName={styles.tableFixHead}
                >
                  {filesInTable.map(source => (
                    <FileTableRow
                      tableRowOnClick={tableRowOnClick}
                      selected={
                        hideTableRowSelection
                          ? undefined
                          : activeTab === ResourceType.AppFiles
                            ? genFilesSelected
                            : uploadedFilesSelected
                      }
                      source={source}
                      key={source.id}
                      hideTableRowDropdown={hideTableRowDropdown}
                      hideTemplateName={activeTab === ResourceType.UserFiles}
                    />
                  ))}
                </FilesTable>
              )}
            </div>
            {(filesAndLinks.value.totals[activeTab] || 0) / (filters.value[activeTab]?.page?.size || 20) > 1 && (
              <div data-test-id='FilesPage.Table.Pagination' className='padding-bottom-16'>
                <Pagination
                  page={filters.value[activeTab]?.page?.index || 0}
                  size={filters.value[activeTab]?.page?.size || 20}
                  totalCount={filesAndLinks.value.totals[activeTab] || 0}
                  changePage={page => {
                    setTabFilters(activeTab, { page: { index: page } });
                    updateResources();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </FilesUploadProvider>
    );
  }
);
FilesPage.displayName = 'memo(FilesPage)';
