import React, { useRef } from 'react';

import {
  IconButton,
  Popover,
  useToggle,
  useOnClickOutside,
  useTranslation,
  Icon,
  Tooltip,
  Button,
} from '@just-ai/just-ui';
import cn from 'classnames';

import { Conversation } from '../../../../types/chat';
import styles from '../../style.module.scss';
import { ChatHeaderSelectFileFromHistoryButton } from '../ChatHeaderActionsPanel/buttons/ChatHeaderSelectFileFromHistoryButton';

type Props = {
  actionButtonTitle: string;
  onClearHistoryClick: () => void;
  onDeleteClick: () => void;
  onSettingsClick: () => void;
  onSelectFileClick?: (() => void) | null;
  selectedConversation: Conversation;
};

export default function ChatHeaderMobileContextControls(props: Props) {
  const {
    actionButtonTitle,
    onClearHistoryClick: handleClearHistoryClick,
    onDeleteClick: handleDeleteClick,
    onSettingsClick: handleSettingsClick,
    onSelectFileClick: handleSelectFileClick,
    selectedConversation,
  } = props;

  const [isMobileSettingsPopover, , closeMobileSettingsPopover, toggleMobileSettingsPopover] = useToggle(false);

  const { t } = useTranslation();

  const settingsBtnRef = useRef<HTMLButtonElement>(null);
  const popoverContentWrapper = useRef<HTMLDivElement>(null);

  const onOutsideClick = (event: MouseEvent) => {
    if (
      !settingsBtnRef.current?.contains(event.target as Node) &&
      !(event.target as HTMLElement).className.includes('tooltip-inner')
    ) {
      closeMobileSettingsPopover();
    }
  };

  useOnClickOutside(popoverContentWrapper, onOutsideClick);

  if (!selectedConversation.contextValue) return null;

  return (
    <>
      <IconButton
        innerRef={settingsBtnRef}
        className={cn(styles.chat__header__icon, 'd-sm-none', {
          [styles.chat__header__icon_active]: selectedConversation.contextValue.messagesTruncationMode,
        })}
        data-test-id='Chat.name_btn_edit_mobile'
        name='farEllipsisH'
      />
      <Popover
        target={settingsBtnRef}
        isOpen={isMobileSettingsPopover}
        placement='bottom'
        toggle={toggleMobileSettingsPopover}
        hideArrow={true}
        withoutBoxShadow={true}
        popperClassName={styles.chat__header__popover}
        positionFixed
      >
        <div ref={popoverContentWrapper} className=''>
          <div className={styles.chat__header__popover__context}>
            <div className='flex justify-between mb-4'>
              <p>
                {t('ChatHeader:context')}:{' '}
                <b className={cn({ 'text-danger': selectedConversation.contextValue.messagesTruncationMode })}>
                  {selectedConversation.contextValue.fullness}%
                </b>
              </p>
              <div className={styles.chat__header__qIcon} id='chatHeaderContextIconMobile'>
                <Icon className='flex' name='faQuestion' />
              </div>
              <Tooltip autohide={false} fade={false} target='chatHeaderContextIconMobile' positionFixed>
                {t('ChatHeader:contextTooltip')}
              </Tooltip>
            </div>
            <Button className='w-full' color='secondary' outline onClick={handleClearHistoryClick}>
              {actionButtonTitle}
            </Button>
          </div>
          <Button
            onClick={handleSettingsClick}
            className='mt-4 w-full justify-start'
            flat
            iconLeft='farPen'
            color='secondary'
          >
            {t('appSettings')}
          </Button>
          {handleSelectFileClick ? (
            <ChatHeaderSelectFileFromHistoryButton
              renderAs='button'
              className='mt-3 w-full justify-start'
              buttonProps={{
                flat: true,
                color: 'secondary',
              }}
              onClick={() => {
                closeMobileSettingsPopover();
                handleSelectFileClick();
              }}
            />
          ) : null}
          <Button
            onClick={handleDeleteClick}
            className='mt-3 w-full justify-start'
            flat
            iconLeft='farTrashAlt'
            color='danger'
          >
            {t('deleteChat')}
          </Button>
        </div>
      </Popover>
    </>
  );
}
