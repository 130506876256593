import React, { useCallback, useEffect, useState } from 'react';

import { ApiKeyIdentifier, ApiKeyList } from '@just-ai/api/dist/generated/CopilotGateway';
import { Button, Icon, useTranslation, usePromiseProcessing, Spinner } from '@just-ai/just-ui';
import { AppLogger } from '@just-ai/logger';
import cn from 'classnames';

import styles from './styles.module.scss';
import { appOptions } from '../../../api/cc.api';
import { addAlert } from '../../../models/alerts';
import useApiService from '../../../services/useApiService';
import { createDocsLink } from '../../../utils/app/common';
import Pagination from '../../Pagination';
import { usePagination } from '../../Pagination/hook';
import ApiKeysTable from '../components/Tables/ApiKeysTable';
import ApiKeyModal from '../GenerateApiKeyModal';

export default function ApiKeysTab() {
  const { t } = useTranslation();

  const [userApikeys, setUserApiKeys] = useState<ApiKeyList | undefined>();

  const [apiKeyModalShown, setApiKeyModalShown] = useState<{ type: 'create' | 'delete'; value?: ApiKeyIdentifier }>();

  const [sortState, setSortState] = useState({ field: 'created', dir: 'desc' });

  const { getUserApiKeys } = useApiService();

  const { paginationInfo, changePage } = usePagination({
    pageNum: userApikeys?.page?.number ?? 0,
    pageSize: userApikeys?.page?.size ?? 10,
    totalCount: userApikeys?.page?.totalElements ?? 0,
    totalPages: userApikeys?.page?.totalPages ?? 0,
  });

  const getApiKeysList = useCallback(
    async (page?: number) => {
      const { data: apiKeysRes } = await getUserApiKeys(page || 0, [sortState.field, sortState.dir]);
      setUserApiKeys(apiKeysRes);
    },
    [getUserApiKeys, sortState.dir, sortState.field]
  );

  const [{ loading }, fetchApiKeysList] = usePromiseProcessing(async () => getApiKeysList(paginationInfo.pageNum), {
    deps: [getApiKeysList, paginationInfo.pageNum],
    onError: error => {
      addAlert(t('AccountPage:apikey:fetchError'));
      AppLogger.error({ message: 'failed to fetch account api keys', exception: error });
    },
  });

  const handleSortClick = async (value: string) => {
    return setSortState(prevState => {
      if (prevState.field === value) return { field: value, dir: prevState.dir === 'desc' ? 'asc' : 'desc' };
      return { dir: 'desc', field: value };
    });
  };

  useEffect(() => {
    fetchApiKeysList();
  }, [fetchApiKeysList]);

  const docs = appOptions.value?.product?.docs;

  return (
    <div className={cn('flex flex-column', styles.Limits__wrapper)}>
      <p className='mb-3'>{t('Account:apikeys:info')}</p>
      {docs?.links.api && Object.keys(docs?.links).includes('api') && (
        <a
          className='flex gap-4 mb-5'
          href={createDocsLink(t('docsUrl'), docs?.links.api, docs?.queryParams)}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Icon name='farQuestionCircle' color='primary' />
          <b>{t('Account:apikeys:more')}</b>
        </a>
      )}

      <div className='flex gap-8 items-center'>
        <Button
          color='primary'
          className={styles.Limits__addBtn}
          onClick={() => setApiKeyModalShown({ type: 'create' })}
        >
          {t('Account:apiKeys:createKey')}
        </Button>
      </div>
      {loading && !apiKeyModalShown ? (
        <div className='h-full flex'>
          <Spinner />
        </div>
      ) : Boolean(userApikeys?.content?.length) ? (
        <>
          <ApiKeysTable
            adminTable={userApikeys?.content.some(apiKey => Boolean(apiKey.owner))}
            apiKeys={userApikeys?.content || []}
            openModalHandler={setApiKeyModalShown}
            sortHandler={handleSortClick}
          />
          {paginationInfo.totalPages > 1 && (
            <Pagination
              page={paginationInfo.pageNum}
              size={paginationInfo.pageSize}
              changePage={changePage}
              totalCount={paginationInfo.totalCount}
            />
          )}
        </>
      ) : (
        <div className={cn(styles.apiTab__empty, 'flex flex-column items-center justify-end')}>
          <Icon name='farGhost' size='3x' color='secondary' />
          <h3 className='mt-4'>{t('AccountPage:apikey:createFirstHeader')}</h3>
          <p className='mt-3'>{t('AccountPage:apikey:createFirstInfo')}</p>
          <Button className='mt-4' color='primary' outline onClick={() => setApiKeyModalShown({ type: 'create' })}>
            {t('Create')}
          </Button>
        </div>
      )}
      {apiKeyModalShown && (
        <ApiKeyModal
          type={apiKeyModalShown.type}
          updateCallback={() => fetchApiKeysList()}
          closeModal={() => setApiKeyModalShown(undefined)}
          selectedKeyPrefix={apiKeyModalShown.value?.prefix}
        />
      )}
    </div>
  );
}
