import { effect, signal } from '@preact/signals-react';
import { Draft } from 'immer';

import { getUserChatHistory } from './apiHooks';
import { allConversationsSg, mainConversationSg, setConversationsValue } from './signals';
import { appOptions } from '../../api/cc.api';
import { guideTourEvent$ } from '../../modules/GuideTourStepper/guideTourEvents';
import { appsAdapterService } from '../../services/service';
import { Conversation } from '../../types/chat';
import { routes } from '../../routes';

const isRequestProcessing = signal(false);
let lastScroll: string | undefined = undefined;
effect(async () => {
  if (allConversationsSg.value.some(conversation => conversation.status === 'BUILDING') && !isRequestProcessing.value) {
    isRequestProcessing.value = true;
    const {
      data: { conversations: conversationsFromApi },
    } = await appsAdapterService.getUserChats({ hasUnreadChanges: true, orderBy: 'updated', sort: 'asc' });

    const currentPageUpdate = conversationsFromApi?.find(conversation => {
      if (conversation.status !== 'READY') {
        return false;
      }
      const isMainConversation = mainConversationSg.value && conversation.id === mainConversationSg.value?.id;
      if (isMainConversation && window.location.pathname === routes.mainConversation) {
        return true;
      }
      return window.location.pathname.includes(conversation.id);
    });

    setConversationsValue(prevConversations => {
      conversationsFromApi?.forEach(conversationFromApi => {
        const updatedConversationIndex = prevConversations.findIndex(({ id }) => id === conversationFromApi.id);
        const updatedConversation = prevConversations.splice(updatedConversationIndex, 1)[0];
        prevConversations.unshift({
          ...(conversationFromApi as Draft<Conversation>),
          config: updatedConversation.config,
          contextValue: updatedConversation.contextValue,
          history: updatedConversation.history,
          messageIsStreaming: conversationFromApi.status !== 'READY',
          streamSubscription: updatedConversation.streamSubscription,
          isCanvasChat: updatedConversation.isCanvasChat,
        });
      });
    });
    if (conversationsFromApi?.length && conversationsFromApi.length > 0) {
      const firstDialog = document.querySelector('[data-test-id="Conversation.0"]');
      const firstDialogId = firstDialog?.getAttribute('href') ?? undefined;
      if (firstDialog && firstDialogId !== lastScroll) {
        firstDialog.scrollIntoView({ behavior: 'smooth', block: 'end' });
        lastScroll = firstDialogId;
      }
    }
    if (!!currentPageUpdate) {
      await getUserChatHistory(currentPageUpdate.id, currentPageUpdate.app.template);
      guideTourEvent$.next(`GetResponse:${currentPageUpdate.app.template}`);
    }
    setTimeout(() => {
      isRequestProcessing.value = false;
    }, 2000);
  }
});

const defaultIconElement = document.head.querySelector('#default_icon') as HTMLLinkElement;
const hasNewMessagesIconElement = document.head.querySelector('#has_new_messages_icon') as HTMLLinkElement;

const defaultIconHref = defaultIconElement?.href;
const hasNewMessagesIconHref = hasNewMessagesIconElement?.href;
effect(() => {
  if (!hasNewMessagesIconHref || !defaultIconHref || appOptions.value?.product?.favicon) return;
  if (allConversationsSg.value.some(conversation => conversation.hasUnreadChanges)) {
    defaultIconElement.href = hasNewMessagesIconHref;
  } else {
    defaultIconElement.href = defaultIconHref;
  }
});
