export const limitCrudModalLocalization = {
  'Error:limitInput': {
    eng: '',
    ru: 'Укажите лимит J-токенов',
  },
  'Error:limitPeriodInput': {
    eng: '',
    ru: 'Укажите период обновления',
  },
};
