import React, { FC, useCallback, useRef, useState } from 'react';

import { InputText, ProgressBar, useToggle, useTranslation } from '@just-ai/just-ui';
import { IconButton } from '@just-ai/just-ui/dist';
import axios, { AxiosError } from 'axios';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { SAFileUploader } from './SAFileUploader';
import { addAlert } from '../../../../../../models/alerts';
import useApiService from '../../../../../../services/useApiService';

type StringArrayItemProps = {
  index: number;
  name: string;
  placeholder?: string;
  invalid: boolean;
  onRemove: (index: number) => void;
  required: boolean;
  dropState: (index: number) => unknown;
  maxFileSize?: number;
  dataTestId?: string;
  accept?: string;
  disabled?: boolean;
};

export const StringArrayItem: FC<StringArrayItemProps> = ({
  index,
  name,
  onRemove,
  invalid,
  required,
  placeholder,
  dataTestId,
  maxFileSize = 20,
  dropState,
  accept,
  disabled,
}) => {
  const { t } = useTranslation();
  const cancelRefFile = useRef<AbortController | null>(null);

  const [uploadStatus, setUploadStatus] = useState({ total: 0, loaded: 0 });

  const { uploadFile } = useApiService();
  const { setValue, register } = useFormContext();
  const [isUpLoading, setLoading, setLoaded] = useToggle();
  const onChangeByIndexHandler = useCallback(
    (value: string) => setValue(`${name}.${index}`, value.trimStart()),
    [index, name, setValue]
  );

  const onRemoveByIndexHandler = useCallback(() => onRemove(index), [index, onRemove]);

  const onUploadProgress = useCallback((status: { loaded: number; total: number }) => {
    setUploadStatus(status);
  }, []);

  const dropStateHandle = useCallback(() => {
    dropState(index);
  }, [dropState, index]);

  const handleFileUpload = useCallback(
    async (files?: FileList | null) => {
      const file = files?.[0];
      if (!file) return;
      if (file.size / 1024 / 1024 > maxFileSize) {
        addAlert(t('fileSizeError'), 'error');
        dropStateHandle();
        return;
      }
      setLoading();
      try {
        cancelRefFile.current = new AbortController();
        const { data: fileResp } = await uploadFile(file, undefined, cancelRefFile.current, {
          onUploadProgress: onUploadProgress,
        });
        onChangeByIndexHandler(fileResp.url);
      } catch (error) {
        if (axios.isAxiosError(error) && error.code !== AxiosError.ERR_CANCELED) {
          error.response?.data.error
            ? addAlert(t(error.response?.data.error))
            : addAlert(t('fileUploadErr') + ' ' + (error as AxiosError).message);
          dropStateHandle();
        }
      } finally {
        setLoaded();
      }
    },
    [dropStateHandle, maxFileSize, onChangeByIndexHandler, onUploadProgress, setLoaded, setLoading, t, uploadFile]
  );

  const cancelUpload = useCallback(() => {
    cancelRefFile.current?.abort();
    setUploadStatus({ total: 0, loaded: 0 });
    setLoaded();
  }, [setLoaded]);

  const { name: nameRegister, ref } = register(`${name}.${index}`);

  return (
    <div data-test-id={dataTestId} className='d-flex gap-8 mb-2 align-items-center'>
      <div className='d-flex flex-fill relative'>
        {isUpLoading && (
          <ProgressBar
            style={{ opacity: 0.5, position: 'absolute', height: 37 }}
            progress={(uploadStatus.loaded / uploadStatus.total) * 100}
            unknownProgress={uploadStatus.loaded === uploadStatus.total}
          />
        )}
        <InputText
          innerRef={ref}
          name={nameRegister}
          className='padding-right-36'
          disabled={isUpLoading || disabled}
          onChange={onChangeByIndexHandler}
          invalid={invalid}
          required={required}
          placeholder={placeholder}
          data-test-id={dataTestId + '.input'}
        />
        <SAFileUploader
          onChange={handleFileUpload}
          isUploading={isUpLoading}
          cancelUpload={cancelUpload}
          accept={accept}
          disabled={disabled}
        />
      </div>
      <div>
        <IconButton
          className={cn({ invisible: index === 0 && required })}
          color='primary'
          name='farTrashAlt'
          flat
          onClick={onRemoveByIndexHandler}
          disabled={(index === 0 && required) || disabled}
          size='md'
          data-test-id={dataTestId + '.delete'}
        />
      </div>
    </div>
  );
};
