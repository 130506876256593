import React, { useMemo, useState } from 'react';

import { isDemoMode } from '../../../api/client';
import { showSignupModal } from '../../../hooks/showSignup';
import { ChatHistoryFilesModal } from '../../../pages/Files/ChatHistoryFilesModal';
import { setFilters } from '../../../pages/Files/FIlters.Contorller';
import { Conversation } from '../../../types/chat';

export default function useConversationSelectFileAction({
  selectedConversation,
  acceptedFiles,
}: {
  selectedConversation: Conversation;
  acceptedFiles?: string;
}) {
  const [isSelectingFile, setIsSelectingFile] = useState(false);

  const formats = useMemo(
    () => (acceptedFiles || '')?.split(',').filter(format => format && !format.startsWith('.')),
    [acceptedFiles]
  );

  return {
    handleTriggerFileSelection: acceptedFiles
      ? () => {
          if (isDemoMode.value) {
            showSignupModal(true);
            return;
          }
          setFilters(prevFilters => {
            prevFilters.appFiles.filters.conversationId = selectedConversation.id;
            prevFilters.userFiles.filters.conversationId = selectedConversation.id;
          });
          setIsSelectingFile(true);
        }
      : null,
    fileSelectionModalNode: acceptedFiles ? (
      <ChatHistoryFilesModal
        isOpen={isSelectingFile}
        formats={formats.length > 0 ? formats : undefined}
        conversationId={selectedConversation.id}
        onClose={() => setIsSelectingFile(false)}
      />
    ) : null,
  };
}
