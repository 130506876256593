type EventType =
  | 'function_call'
  | 'upload_file'
  | 'update_file'
  | 'queue_upload_file'
  | 'receive_file'
  | 'file_received'
  | 'file_received_failed'
  | 'get_file'
  | 'update_state'
  | 'update_tools'
  | 'update_description'
  | 'delete_update_description'
  | 'receive_file_url'
  | 'restore_state';

abstract class AbstractEventTransport {
  abstract addEventListener(callback: (event: MessageEvent) => unknown): void;
  abstract removeEventListener(callback: (event: MessageEvent) => unknown): void;
  abstract postMessage(event: EventType, data: any): void;
  abstract unlisten(): void;
}

export class IframeEventTransport implements AbstractEventTransport {
  iframe: HTMLIFrameElement;
  origin: string;
  listeners: ((message: MessageEvent) => unknown)[];
  unlisten: () => void;

  constructor(iframeTarget: HTMLIFrameElement, origin?: string) {
    this.iframe = iframeTarget;
    this.origin = origin || '*';
    this.listeners = [];
    this.unlisten = this.listen();
  }

  addEventListener(callback: (event: MessageEvent) => void) {
    this.listeners.push(callback);
  }
  removeEventListener(callback: (event: MessageEvent) => void) {
    this.listeners = this.listeners.filter(listener => listener !== callback);
  }

  listen() {
    const childIframe = this.iframe.contentWindow;
    const listener = message => {
      if (message.source !== childIframe) {
        return; // Skip message in this event listener
      }
      // do not remove this, easy uncomment for debug
      // console.log(message.data);
      this.listeners.forEach(listener => listener(message));
    };
    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }

  postMessage(event: EventType, data: any) {
    if (typeof data === 'object') {
      this.iframe.contentWindow?.postMessage({ event: `canvas:${event}`, data }, '*');
    } else {
      this.iframe.contentWindow?.postMessage({ event: `canvas:${event}`, data: data }, '*');
    }
  }
}

export type ToolRequest = {
  id: string;
  type: string;
  function: {
    name: string;
    arguments: Record<string, any>;
  };
};

export class ToolEventTransport {
  transport: AbstractEventTransport;
  requestTimeout: number;

  constructor(transport: AbstractEventTransport, requestTimeout: number = 60_000) {
    this.transport = transport;
    this.requestTimeout = requestTimeout;
  }

  destroy() {
    this.transport.unlisten();
  }

  async toolsCall<ToolResponse extends string = string, TData extends ToolRequest = ToolRequest>(
    toolsRequests: TData[]
  ) {
    const cleanAfterAll: ((event: MessageEvent) => void)[] = [];
    // do not remove this, easy uncomment for debug
    // console.log('--- toolsCall sended to iframe', toolsRequests);
    const results: { output: ToolResponse; function_call: TData }[] = [];
    for (let i = 0; i < toolsRequests.length; i++) {
      const toolRequest = toolsRequests[i];
      this.transport.postMessage('function_call', toolRequest);
      const result = await new Promise<{ output: ToolResponse; function_call: TData }>((resolve, reject) => {
        const timerId = setTimeout(() => {
          reject({
            function_call: toolRequest,
            output: 'error:timeout',
          });
        }, this.requestTimeout);

        const onMessage = (event: MessageEvent) => {
          if (event.data.event === `canvas:function_output` && event.data.data.function_call.id === toolRequest.id) {
            resolve({
              output: event.data.data.output as ToolResponse,
              function_call: event.data.data.function_call as TData,
            });

            clearTimeout(timerId);
          }
        };
        cleanAfterAll.push(onMessage);

        this.transport.addEventListener(onMessage);
      });
      results.push(result);
    }
    // do not remove this, easy uncomment for debug
    // console.log('--- toolsCall - receive results from iframe', results);

    cleanAfterAll.forEach(cb => this.transport.removeEventListener(cb));
    return results;
  }

  sendNewFileUrlToIframe(url: string) {
    this.transport.postMessage('receive_file_url', url);
  }

  async uploadFileToIframe<TRequest extends ToolRequest = ToolRequest>(
    file: File | { data: any; type: string; name: string },
    toolRequest: TRequest
  ) {
    if (file instanceof File) {
    } else {
      const cleanAfterAll: ((event: MessageEvent) => void)[] = [];
      const response = await new Promise<{
        toolCallId: TRequest['id'];
        name: TRequest['function']['name'];
        response: string;
      }>((resolve, reject) => {
        const timerId = setTimeout(() => {
          reject({
            toolCallId: toolRequest.id || '',
            name: toolRequest.function.name,
            response: 'file cannot be restored',
          });
        }, this.requestTimeout);

        const onFileReceive = (event: MessageEvent) => {
          if (event.data.event === 'canvas:file_received') {
            resolve({
              toolCallId: toolRequest.id || '',
              name: toolRequest.function.name,
              response: 'file successfully restored',
            });
            clearTimeout(timerId);
          }
          if (event.data.event === 'canvas:file_received_error') {
            reject({
              toolCallId: toolRequest.id || '',
              name: toolRequest.function.name,
              response: 'file cannot be restored',
            });
            clearTimeout(timerId);
          }
        };

        cleanAfterAll.push(onFileReceive);

        this.transport.addEventListener(onFileReceive);

        const { data, type, name } = file;
        this.transport.postMessage('receive_file', { data, type, name });
      });

      cleanAfterAll.forEach(cb => this.transport.removeEventListener(cb));
      return response;
    }
  }
}
