import React from 'react';

import { Modal, useTranslation } from '@just-ai/just-ui';

type Props = {
  isClearModalShown: boolean;
  isClearingInProgress: boolean;
  onConfirmClearAction: () => void;
  onCancelClearAction: () => void;
};

export default function ChatHeaderContextControls({
  isClearModalShown,
  isClearingInProgress,
  onConfirmClearAction: handleConfirmClearAction,
  onCancelClearAction: handleCancelClearAction,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isClearModalShown}
      inProgress={isClearingInProgress}
      className='mobileBottomModal'
      title={t('ClearHistoryModal:Title')}
      buttonSubmitText={t('clear')}
      onCancelClick={handleCancelClearAction}
      onActionClick={handleConfirmClearAction}
      buttonCancelColor='secondary'
    >
      <p className='mb-0'>{t('ClearHistoryModal:Text')}</p>
    </Modal>
  );
}
